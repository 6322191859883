import styled from "styled-components";
import { MyInputMask } from "shared/UI/Inputs";

export const MyInputMaskStyled = styled(MyInputMask)`
    border-radius: 12px;
    padding: 5px 10px;
    width: 100%;
    height: 40px;
    outline: none;
    font-weight: 500;
    border:${({ $error }) => $error ? '1px solid #FF5B5B' : 'none'}  ;
    background-color: ${({ $error }) => $error ? '#fff' : '#E8F1FF'}  ;   
    /* border: 0px; */
    /* color: #163C85; */
    color: ${({ $error }) => $error ? '#FF5B5B' : '#163C85'};;
    

    &:active, &:focus  {
    border:  ${({ $error }) => $error ? '1px solid #FF5B5B ' : '#246FFB'}  ;
    font-size: 16px;
    font-weight: 400;
    box-shadow: 0 0 10px rgba(83, 143, 255, 0.466);
    background-color: #fff !important;
    /* border: 0px; */
     }

`