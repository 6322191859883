import React, { useState, useEffect } from 'react';
import PersonalData from './PersonalData';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import userStore from 'shared/store/userStore';
import { useForm } from 'react-hook-form';
// import cl from '../../ProfilePage.module.css'
import { AccountData } from './ui';
import GmtLoactions from 'models/GmtLoactions/GmtLoactions';

const UserForm = observer(() => {
    const [fields, setFields] = useState([]);
    const [oldFields, setOldFields] = useState()

    useEffect(() => {
        setFields(userStore.data)
        setOldFields(userStore.data)
    }, [])

    const fieldsList = [{ title: 'Никнейм', type: 'text', connect: 'user_nikname' },
    { title: 'Имя', type: 'text', connect: 'user_name', require: true },
    { title: 'Фамилия', type: 'text', connect: 'user_surname' },
    { title: 'Отчество', type: 'text', connect: 'user_patronymic' },
    { title: 'Пол', inputType: 'gender', connect: 'user_sex', set: (e) => setFields({ ...fields, user_sex: e }) },
    { title: 'День рождения', inputType: 'date', type: 'text', connect: 'user_birthday' },
    { title: 'Почта', disabled: true, type: 'email', connect: 'mail', inputType: 'email' },
    { title: 'Номер телефона', type: 'mask', connect: 'user_phone_number', inputType: 'mask', mask: '+7(999)-999-99-99' },
    { title: 'О себе', type: 'text', required: false, connect: 'user_description', inputType: 'textarea', minRows: 7 },
    { title: 'Время от москвы', inputType: 'select', connect: 'user_gmt_time', placeholder: '0' , defaultValue: '0',
        list: GmtLoactions},
    ]
    return (
        <AccountData>
            <PersonalData
                fieldsList={fieldsList}
                setFields={setFields}
            />
        </AccountData>
    );
});

export default UserForm;