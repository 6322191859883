import styled from "styled-components";
import { BlueButton, GreenButton, RedButton, YellowButton, ActionBtn } from 'shared/UI/Buttons';

export const CourseDetilRedBtn = styled(RedButton)`
    padding: 12px 20px;
    line-height: 1;
    max-width: 120px;
    height: fit-content;
    font-size: 16px;
    font-weight: 500;
`
export const CourseDetilGreenBtn = styled(GreenButton)`
    padding: 12px 20px;
    line-height: 1;
    max-width: 120px;
    height: fit-content;
    font-size: 16px;
    font-weight: 500;
`
export const CourseDetilYellowBtn = styled(YellowButton)`
    padding: 12px 20px;
    line-height: 1;
    max-width: 120px;
    height: fit-content;
    font-size: 16px;
    font-weight: 500;
`
export const CourseDetilBlueBtn = styled(BlueButton)`
    padding: 12px 20px;
    line-height: 1;
    max-width: 120px;
    height: fit-content;
    font-size: 16px;
    font-weight: 500;
`