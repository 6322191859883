import styled from "styled-components";

export const AnswerMark = styled.div`
position: absolute;
    left: 15px;
    top: 13px;
    font-size: 13px;
    border: none;
    padding: 4px 15px;
    color: #fff;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.isAnswered ? 'var(--color-green2-default)' : 'var(--color-yellow-default;)'} 
`