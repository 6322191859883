import { CardCourseStudent } from 'entities/StudentArea/CardCourseStudent';
import React from 'react';

const StudentCourses = ({ StudentCoursesList, Subjects }) => {
    return (
        <>
            {StudentCoursesList?.length !== 0 ?
                StudentCoursesList?.map(cours => (
                    <CardCourseStudent
                        key={cours?.id}
                        cours={cours?.course}
                    />
                ))
                : <p>У вас пока что нет купленных курсов</p>
            }
        </>
    );
};

export default StudentCourses;