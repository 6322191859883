import ListVertical from 'shared/UI/Layouts/ListVertical'
import styled from 'styled-components'

export const AnswerWrap = styled.label`
display: flex;
flex-direction: row;
justify-content: space-between;
gap: 10px;
padding-left: 30px;
position: relative;

&>input {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);


}

&>section {
 gap: 5px;
 justify-content: flex-end;
}
`