import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import cl from './LessonsListManager.module.css'
import { api } from 'app/api'
import { Link, useLocation, useNavigate, redirect, Outlet } from 'react-router-dom';
import { useParams } from 'react-router';
import { RejectButton, ModuleCardHeaderFull } from '../TeacherCourseEdit/ModulesAndLessons(Unused)/components/styled/ModulesLessons.styled';
import { useForm } from 'react-hook-form';
import { BorderedButton, DefaultWrapperNoPadding, EscapeLesson, Lesson, LessonsNavigate, LessonsNavigateWrap, LessonsSidebar, LessonsSidebarNP, LessonsSidebarWrap, MoveBtn, NavBtns, NavigateLesson, NavigateModule, StudyingWrap } from 'shared/UI/LearnPrev/LearmPrev.styled';
import СourseСontentNavigation from 'features/СourseСontentNavigation/СourseСontentNavigation'
import { AcceptButton } from 'shared/UI/Buttons';
import { CustomLink } from 'shared/UI/Links';
import { observer } from 'mobx-react-lite';
import { TextAndBackHead } from 'shared/UI/Other';
import { ContentHorizontal, Layout1200 } from 'shared/UI/Layouts';
import { MainPopup } from 'shared/UI/Popups';
import NavigateBtns from 'features/PersonalArea/CourseReaderNavigateBts/NavigateBtns';
import { RiSlideshow3Fill } from 'react-icons/ri';
import { AiOutlineClear, AiOutlineSave } from 'react-icons/ai';
import EditorStore from 'shared/store/EditorStore';
import MainEditor from 'widgets/MainEditor/MainEditor';
import { FaPlus } from "react-icons/fa6";
import { IoIosArrowRoundBack } from 'react-icons/io';
import { ToCourseBtn } from './styled/ToCourseBtn.styled';

const CourseContentEditor = observer(() => {

    const { cid, mid, lid, tid } = useParams()
    const [courseLabel, setCourseLabel] = useState('')
    const [Modules, setModules] = useState([])
    const [currentModule, setCurrentModule] = useState(null)
    const [currentLesson, setCurrentLesson] = useState(null)
    const [modulesAndLessons, setModulesAndLessons] = useState()
    const location = useLocation()
    const navigate = useNavigate();
    const scrollNavigation = useRef(null);

    useEffect(() => {
        if (EditorStore.deleteLessonCommand !== undefined) {
            // modulesAndLessons
            var allModules = [...modulesAndLessons]
            let ModuleIndex = allModules.findIndex(module => Number(module.id) === Number(mid))
            allModules[ModuleIndex].lesson = (allModules[ModuleIndex].lesson).filter(lesson => Number(lesson.id) !== Number(currentLesson))
            //Место где нужно добавить фтьтрацию modulesAndLessons по id из deleteLessonCommand
            EditorStore.setDeleteLessonCommand(undefined)
        }
    }, [EditorStore.deleteLessonCommand])

    const goToNavigate = (module_id, lesson_id, test_id) => {
        test_id !== undefined
            ? navigate(`module/${module_id}/lesson/${lesson_id}/survey/${test_id}`)
            : lesson_id !== undefined
                ? navigate(`module/${module_id}/lesson/${lesson_id}`)
                : navigate(`module/${module_id}`)
    }

    const [ActionError, setActionError] = useState(null)

    const SortSubArrays = (array, param) => {
        return ([...array.map(subarray =>
            subarray = { ...subarray, [param]: _.sortBy(subarray[param], 'order') })])
    }

    useEffect(() => {
        var DataFilled = false
        const getContent = () => {
            api.get(`/Module/author/Course/${cid}`)
                .then((result) => {
                    let modules = result.data.results
                    const OrderedLessonsList = SortSubArrays([...result.data.results], 'lesson')
                    setModules(_.sortBy(modules, 'order'))
                    setModulesAndLessons(OrderedLessonsList)
                    console.log(result.data)
                    setCourseLabel(result.data.course.label)
                }
                )
            if (mid) {
                setCurrentModule(mid)
            }
            if (lid) {
                setCurrentLesson(lid)
            }
            EditorStore.setUpdateModulesAndLesson(false)
            DataFilled = true
        }
        if (DataFilled === false) {
            getContent()
        }
    }, [cid, mid, lid, EditorStore.updateModulesAndLesson])


    useEffect(() => {
        const scrollInRef = (ref, number) => {
            ref.current.scroll({
                top: [0, 1, 2, 3].indexOf(number) != -1 ? 0 :
                    (number - 5) * 40,
                behavior: 'smooth'
            });
        };
        var counter = 0
        Modules.map(module => {
            counter += 1
            if (currentLesson === null && Number(module.id) === Number(currentModule)) {
                scrollInRef(scrollNavigation, counter)
            } else {
                _.sortBy(module.lesson, 'order').map(lesson => {
                    if (Number(lesson.id) !== Number(currentLesson)) {
                        counter += 1
                    } else {
                        scrollInRef(scrollNavigation, counter)
                    }
                })
            }
        })
    }, [currentLesson, currentModule])

    return (
        <>
            <LessonsSidebarWrap >
                <LessonsSidebarNP>
                    {/* <EscapeLesson> */}
                    <ToCourseBtn to={`/teachercabinete/course/${cid}/info`}>
                        <IoIosArrowRoundBack /><p>Кабинет автора</p>
                    </ToCourseBtn>
                    {/* </EscapeLesson> */}
                    <h2>{courseLabel}</h2>
                    <СourseСontentNavigation
                        cid={cid}
                        mid={mid}
                        lid={lid}
                        tid={tid}
                        ScrollRef={scrollNavigation}
                        modulesAndLessons={modulesAndLessons}
                        onClickModule={(M_id, L_id, T_id) => {
                            setCurrentModule(M_id);
                            setCurrentLesson(null)
                            goToNavigate(M_id)
                            EditorStore.setShowNewModuleForm(false)
                        }
                        }
                        onClickLesson={(M_id, L_id, T_id) => {
                            setCurrentModule(M_id);
                            setCurrentLesson(L_id)
                            goToNavigate(M_id, L_id)
                            EditorStore.setShowNewModuleForm(false)
                        }}
                        onClickTest={(M_id, L_id, T_id) => {
                            setCurrentModule(M_id)
                            setCurrentLesson(L_id)
                            goToNavigate(M_id, L_id, T_id)
                            EditorStore.setShowNewModuleForm(false)
                        }}
                        ActiveModule={cid !== undefined && mid !== undefined && lid == undefined}
                        ActiveLesson={false}
                        ActiveTest={true}
                    />
                    {console.log(Modules)}
                    {tid === undefined
                        ?
                        <BorderedButton title={
                            <div>
                                <span className='plus'><FaPlus /></span><p>Добавить модуль</p>
                            </div>
                        }
                            onClick={e => { EditorStore.setShowNewModuleForm(true) }}
                        />
                        : null
                    }
                    {currentLesson
                        ?
                        tid === undefined
                            ?
                            <NavigateBtns
                                id_course={cid}
                                wrap={<NavBtns />}
                                from={'constructor'}
                                buttons={{
                                    save:
                                        <MoveBtn
                                            type="button"
                                            title={'Сохранить'}
                                            onClick={() => EditorStore.setCommand('save')}
                                            disabled={!EditorStore.SaveMarker}
                                            className={
                                                EditorStore.SaveMarker
                                                    ? cn(cl.LessonManageBtn, cl.green)
                                                    : cn(cl.LessonManageBtn, cl.while)}
                                        >
                                            <AiOutlineSave />
                                        </MoveBtn>,
                                    clear:
                                        <MoveBtn
                                            className={cn(cl.LessonManageBtn, cl.clear)}
                                            type="button"
                                            title={'Очистить'}
                                            onClick={() => EditorStore.setCommand('clear')}
                                        >
                                            <AiOutlineClear />
                                        </MoveBtn>,
                                    preview:
                                        <Link className={cl.LessonManageBtn}
                                            title={'Предпросмотр'}
                                            to={"/lesson/edit/preview/" + cid + "/" + currentLesson}>
                                            <MoveBtn>
                                                <RiSlideshow3Fill />
                                            </MoveBtn>
                                        </Link>
                                }}
                            />
                            : null
                        : null}



                </LessonsSidebarNP>
            </LessonsSidebarWrap>
            {EditorStore?.showNewModuleForm
                ? <MainEditor />
                : <ContentHorizontal className={cl.fullAndPadR}>
                    <Outlet />
                </ContentHorizontal>
            }
            <MainPopup
                isOpened={(ActionError != null) ? true : false}
                onClose={e => setActionError(null)}
                title={'Внимание'}
                showHeader={true}
            >
                {ActionError}
                <RejectButton title={'Ок'} onClick={() => setActionError(null)} />
            </MainPopup>
        </>
    );
});

export default CourseContentEditor;