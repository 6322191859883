import React, { useEffect, useState } from 'react';
import cl from './TeacherQuestionsList.module.css'
import QuestionCard from 'entities/TestEditor/QuestionsCards/QuestionCard';
import { ListVertical } from 'shared/UI/Layouts';
import { TextAndBackHead } from 'shared/UI/Other';
import AddTestQuestion from 'features/TestArea/AddTestQuestion/AddTestQuestion';

const TeacherQuestionsList = ({ questionsList, addQuestion, deleteQuestion }) => {
    const [LocalList, setLocalList] = useState()
    const [NewQuestionForm, setNewQuestionForm] = useState(false)

    //обновление списка после изменения
    useEffect(() => {
        setLocalList(questionsList)
    }, [questionsList])

    //удление вопроса по индексу
    const DeleteQuestion = (index) => {
        deleteQuestion(index)
    }

    //добавление нового вопроса
    const AddNewQuestion = (NewQdata) => {
        addQuestion(NewQdata, LocalList)
        setNewQuestionForm(false)
    }

    //добавление ответа или обновление данных существующего ответа с вопросом
    const AddNewAnswer = (QuestionId, AnswerData, QuestionData, type, index) => {
        var LocalCopy = JSON.parse(JSON.stringify([...LocalList]))
        if (type == 1) {
            LocalCopy[index].question = QuestionData
            LocalCopy[index].answerOptions[0] = AnswerData
        }
        if (type == 2) {
            LocalCopy[index].question = QuestionData
            LocalCopy[index].answerOptions = AnswerData
        }
        if (type == 3) {
            LocalCopy[index].question = QuestionData
            LocalCopy[index].answerOptions = AnswerData
        }
        setLocalList(LocalCopy)
    }

    return (
        <ListVertical className={cl.TeacherQuestions}>
            {/* <TextAndBackHead text={''} /> */}
            {/* Попап добавления вопроса*/}
            <AddTestQuestion
                showDownButton={LocalList?.length >= 3}
                addAction={AddNewQuestion} >
                {LocalList?.map((question, index) =>
                    <QuestionCard
                        key={index}
                        question={question}
                        index={index}
                        deleteQuestion={DeleteQuestion}
                        AddNewAnswer={(id, Data, Question, type) => { AddNewAnswer(id, Data, Question, type, index) }}
                    />
                )}

            </AddTestQuestion>
        </ListVertical >
    );
};

export default TeacherQuestionsList;