import React, { useEffect, useState } from 'react';
import cl from './PageRegTeacher.module.css'
import InputArea from 'shared/UI/Inputs/InputArea';
import { AcceptButton, CancelButton } from 'shared/UI/Buttons';
import { usePassEncrypt } from 'shared/hooks';
import { api } from 'app/api';
import userStore from 'shared/store/userStore';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorField } from 'shared/UI/Errors';
import { MyTextarea } from 'shared/UI/Inputs';
import { InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
import { Controller, useForm } from 'react-hook-form';
import { InputValidateRadio } from 'shared/UI/Inputs/HookForm/InputValidateRadio';
import { BlueWrap } from 'shared/UI/Layouts';
import { ContentHorizontal, ListVertical } from 'shared/UI/Layouts';
import OneSwitchCheckBoxValidate from 'shared/UI/Inputs/HookForm/OneSwitchCheckBoxValidate';
import { link_project_docs } from 'app/globals/server';
import { SwitchButton } from 'shared/UI/Buttons/SwitchButton/SwitchButton.styled';
import { uppFistChar } from 'shared/hooks/uppFistChar';
import { InputValidateFirstCharUpp } from 'shared/UI/Inputs/HookForm/InputValidateFirstCharUpp';
import { MainSelect } from 'shared/UI/Selects';
import GmtLoactions from 'models/GmtLoactions/GmtLoactions';

const RegistratonFormSteps = ({ Step, setStep, setIsSucces, setInLoad, LastStep, setError, error }) => {
    const [dataTeacher, setDataTeacher] = useState({})



    const { register,
        handleSubmit,
        setValue,
        clearErrors,
        control,
        reset,
        watch,
        formState: { errors }
    } = useForm({ mode: 'onSubmit' });

    const navigate = useNavigate()
    const goHome = () => navigate('/')

    useEffect(() => {
        if (Step === 0)
            setStep(1)
    }, [Step])

    useEffect(() => {
        const GetUserData = async () => {
            if (userStore.auth) {
                try {
                    const { data } = await api.get('/user/0')
                    return reset(data)
                } catch {
                    return setError({ error: 1, message: 'Ошибка' })
                }
            }
        }
        GetUserData()
    }, [])

    const passEncrypt = usePassEncrypt()

    const regTeacher = (sendData) => {
        setInLoad(true)
        console.log(sendData)
        if (userStore.auth) {
            api.post('teacher', {
                QualificationLevel: sendData.qualification_level,
                ExperienceYears: sendData.experience_years,
                Description: sendData.description,
            })
                .then((result) => {
                    const data = {
                        user_name: sendData.user_name,
                        user_surname: sendData.user_surname,
                        user_patronymic: sendData.user_patronymic,
                        user_gmt_time: sendData.user_gmt_time,
                        user_birthday: sendData.user_birthday + 'T00:00:00',
                        user_sex: sendData.user_sex
                    }
                    api.put('user', data)
                        .then(() => {
                            userStore.changeUser({ ...data, role_id: 2 })
                            setInLoad(false)
                            goHome()
                        })
                        .catch(error => {
                            setError({ ...error, error: error?.response.data })
                            setInLoad(false)
                        })
                })
                .catch(error => {
                    setError({ ...error, error: error?.response.data })
                    setInLoad(false)
                })
        } else {
            const { qualification_level, experience_years, description, ...allData } = sendData
            api.post('user/RegistrationTeacher', {
                user: { ...allData, password: passEncrypt(allData.password) },
                teacher: {
                    QualificationLevel: qualification_level,
                    ExperienceYears: experience_years,
                    Description: description,
                }
            })
                .then((result) => {
                    setInLoad(false)
                    setIsSucces(true)
                })
                .catch(error => {
                    console.log(error)
                    setError({
                        ...error, responseError:
                            error.response.status >= 500
                                ? 'Что-то пошло не так!'
                                : error?.data?.messages || error?.response?.data
                    })


                    setInLoad(false)
                    setIsSucces(false)
                })
        }
    }

    const goNext = () => {
        if (Step >= 1 && Step <= LastStep) {
            setStep(Step + 1)
        }
    }
    const goBack = () => {
        if (Step >= 1 && Step <= LastStep) {
            setStep(Step - 1)
        }
    }
    return (
        <form onSubmit={handleSubmit(regTeacher)} >
            <ListVertical>
                <BlueWrap $active={true} as='div'>
                    <p>Регистрация</p>
                </BlueWrap>
                {Step === 1 &&
                    <ListVertical>
                        <InputValidateUseForm
                            title={'Электронная почта'}
                            isEdit={{ isAuth: userStore?.auth, mail: watch('mail') }}
                            type={'email'}
                            error={errors?.mail}
                            validateSet={register("mail", {
                                required: "Пожалуйста, введите mail",
                                minLength: {
                                    value: 3,
                                    message: 'Минимум 3 символа',
                                },
                                pattern: {
                                    value: /^(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#%&*\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{2,63}\.)+[a-zA-Z]{2,15}$/,
                                    message: 'Неверный формат email',
                                }
                            })}
                        />
                        {
                            !userStore.auth
                                ? <InputValidateUseForm
                                    title={'Пароль'}
                                    type={'password'}
                                    error={errors?.password}
                                    validateSet={register("password", {
                                        required: "Пожалуйста, введите пароль",
                                    })}
                                />
                                : null
                        }

                        <OneSwitchCheckBoxValidate
                            RadioClass={cl.policy}
                            RadioList={
                                {
                                    id: 'policy',
                                    value: watch('user_confirm_policy'),
                                    name: 'user_confirm_policy',
                                    title: <p>
                                        Ознакомлен с <a href={link_project_docs.policy_pdn} target={'_blank'}>политикой обработки персональных данных</a> и <a href={link_project_docs.user_policy} target={'_blank'}>пользовательским соглашением</a>
                                    </p>
                                }
                            }
                            error={errors?.user_confirm_policy}
                            validateSet={{ ...register("user_confirm_policy", { required: "Не заполнено" }) }}
                            onChange={e => { setValue('user_confirm_policy', e) }}
                        />
                    </ListVertical>
                }
                {Step === 2 &&
                    <ListVertical>
                        <InputValidateFirstCharUpp
                            title={'Имя'}
                            type={'text'}
                            error={errors?.user_name}
                            validateSet={register("user_name", {
                                required: "Пожалуйста, введите имя",
                                minLength: {
                                    value: 1,
                                    message: 'Минимум 1 символа'
                                },
                            })}
                        />
                        <InputValidateFirstCharUpp
                            title={'Фамилия'}
                            type={'text'}
                            error={errors?.user_surname}
                            validateSet={register("user_surname", {
                                required: "Пожалуйста, введите фамилию",
                                minLength: {
                                    value: 1,
                                    message: 'Минимум 1 символа'
                                },
                            })}
                        />
                        <InputValidateFirstCharUpp
                            title={'Отчество'}
                            type={'text'}
                            error={errors?.user_patronymic}
                            validateSet={register("user_patronymic", {
                                minLength: {
                                    value: 1,
                                    message: 'Минимум 2 символа'
                                }
                            })}
                        />
                        <InputValidateUseForm
                            title={'Ваш пол'}
                            error={errors?.user_sex}
                        >
                            <InputValidateRadio
                                RadioList={[
                                    { id: 1, value: "М" },
                                    { id: 2, value: "Ж" }
                                ]}
                                checked={watch('user_sex')}
                                InputClass={cl.sexInput}
                                RadioClass={cl.radio}
                                validateSet={{ ...register("user_sex", { required: "Выберите пол" }) }}
                            />
                        </InputValidateUseForm>
                    </ListVertical>
                }
                {/* {Step == 3 &&
                <ListVertical>
                    <ProfileImgUploader currentImgId = {watch('user_photo')} returnImgId={val => (setValue('user_photo', val))} />                                                
                </ListVertical>
            } */}
                {Step === LastStep &&
                    <ListVertical>
                        <InputValidateUseForm
                            title={'День рождения'}
                            type={'date'}
                            id={'birthday'}
                            error={errors?.birthday}
                            validateSet={register("user_birthday", {
                                required: 'Не указана дата',
                            })}
                        />               
                        <Controller
                            name="user_gmt_time"
                            control={control}
                            rules={{ required: 'Пожалуйста, введите/выберите значение' }}
                            render={({ field: { onChange, onBlur, value } }) => (
                                <MainSelect
                                    title={'Время по МСК'}
                                    placeholder={watch('user_gmt_time') || 'Выбор...'}
                                    map={GmtLoactions}
                                    value={['id']}
                                    label={['value']}
                                    onChange={e => {setValue('user_gmt_time', e.label)}}
                                    error={errors.user_gmt_time}
                                    defaultValue={GmtLoactions?.filter(sub => sub.id == watch("user_gmt_time", false)).label}
                                />
                            )}
                        />
                        <InputValidateUseForm
                            title={'Уровень подготовки'}
                            type={'text'}
                            error={errors?.qualification_level}
                            validateSet={register("qualification_level", {
                                required: 'Пожалуйста, укажите степень',
                            })}
                        />
                        <InputValidateUseForm
                            title={'Стаж (год)'}
                            type={'number'}
                            error={errors?.experience_years}
                            validateSet={register("experience_years", {
                                required: 'Значения от 0 до 80 лет ',
                                min: {
                                    value: 0,
                                    message: 'Неверное значение'
                                },
                                max: {
                                    value: 80,
                                    message: 'Неверное значение'
                                }
                            })}
                        />
                        <InputArea
                            title={'О себе'}
                            type={'text'}
                            id={'description'}
                            value={dataTeacher.description}
                            onChange={(e) => setDataTeacher({ ...dataTeacher, description: e.target.value })}
                        >
                            <MyTextarea
                                minRows={3}
                                maxRows={5}
                                validateSet={{ ...register("description", {}) }}
                            />
                        </InputArea>
                    </ListVertical>
                }
                <ListVertical>
                    <ErrorField
                        error={error?.error}
                        confirm={error?.confirm}
                    />
                    {Step === LastStep
                        ?
                        <ListVertical className={cl.regAction}>
                            <AcceptButton
                                className={cl.accept}
                                title={'Зарегистрироваться'}
                                type={'submit'}
                            />
                            <BlueWrap type='button' onClick={e => goBack()}
                            >  Назад
                            </BlueWrap>
                        </ListVertical>
                        :
                        <ContentHorizontal className={cl.buttonSection}>
                            <>
                                {Step == 1
                                    ?
                                    <SwitchButton textalign={'center'} as={Link} to={'/login'}>
                                        Назад
                                    </SwitchButton>
                                    :
                                    <SwitchButton type='button' onClick={e => goBack()}
                                    >  Назад
                                    </SwitchButton>
                                }
                                <SwitchButton onClick={handleSubmit(goNext)}
                                > Вперёд
                                </SwitchButton>
                            </>
                        </ContentHorizontal>}
                </ListVertical>
            </ListVertical>
        </form>
    );
};

export default RegistratonFormSteps;