import { MainWrap } from 'shared/UI/Layouts';
import React, { useEffect, useState } from 'react';
import { QuestionStepper } from 'widgets/QuestionStepper';
import { useParams } from 'react-router-dom';
import { api } from 'app/api';
import { observer } from 'mobx-react-lite';
import MySurveysStore from 'shared/store/MySurveysStore';
import { Lesson } from 'shared/UI/LearnPrev/LearmPrev.styled';
import { QuestionRender } from 'widgets/QuestionRender';
import FinishTestPopup from 'features/FinishTestPopup/FinishTestPopup';
import { TestResultShort } from 'widgets/TestResultShort';
import { formateTimeSpanToMinute } from 'shared/hooks';

const TestPassing = observer(() => {
    const [NotAll, setNotAll] = useState([]) //массив неотвеченных вопросов
    const [TestResults, setTestResults] = useState(null)
    const [testParams, settestParams] = useState({})
    const [questions, setQuestions] = useState(null)
    const { id_test } = useParams()
    const [CurrentId, setCurrentId] = useState(null)
    const [AnswersList, setAnswersList] = useState([]) // Испольуеся для определения отвеченных вопросов и для указания неотвеченных, в сути должен содержать id отвкченных вопросов



    const setNewCurrentId = (id) => {
        const CurrentIndex = questions.indexOf(questions.find(q => q.question.id == CurrentId))
        if (CurrentIndex != questions.length - 1) {
            setCurrentId(questions[CurrentIndex + 1].question?.id)
        }
    }
    useEffect(() => {
        const LastTryId = localStorage.getItem('SurveyCurrentTry')
        const getSurveyInfo = async (time) => {
            const Params = await api.get(`Survey/Info/${id_test}`)
            return (await Params.data.survey)
        }

        //Создание новой попытки и получение вопросов к ней
        const CreateNewTry = async () => {
            const TestQuestions = await api.get(`Survey/Trial/${id_test}/${LastTryId}`) //Получение вопросов теста
            const CurrentTry = await api.post(`Survey/CreateSurveyTry`, { idSurvey: id_test, points: 0 }) //создание новой поытки
            localStorage.setItem('SurveyCurrentTry', CurrentTry.data.id)
            setQuestions([...TestQuestions.data.old.questions, ...TestQuestions.data.surveyWithQuestionsAndAnswers.questions])
            setCurrentId(TestQuestions.data.surveyWithQuestionsAndAnswers.questions[0].question.id)
        }

        //Создание попытки по тесту если попытки нет, или она ещё не заrрыта
        if (LastTryId == null || LastTryId == undefined) {
            //Если попытки нет    
            CreateNewTry()
            getSurveyInfo()
        } else {
            const getTryTime = async () => {
                const survey = await getSurveyInfo() //Получаем данные по тесту
                const TestQuestions = await api.get(`Survey/Trial/${id_test}/${LastTryId}`)
                const TestQuestionsList = [...TestQuestions?.data?.old, ...TestQuestions?.data?.surveyWithQuestionsAndAnswers?.questions]
                var Answered = []
                TestQuestionsList.map(q => {
                    q.question.answerOptions.find(ao => ao.answered == true) != null &&
                        Answered.push(q.question.id)
                })
                const TryTime = await api.post(`Survey/TimeSurveyTry/${LastTryId}`)  //дата начала попытки
                // ---------------Вычисление даты покупок----------------
                const CurrentTryTime = Math.floor((Date.parse(TryTime.data)) / 1000) //Дата начала попытки
                const TestDuration = survey.duration // Длительность теста
                const CurrentTime = Math.floor(Date.parse(new Date()) / 1000)  //Дата сейчас
                {
                    if ((CurrentTryTime + TestDuration) - CurrentTime < 0) {
                        //если меньше, зеачит попытка акончилась
                        settestParams({ ...survey, duration: 0 })
                    } else {
                        // Попытка не закончилась по времени, установка параметров попытки с изменённым временем
                        settestParams({ ...survey, duration: formateTimeSpanToMinute(TestDuration) * 60 - (CurrentTime - CurrentTryTime) })
                        setQuestions(TestQuestionsList)
                        setCurrentId(TestQuestions?.data?.surveyWithQuestionsAndAnswers?.questions[0]?.question?.id || TestQuestions?.data?.old[0]?.question?.id)
                        setAnswersList(Answered)
                    }
                }
            }
            getTryTime()
        }
    }, [])

    //----------------Конец теста, действия завершения -----------------------
    const FinishTest = () => {
        var hasNotAnswers = [...questions.filter(question => AnswersList.indexOf(AnswersList.find(answer => answer.id == question.question.id)) == -1)]
        var indexArrays = []
        hasNotAnswers.map((elem, index) => {
            var qel = questions.find(q => q.question.id == elem.question.id)
            AnswersList.indexOf(elem.question.id) == -1 && indexArrays.push(questions.indexOf(qel) + 1)
        }
        )

        indexArrays.length > 0
            ?
            setNotAll(indexArrays)
            :
            getTryResult()
    }

    //Запрос на получение результата теста
    const getTryResult = async () => {
        //Запрос на получение резульатов по тесту
        await api.put(`Survey/EditStatusSurveyTry/${localStorage.getItem('SurveyCurrentTry')}`)
            .then(result => {
                setTestResults({
                    pointSystem: result?.data?.pointSystem,
                    failure_3: testParams.failure_3,
                    grade_4: testParams.grade_4,
                    grade_5: testParams.grade_5,
                    countOfRight: result.data.points,
                    grade: result?.data?.grade,
                    questionCount: testParams.showAnyCount
                })
                localStorage.removeItem('SurveyCurrentTry');
            }
            )
    }
    //-----------------------------------------

    //функция обновления вопроса с ответом на него
    const upQuestionAndAnswers = (answer, qId, type) => {
        const NewArr = [...questions]
        NewArr.map(q => {
            if (q.question.id == qId) {
                if (q.question.type == 1) {
                    q.question.answerOptions = [{ ...answer, answered: true }]
                }
                else {
                    q.question.answerOptions.map(ao => answer.answer.includes(ao.id)
                        ? ao.answered = true
                        : ao.answered = false
                    )
                }
            }
        })
        // id записывается в массив ответов
        setAnswersList(prevState => {
            return ([...prevState.filter(item => item !== qId), qId])
        })
        const CurrentIndexinQuestions = questions.indexOf(questions.find(q => q.question.id == qId))
        //если это не последний вопрос 
        if (CurrentIndexinQuestions != (questions.length - 1)) {
            //ставим текущим следующий вопрос
            // console.log('ставим текущим следующий вопрос')
            setCurrentId(questions[CurrentIndexinQuestions + 1].question.id)
        }
        // else {
        //     //отправляем маркер того что нужно проверитьсвсё
        //     console.log('отправляем маркер того что нужно проверитьсвсё')
        //     MySurveysStore.SendIsLastAnswer(true)
        // }
    }

    return (
        <MainWrap>
            {/* {console.log(AnswersList)} */}
            <Lesson>
                {TestResults != null
                    ?
                    <TestResultShort
                        TestResults={TestResults}
                    />
                    :
                    <>
                        {(questions !== null) ?
                            <>
                                <QuestionStepper
                                    CurrentId={CurrentId}
                                    List={questions}
                                    AnswersList={AnswersList}
                                    setCurrent={setCurrentId}
                                    testParams={testParams}
                                    LastAction={FinishTest}
                                    EndAction={getTryResult}
                                >
                                    <QuestionRender
                                        ListLength={questions?.length}
                                        setAnswersToCheck={upQuestionAndAnswers}
                                        setCurrent={setNewCurrentId}
                                        Question={questions?.find(elem => elem.question.id == CurrentId)?.question}
                                        isAnswered={AnswersList.indexOf(CurrentId) != -1}
                                        isLastIndex={CurrentId == questions[questions.length - 1].question.id}
                                        updateQuestion={(data) =>
                                            setQuestions(prev => {
                                                const FullQList = JSON.parse(JSON.stringify(prev))
                                                FullQList[questions?.findIndex(q => q == questions?.find(elem => elem.question.id == CurrentId))].question = data
                                                return (FullQList)
                                            }
                                            )
                                        }
                                    />
                                </QuestionStepper>
                                {/* Конец тестирования */}
                                <FinishTestPopup
                                    isOpened={NotAll.length > 0}
                                    onClose={e => setNotAll([])}
                                    Elemnts={NotAll}
                                    finishActions={() => {
                                        getTryResult();
                                        setNotAll([]);
                                        // localStorage.removeItem('SurveyCurrentTry');
                                        MySurveysStore.setActiveTry(false)
                                    }}
                                    JumpToElement={(el) => {
                                        setCurrentId(questions[el - 1].question.id)
                                        setNotAll([])
                                    }}
                                />
                            </>
                            : null}
                    </>
                }
            </Lesson>
        </MainWrap>
    );
});

export default TestPassing;