import { SimpleWhiteButton } from "shared/UI/Buttons";
import styled from "styled-components";

export const CustomLBlueBtn = styled(SimpleWhiteButton)`
        background-color: #246FFB;
        color: #fff;
        padding: 10px 20px;
        font-weight: 600;
        width: 100%;    
        border: none;

        &:hover {
            background-color:#1f58c1;
        }
`