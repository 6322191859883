import React from 'react';

const PublicateCours = ({ pubStatus, modStatus, className, PublicateOnOver, overAction, mainAction, texts, }) => {
    return (

        <>
            {/* {modStatus} */}
            {/* {modStatus === 2 ? true : false} */}
            <button
                onMouseOver={() => overAction(true)}
                onMouseOut={() => overAction(false)}
                className={className}
                onClick={() => mainAction()}
                width={'102px'}
                disabled={modStatus === 2 ? true : false}
            >
                {pubStatus === false || pubStatus === null
                    ? modStatus === 2
                        ? texts.publicate
                        : texts.publicate                    
                    //опубликовано
                    : modStatus === 1 //на модерации
                        ? texts.unpublicate
                        : modStatus === 2 //отклонено
                            ? texts.publicate
                            : modStatus === 3
                                ? texts.publicate
                                : texts.publicate
                }
            </button>
        </>

    );
};
export default PublicateCours;