import { api } from 'app/api';
import StudentCourseInformation from 'entities/StudentArea/StudentCourseInformation/StudentCourseInformation';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const CourseProgress = ({ CourseData, lessonsCount }) => {
    const [Directions, setDirections] = useState([])
    const { id_course, id_module, id_lesson, id_test } = useParams()
    useEffect(() => {
        api.get(`/Course/Subjects`)
            .then((result) => {

                setDirections([...result.data])
            }
            )
    }, [id_course])

    return (
        <StudentCourseInformation
            CourseData={CourseData}
            lessonsCount={lessonsCount}
            Directions={Directions}
        />
    );
};

export default CourseProgress;