import styled from 'styled-components';

export const TransparentButton = styled.button`
cursor: pointer;
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;

 &:hover {
    text-decoration: underline;
 }
 `