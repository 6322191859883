import React from 'react'; 
import { observer } from 'mobx-react-lite';
import { FullWrap } from 'shared/UI/Wrappers';
import SecurityMainWidget from 'widgets/PersonalSecurity/SecurityMain/SecurityMain';

const SecurityMain = observer(() => {  
    return (
        <FullWrap>
          <SecurityMainWidget/>
        </FullWrap >
    );
});

export default SecurityMain;