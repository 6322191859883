import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultWrapper } from 'shared/UI/Wrappers';
import EmailRecovey from 'widgets/Autorisation/EmailRecovey/EmailRecovey';
import AuthorisationWidget from 'widgets/Autorisation/AuthorisationWidget/AuthorisationWidget';
import { PageLoginWrap } from './ui';

const PageLogin = observer((props) => {
    const [rememberPassword, setRememberPassword] = useState(false)
    return (
        <PageLoginWrap>
            <DefaultWrapper style={{padding: '32px', width: '376px', borderRadius: '30px' }}>
                {rememberPassword == false
                    ?
                    <AuthorisationWidget callRememberPassword={() => setRememberPassword(true)} />
                    :
                    <EmailRecovey cancelAction={() => setRememberPassword(false)} />
                }
            </DefaultWrapper>
        </PageLoginWrap >
    );
});

export default PageLogin;