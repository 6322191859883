import { ListVertical } from 'shared/UI/Layouts';
import React from 'react';
import EducationLayout from 'shared/UI/Layouts/EducationLayout/EducationLayout';
import { link_project_docs } from 'app/globals/server';
import HeaderMain from 'widgets/HeaderMain';
import { BlockCards, BoldSpan, BoldSpanUppercase, Card, Elem, ElemLeft, ElemRightAlign, ElementList, HorizontalBlockRequisites, HorizontalBlockWrap, HorizontalBlockWrapElem, Label, List, Logo, Main, SupportCard, TitleBlock, SizedSpan } from './ui';
import { DownloadDocCard } from 'features/docs/download-doc-card';

const About = () => {

    return (
        <>
            <HeaderMain />
            <Main>
                <ListVertical>
                    <EducationLayout>
                        <ListVertical>
                            <BlockCards>
                                <Logo>
                                    <img src={window.location.origin + '/images/logo.svg'} alt='logo' />
                                </Logo>
                                <HorizontalBlockWrap>
                                    <Card>
                                        <h2>Назначение</h2>
                                        <p>Программное обеспечение “ТОТЭРА” предназначено для автоматизации взаимодействия между авторами учебных курсов и их потребителями в формате онлайн-сервиса”</p>
                                    </Card>
                                    <Card>
                                        <h2>На что направлен проект</h2>
                                        <p>
                                            Программное обеспечение предназначено для функционирования в виде веб-приложения и распространяется по модели SaaS</p>
                                    </Card>
                                </HorizontalBlockWrap>
                            </BlockCards>
                        </ListVertical>
                    </EducationLayout>
                </ListVertical>
                <ListVertical style={{ background: ' var(--dullBlue)' }}>
                    <EducationLayout>
                        <ListVertical>
                            <BlockCards>
                                <TitleBlock>
                                    <h2>ПО «ТОТЭРА» реализует следующие основные функции</h2>
                                </TitleBlock>
                                <HorizontalBlockWrap>
                                    <Card>
                                        <h2>ФУНКЦИИ ДЛЯ АВТОРОВ КУРСОВ</h2>
                                        <List>
                                            <ElementList>
                                                <h1>1</h1>
                                                <p>Создание курса;</p>
                                            </ElementList>
                                            <ElementList>
                                                <h1>2</h1>
                                                <p>Публикация курса;</p>
                                            </ElementList>
                                        </List>
                                    </Card>
                                    <Card>
                                        <h2>ФУНКЦИИ ДЛЯ ЧИТАТЕЛЕЙ КУРСОВ</h2>
                                        <List>
                                            <ElementList>
                                                <h1>1</h1>
                                                <p>Просмотр доступных курсов;</p>
                                            </ElementList>
                                            <ElementList>
                                                <h1>2</h1>
                                                <p>Приобретение выбранного курса;</p>
                                            </ElementList>
                                            <ElementList>
                                                <h1>3</h1>
                                                <p>Прохождение курса;</p>
                                            </ElementList>
                                        </List>
                                    </Card>
                                </HorizontalBlockWrap>
                            </BlockCards>
                        </ListVertical>
                    </EducationLayout>
                </ListVertical>
                <ListVertical>
                    <EducationLayout>
                        <ListVertical>

                            <BlockCards>
                                <TitleBlock>
                                    <h2>Документация</h2>
                                </TitleBlock>
                                <HorizontalBlockWrap>
                                    <DownloadDocCard
                                        href={link_project_docs.descript_function}
                                        title={'Описание функциональных характеристик'}
                                    />
                                    <DownloadDocCard
                                        href={link_project_docs.install_instruction}
                                        title={'Инструкция по установке'}
                                    />
                                    <DownloadDocCard
                                        href={link_project_docs.user_guide}
                                        title={'Руководство пользователя, установки и эксплуатации'}
                                    />
                                </HorizontalBlockWrap>
                                <Card>
                                    <h2>СТОИМОСТЬ И ПОРЯДОК ПРИОБРЕТЕНИЯ</h2>
                                    <HorizontalBlockWrap>
                                        <ElemLeft>
                                            <p>Телефон:
                                                <BoldSpan>
                                                    +7 (3952) 48-77-37
                                                </BoldSpan>
                                            </p>
                                            <p>Email:<BoldSpan>info@totera.ru</BoldSpan></p>
                                        </ElemLeft>
                                        <ElemRightAlign>
                                            <p>Условия и стоимость права пользования программным обеспечением «ТОТЭРА» определяются индивидуальными контрактами.</p>
                                        </ElemRightAlign>
                                    </HorizontalBlockWrap>
                                </Card>
                            </BlockCards>
                        </ListVertical>
                    </EducationLayout>
                </ListVertical >
                <ListVertical style={{ background: ' var(--dullBlue)' }}>
                    <EducationLayout>
                        <ListVertical>
                            <BlockCards>
                                <TitleBlock>
                                    <h2>Техническая поддержка</h2>
                                </TitleBlock>
                                <SupportCard>
                                    <h2>Контакты службы технической поддержки:</h2>
                                    <HorizontalBlockWrap>
                                        <Elem>
                                            <p>Телефон:  <BoldSpan>+7 (3952) 48-77-37 </BoldSpan> </p>
                                            <p>Email:
                                                <BoldSpan>help@totera.ru</BoldSpan></p>
                                        </Elem>
                                        <Elem>
                                            <p>Адрес:
                                                <BoldSpan>
                                                    665830, Иркутская область, г. Ангарск, 78-й квартал, стр. 3, офис 6.
                                                </BoldSpan>
                                            </p>
                                        </Elem>
                                        <Elem>
                                            <p>
                                                <Label>
                                                    Служба технической поддержки:
                                                </Label>
                                                <BoldSpan>
                                                    по будням с 09:00 до 18:00 (по мск с 04:00 до 13:00, GMT +8).
                                                </BoldSpan>
                                            </p>
                                        </Elem>
                                    </HorizontalBlockWrap>
                                    <SizedSpan>Техническая поддержка и сопровождение выполняются организацией-разработчиком ‒ ООО «ТОТЭРА».</SizedSpan>
                                </SupportCard>
                            </BlockCards>
                        </ListVertical>
                    </EducationLayout>
                </ListVertical>
                <ListVertical style={{ background: 'var(--dullBlue)' }}>
                    <EducationLayout>
                        <ListVertical>
                            <BlockCards>
                                <TitleBlock>
                                    <h2>РЕЕСТР РОССИЙСКОГО ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ</h2>
                                </TitleBlock>
                                <Card>
                                    <HorizontalBlockWrap>
                                        <Elem >
                                            <Logo>
                                                <img src={window.location.origin + '/images/logo_dump/orel_logo_1.png'} />
                                            </Logo>
                                        </Elem>
                                        <Elem style={{ justifyContent: 'center' }}>
                                            <BoldSpan>
                                                Программное обеспечение “ТОТЭРА” включено в реестр Российского Программного обеспечения. Реестровая запись №22219 от 17.04.2024
                                            </BoldSpan>
                                        </Elem>
                                    </HorizontalBlockWrap>
                                </Card>
                            </BlockCards>
                        </ListVertical>
                    </EducationLayout>
                </ListVertical>
                <ListVertical>
                    <EducationLayout>
                        <ListVertical>
                            <BlockCards>
                                <TitleBlock>
                                    <h2>Реквизиты компании</h2>
                                </TitleBlock>
                                <Card>
                                    <Elem>
                                        <p >Наименование компании:
                                            <BoldSpanUppercase>
                                                Общество с ограниченной ответсвенностью "ТОТЭРА"
                                            </BoldSpanUppercase>
                                        </p>
                                    </Elem>
                                    <HorizontalBlockWrapElem>
                                        <Elem>
                                            <p>Контактный телефон:
                                                <BoldSpan>
                                                    +7 (3952) 48-77-37
                                                </BoldSpan>
                                            </p>
                                        </Elem>
                                        <Elem>
                                            <p>e-mail:  <BoldSpan>
                                                info@totera.ru
                                            </BoldSpan>
                                            </p>
                                        </Elem>
                                    </HorizontalBlockWrapElem>
                                    <HorizontalBlockRequisites>
                                        <Elem>
                                            <p>ИНН:
                                                <BoldSpan>
                                                    3801158644
                                                </BoldSpan>
                                            </p>
                                        </Elem>
                                        <Elem>
                                            <p>ОГРН:
                                                <BoldSpan>
                                                    1233800005834
                                                </BoldSpan>
                                            </p>
                                        </Elem>
                                        <Elem>
                                            <p>КПП:
                                                <BoldSpan>
                                                    380101001
                                                </BoldSpan>
                                            </p>
                                        </Elem>
                                    </HorizontalBlockRequisites>
                                </Card>
                            </BlockCards>
                        </ListVertical>
                    </EducationLayout>
                </ListVertical >
            </Main >
        </>
    );
};

export default About;