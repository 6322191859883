import styled from "styled-components";
export const EditProfileForm = styled.form`   
    max-width: 765px;
    width: 80%;
    margin: 0 auto;
`
export const ProfileHeader = styled.h3`   
    font-size  : 20px; 
    font-weight: 600;
    color: #163C85;
`

export const ProfileText = styled.p`   
    font-size  : 18px; 
    font-weight: 500;
    color: #163C85;
`