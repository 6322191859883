import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { api, getUserData } from 'app/api';
import useDeviceInfomation from 'shared/hooks/AuthHooks';
import { observer } from 'mobx-react-lite';
import userStore from 'shared/store/userStore';
import { Loader } from 'shared/UI/Loaders';
import { TbSquareRoundedCheckFilled } from "react-icons/tb";
import { RedirectButton, ConfirmMain, ConfirmDefault } from './ui';
import { invite_group } from 'app/globals';
import { HocInviteRoute } from 'shared/hoc';

const Verification = observer(() => {

    const [isResult, setIsResult] = useState(false);
    const [hasConfirm, setHasConfirm] = useState(false);
    const [confirmationType, setConfirmationType] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams()

    const { pathname } = useLocation();
    const device = useDeviceInfomation();
    const navigate = useNavigate()
    const goHome = () => navigate('/home')
    const goLogin = (param) => {
        navigate('/login', { state: param })
    }


    var timer = null

    //Получение случайной строки для подтверждения регистрации
    var confirmURL = pathname.split('/')[pathname.indexOf('confirmation') + 1]

    useEffect(() => {
        checkVerification()
        // return () => clearTimeout(timer)
    }, [])

    const checkVerification = () => {
        api.post(`user/confirmation/${confirmURL}`, {
            device_type_id: device.type,
            platform: '123',
            geo: device.geo == {} ? "" : JSON.stringify(device.geo),
            system: device.system
        })
            .then(result => {
                localStorage.setItem('token', result.data.token)
                result.status == 200 && setIsResult(true)
                setConfirmationType(result.data.type)
                getUserData()
                    .then((result) => {
                        localStorage.setItem('userId', result.data.id)
                        userStore.changeUser(result.data);
                        userStore.signIn()
                        setIsResult(true)
                        timer = setTimeout(Redirect, 5000)
                        userStore.getUserPhoto(result)
                    })
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    setHasConfirm(true)
                    setIsResult(true)
                    return
                }
                if (error.response.status === 429) {
                    return goLogin({ error: { code: 1, message: 'Ссылка устарела, войти ещё раз и отправьте ссылку повторно' }, link: confirmURL })
                }
                return goLogin({ error: { code: 1, message: 'Неизвестная ошибка' } });
            })
    }

    const Redirect = () => {
        if (searchParams.get('invite_group') !== undefined || searchParams.get('invite_group') !== null && isResult) {
            invite_group(searchParams.get('invite_group'))
            return navigate('/invite/group')
        }
        if (!hasConfirm && isResult) {
            return goHome()
        }
        if (hasConfirm && isResult) {
            return goLogin()
        }
    }

    const RedirectVerification = () => {
        return (
            <>
                <TbSquareRoundedCheckFilled size={'42px'}
                    color={hasConfirm ? '#df9f09' : '#39AA95'} />
                {
                    !hasConfirm && isResult
                        ?
                        confirmationType === 'mailChange'
                            ? <p>Ваша почта успешно изменена</p>
                            : confirmationType === 'password'
                                ? <p>Ваша пароль успешно изменён</p>
                                : <p>Вы успешно прошли регистрацию и будете перенаправлены на домашнюю страницу через 5 секунд</p>
                        : <p>Ваша почта успешно подтверждена. Перейдите на страницу входа</p>
                }
                <RedirectButton style={{ height: 'auto' }} onClick={() => Redirect()}>{!hasConfirm && isResult ? 'Перейти на главную' : 'Перейти'}</RedirectButton>
            </>
        )
    }

    const AwaitVerification = () => {
        return (
            <>
                <div>
                    <Loader style={{ width: '50px', height: '50px' }} />
                </div>
                <p>Ожидайте...</p>
            </>
        )
    }

    return (
        <ConfirmMain >
            <ConfirmDefault >
                {
                    isResult
                        ? <RedirectVerification />
                        : <AwaitVerification />
                }
            </ConfirmDefault>
        </ConfirmMain>

    );
});

export default Verification;