import React, { useEffect } from 'react';
import { CancelButton, SimpleWhiteButton } from 'shared/UI/Buttons';
import { BlueAcceptButton } from 'shared/UI/Inputs/HookForm/InputValidateUseForm/styled/BlueValidateInput.styled';
import { MainPopup } from 'shared/UI/Popups';
import { ContentHeader } from 'shared/UI/Wrappers/StyledWrappers/ui';
import { ColRowWrap } from 'widgets/QuestionRender/styled/ColRowWrap.styled';

const FinishTestPopup = ({ isOpened, onClose, Elemnts, finishActions, JumpToElement }) => {   
    return (
        <MainPopup
            isOpened={isOpened}
            title={'Вы точно хотите завершить тест?'}
            onClose={e => onClose([])}>
            <p>На следующие вопросы не указваны ответы</p>
            <ColRowWrap gap={'10px'} repeat='repeat(5, 1fr)'>
                {Elemnts.map((el, index) =>
                    <SimpleWhiteButton
                        onClick={() => { JumpToElement(el) }}
                        key={el}>
                        № {el}
                    </SimpleWhiteButton>)}
            </ColRowWrap>
            <ContentHeader style={{ fontSize: '16px' }}>
                <BlueAcceptButton
                    title='Завершить'
                    onClick={() => { finishActions() }}
                />
                <CancelButton title='Отмена' onClick={() => onClose()} />
            </ContentHeader>
        </MainPopup>
    );
};

export default FinishTestPopup;