import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash'
import { host } from 'app/globals';
import SimpleImg from 'shared/UI/Images/SimpleImg/SimpleImg';
import { useForm } from 'react-hook-form';
import { api } from 'app/api';
import { useParams } from 'react-router-dom';
import { InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
import { SpacedApartWrap } from 'shared/UI/Wrappers';
import { ChevronRotate } from 'shared/UI/Other';
import { BlueQuestionButton, DarkGreenQuestionButton, QuestionTextStyled, RedQuestionBtn } from 'shared/UI/Buttons/OtherButtons/QuestionTextStyled';
import { HiOutlineTrash } from 'react-icons/hi';
import { ImgRemove, ImgSubUploadQuestion, ImgUploadQuestion } from 'entities/TestEditor/OptionAnswer/styled/ImgUpload.styled';
import { BsUpload } from 'react-icons/bs';
import { MyInput } from 'shared/UI/Inputs';
import { ContentHorizontalCenter } from 'pages/StudentArea/StudentCoursesList/courseShow/SurveyLobby/SurveyLobbyStyled';

const QuestionText = ({ question, setQuestionData, isEdit, EditAction, DeleteAction, AddNewAnswer, QuestionIndex }) => {
    const [QuestionImg, setQuestionImg] = useState(null)
    const [ShowFull, setShowFull] = useState(false)
    const [LocalQuestion, setLocalQuestion] = useState({})
    const clearVal = useRef("")
    // const { id } = useParams()
    const { cid, mid, lid, tid } = useParams()

    const { register,
        handleSubmit,
        setValue,
        clearErrors,
        reset,
        watch,
        formState: { errors }
    } = useForm({ mode: 'onSubmit' });

    //получение изображеия и сброс данных
    useEffect(() => {
        if (question.imgQuest == null) {
            setQuestionImg(null)
        } else {
            fetch(`${host}/api/GetFile/${question.imgQuest
                }`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
                .then(response => response.status === 200 && response.blob(response))
                .then(blob => {
                    if (blob) {
                        const fileURL = URL.createObjectURL(blob)
                        setQuestionImg(fileURL)
                    }
                    else {
                        setQuestionImg(null)
                    }
                })
        }
        const qData = JSON.parse(JSON.stringify(question))
        const ValidateData = { textQuest: qData?.textQuest, questionAnswer: qData?.answerOptions[0]?.textAnswer || '' }
        reset(ValidateData)
        setLocalQuestion(qData)
    }, [question]);

    const removeImage = (PhotoId) => {
        api.delete(`deletefile/${PhotoId}`)
            .then(() => {
                // setLoaclQuestion({ ...LocalQuestion, imgQuest: null })
                setQuestionImg(null)
            },
                (error) => { }
            )
    }
    const uploadPhoto = async (event) => {
        var file = event.target.files;
        setQuestionImg(URL.createObjectURL(file[0]))
        const formData = new FormData();
        formData.append('file', file[0]);
        const Uploaded = await api.post('/uploadfile', formData)
        setLocalQuestion({ ...LocalQuestion, imgQuest: await Uploaded.data.id })
    }

    //Сохранение данных вопроса и его ответов
    const saveQuestion = (data) => {
        var { answerOptions, ...mainData } = LocalQuestion
        mainData.textQuest = data.textQuest
        if ((mainData.textQuest !== question.textQuest) || (LocalQuestion.imgQuest !== question.imgQuest)) {
            api.put(`Question/${LocalQuestion.id}`, { ...mainData, SurveyId: tid, imgQuest: mainData.imgQuest })
                .then((result) => {
                    if (!result.status == 200 || !result.status == 204) {
                        var { answerOptions, ...other } = question
                        mainData = other
                    }
                })
        }

        question.answerOptions = [{ ...LocalQuestion?.answerOptions[0], textAnswer: watch('questionAnswer') }]
        // Сохранение ответов на вопрос если были изменения  
        if (!_.isEqual(LocalQuestion?.answerOptions, question.answerOptions)) {
            if (question?.answerOptions[0]?.id != null) { // Если списоку ответов не пустой
                //Если ответа существовал, то обноляем запросом в базеу и отправляем обновление в верхний компонент 
                api.put(`AnswerOption/${LocalQuestion?.answerOptions[0].id}`, { QuestionId: LocalQuestion.id, TextAnswer: data.questionAnswer, right: true, ImgId: 0 })
                    .then(result => {
                        AddNewAnswer(LocalQuestion.id, { ...LocalQuestion.answerOptions[0], textAnswer: data.questionAnswer }, mainData, LocalQuestion.type)
                    })
            }
            else {//Если список ответов пустой, значит мы создаём первый ответ 
                api.post(`AnswerOption`, { QuestionId: LocalQuestion.id, TextAnswer: watch('questionAnswer'), right: true, ImgId: null })
                    //Если ответ не существовал, то мы его создаём запросом в базу и отправляем обновление в верхний компонент 
                    .then(result => {
                        AddNewAnswer(LocalQuestion.id, { ...result.data, QuestionId: LocalQuestion.id, TextAnswer: data.questionAnswer, right: true, ImgId: null }, mainData, LocalQuestion.type)
                    })
            }
        } else {
            //Если ответы не менялись
            AddNewAnswer(LocalQuestion.id, LocalQuestion.answerOptions[0], mainData, LocalQuestion.type)
        }
        setQuestionData({ LocalQuestion, imgQuest: LocalQuestion.imgQuest })
        EditAction(false)
    }

    const saveCancel = () => {
        setLocalQuestion({ ...question })
        EditAction(false)
    }

    return (
        <>
            {isEdit
                ?
                <>
                    <InputValidateUseForm
                        title={`Вопрос ${QuestionIndex + 1}`}
                        type={'text'}
                        error={errors?.textQuest}
                        validateSet={register("textQuest", {
                            required: "Пожалуйста, введите текст вопроса",
                            minLength: {
                                value: 2,
                                message: 'Минимум 2 символа'
                            }
                        })}
                    />
                    <QuestionTextStyled style={{ justifyContent: 'center', flexDirection: 'column' }}>
                        <label htmlFor='addMainImg'
                        >
                            <ImgUploadQuestion >
                                {QuestionImg !== null
                                    ?
                                    <SimpleImg
                                        src={QuestionImg}
                                        maxW={'400px'}
                                    />
                                    :
                                    <>
                                        <BsUpload />
                                        <p>
                                            Добавить картинку
                                        </p>
                                    </>
                                }
                                <MyInput
                                    refInput={clearVal}
                                    id="addMainImg"
                                    type="file"
                                    onChange={uploadPhoto}
                                    accept='image/jpeg,image/png'
                                />
                            </ImgUploadQuestion>
                        </label>
                        <ContentHorizontalCenter>
                            {QuestionImg !== null
                                ?
                                <>
                                    <ImgSubUploadQuestion>
                                        <label htmlFor='addSubMainImg' >
                                            <BsUpload />
                                            <p>
                                                Заменить
                                            </p>
                                        </label>
                                        <MyInput
                                            refInput={clearVal}
                                            id="addSubMainImg"
                                            type="file"
                                            onChange={uploadPhoto}
                                            accept='image/jpeg,image/png'
                                        />
                                    </ImgSubUploadQuestion>
                                    <ImgRemove onClick={() => removeImage(LocalQuestion.imgQuest)}>
                                        <HiOutlineTrash />
                                        <p>
                                            Удалить
                                        </p>
                                    </ImgRemove>
                                </>
                                : null
                            }
                        </ContentHorizontalCenter>
                    </QuestionTextStyled>
                    <input
                        type={'file'}
                        onChange={(e) => uploadPhoto(e)}
                        accept='image/jpeg,image/png'
                    />
                    <InputValidateUseForm
                        title={'Текст правильного ответа'}
                        type={'text'}
                        error={errors?.questionAnswer}
                        validateSet={register("questionAnswer", {
                            required: "Пожалуйста, введите текст ответа",
                            minLength: {
                                value: 1,
                                message: 'Минимум 1 символа'
                            },
                        })}
                    />
                    <SpacedApartWrap>
                        <RedQuestionBtn type={'submit'} onClick={() => saveCancel(false)}>
                            Отменить
                        </RedQuestionBtn>
                        <DarkGreenQuestionButton type={'submit'}
                            onClick={handleSubmit(saveQuestion)}
                        > Сохранить</DarkGreenQuestionButton>
                    </SpacedApartWrap>
                </>
                :
                <>
                    <QuestionTextStyled style={{ cursor: 'pointer' }} $ShowUnderLine={!ShowFull}
                        onClick={() => setShowFull(!ShowFull)} >
                        №{QuestionIndex + 1 + ' ' + LocalQuestion?.textQuest}
                        <ChevronRotate triggerParam={ShowFull} />
                    </QuestionTextStyled>
                    {ShowFull
                        ?
                        <>
                            {console.log(QuestionImg)}
                            {
                                QuestionImg
                                    ?
                                    <QuestionTextStyled style={{ justifyContent: 'center' }}>
                                        <SimpleImg
                                            src={QuestionImg}
                                            maxW={'400px'}
                                        />
                                    </QuestionTextStyled>
                                    :
                                    null
                            }
                            <p>Ответ:</p>
                            {(LocalQuestion?.answerOptions?.length && LocalQuestion?.answerOptions?.length != 0)
                                ?
                                LocalQuestion?.answerOptions[0]?.textAnswer
                                :
                                <p> У вопроса нет ответа</p>
                            }
                            <SpacedApartWrap>
                                <RedQuestionBtn type={'button'}
                                    onClick={() => DeleteAction(true)} > Удалить</RedQuestionBtn>
                                <BlueQuestionButton type={'button'}
                                    onClick={() => EditAction(true)} > Редактировать</BlueQuestionButton>
                            </SpacedApartWrap>
                        </>
                        : null
                    }
                </>
            }
        </>
    );
};
export default QuestionText;