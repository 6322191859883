import { AcceptButton, BlueButton, GreenButton, RedButton, YellowButton } from 'shared/UI/Buttons';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import LessonDetail from 'widgets/LessonDetail/LessonDetail';
import { LessonString } from 'pages/TeacherArea/TeacherCourseEdit/ModulesAndLessons(Unused)/components/styled/ModulesLessons.styled';
import { ContentHeader, ListVerticalStart } from 'shared/UI/Wrappers/StyledWrappers/ui';
import { api } from 'app/api';
import { ContentHorizontal } from 'shared/UI/Layouts';
import { DefaultWrapperFull, DefaultWrapperFullNP, LessonHeader } from 'shared/UI/LearnPrev/LearmPrev.styled';
import { MyInput } from 'shared/UI/Inputs';
import { BlueWrapSBCenter } from 'shared/UI/Layouts';
// import { MdCancel, MdModeEditOutline } from 'react-icons/md';
import { MdCancel, MdModeEditOutline } from 'react-icons/md';
import { IoSaveSharp } from 'react-icons/io5';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AddLessonPopup from 'widgets/TeacherArea/CourseConstructor/AddLessonPopup/AddLessonPopup';
import { CreateLessonBtn, CreateLessonSmallBtn, InnerButton } from './ui';
import { observer } from 'mobx-react-lite';
import EditorStore from 'shared/store/EditorStore';

const ModuleWindow = observer(({ ModuleData, UpdateModuleData, deleteLesson, rebaseLesson, deleteModule, CurrentLessonId, setLesson, CurrentModuleId, AddNewLesson, AllModules, course_id }) => {

    const [PopupCreateLesson, SetPopupCreateLesson] = useState(false)
    const [ModuleChange, setModuleChange] = useState(false)
    const [ModuleName, setModuleName] = useState(null)
    const { cid, mid, lid, tid } = useParams()
    const [newModuleName, setNewModuleName] = useState(() => ModuleName)
    const [errorRename, setErrorRename] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setModuleName(ModuleData?.name)
        setNewModuleName(ModuleData?.name)
    }, [ModuleData?.name])

    useEffect(() => {
        setModuleChange(false)
    }, [CurrentModuleId, CurrentLessonId])

    const filterLessons = (newModule, lessonId, oldModuleID, lessonData) => {
        api.put(`/Lesson/sovok/${lessonId}`, { ModuleId: newModule })
            .then(() => {
                newModule !== ModuleData.id &&
                    rebaseLesson(newModule, lessonId, oldModuleID, { ...lessonData, ModuleId: newModule })
            }
            )
    }
    const SaveModuleChange = () => {
        UpdateModuleData({ Name: ModuleName })
            .then(() => {
                setErrorRename(false)
                setModuleName(newModuleName)
            })
            .catch(() =>
                setErrorRename(true)
            )
        // отправка нового названия на сохранение]
        setModuleChange(false) //скрытие инпута
    }

    return (
        <>
            {CurrentLessonId === null && CurrentModuleId === null  //Если урока не выбрано
                ?
                <DefaultWrapperFull>
                    Пожалуйста, выберите модуль из списка слева или создайте новый
                </DefaultWrapperFull>
                :
                null
            }
            {CurrentLessonId == null && CurrentModuleId !== null
                ?
                <>
                    <LessonHeader style={{ gap: '10px' }}>
                        {ModuleChange
                            ?
                            <ContentHorizontal style={{ alignItems: 'center', width: '100%' }}>
                                <p style={{ whiteSpace: 'nowrap' }}>
                                    Модуль  {ModuleData?.order}:
                                </p>
                                <MyInput value={newModuleName} onChange={e => setNewModuleName(e.target.value)} />

                            </ContentHorizontal>
                            :
                            <>
                                <p>
                                    {`Модуль  ${ModuleData?.order}:  ${ModuleName}`}
                                </p>
                                {
                                    errorRename ?
                                        <div style={{padding: '5px', background: '#dbdbdb', borderRadius: '4px'}}>
                                            <p style={{ color: "#f36767", fontSize: '14px', fontWeight: '600' }}>Ошибка</p>
                                        </div>
                                        : null}
                            </>
                        }
                        <ContentHorizontal style={{ gap: '10px' }}>
                            {ModuleChange
                                ?
                                <>
                                    <GreenButton
                                        style={{ padding: 'unset' }}
                                        onClick={e => SaveModuleChange()}>
                                        <InnerButton>
                                            <IoSaveSharp /> Сохранить
                                        </InnerButton>
                                    </GreenButton>
                                    <YellowButton
                                        style={{ padding: 'unset' }}
                                        onClick={e => setModuleChange(false)}
                                    >
                                        <InnerButton>
                                            < MdCancel /> Отменить
                                        </InnerButton>
                                    </YellowButton>
                                </>
                                :
                                <BlueButton
                                    style={{ padding: 'unset' }}
                                    onClick={e => setModuleChange(true)}>
                                    <InnerButton>
                                        <MdModeEditOutline /> Изменить
                                    </InnerButton>
                                </BlueButton>
                            }
                        </ContentHorizontal>
                    </LessonHeader>
                    <DefaultWrapperFull>
                        <ListVerticalStart>
                            <ContentHeader>
                                <p>Содержание модуля</p>
                                <ContentHorizontal style={{ alignItems: 'center' }} >
                                    <CreateLessonSmallBtn style={{ height: '32px' }} title='Создать урок'
                                        onClick={e => SetPopupCreateLesson(true)} />
                                    {`Уроков: `}<span style={{ minWidth: '50px', textAlign: 'end' }}>{ModuleData?.lesson?.length ?? '0'}</span>
                                </ContentHorizontal>
                            </ContentHeader>
                            {_.sortBy(ModuleData?.lesson, 'order').map((lesson, index) =>
                                <div
                                    key={lesson.id}
                                    style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'flex-end' }}>
                                    <BlueWrapSBCenter as={'div'} style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                        <LessonString key={lesson.id}
                                            onClick={e => setLesson(lesson.id)}>
                                            {`Урок ${ModuleData.order}.${index + 1} ${lesson.name}`}
                                        </LessonString>
                                        <AcceptButton onClick={e => {
                                            setLesson(lesson.id)
                                            navigate(`lesson/${lesson.id}`)
                                        }}
                                            title={'перейти'} />
                                    </BlueWrapSBCenter>
                                    {
                                        lesson?.surveys?.length > 0 && lesson?.surveys[0] ?
                                            <div style={{
                                                background: 'rgb(246, 246, 246)',
                                                padding: '10px 20px',
                                                width: '90%',
                                                borderRadius: '14px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}>

                                                <p>{lesson?.surveys[0]?.label}</p>
                                                <p>Длительность: {lesson?.surveys[0]?.duration}</p>
                                                <AcceptButton onClick={e => {
                                                    navigate(`lesson/${lesson?.id}/survey/${lesson?.surveys[0]?.id}`)
                                                }}
                                                    title={'изменить'} />
                                            </div>
                                            : null
                                    }
                                </div>

                            )}
                            {ModuleData?.lesson?.length == 0
                                ? null
                                : <AcceptButton style={{ height: '32px' }} title='Создать урок'
                                    onClick={e => SetPopupCreateLesson(true)} />}
                        </ListVerticalStart>
                    </DefaultWrapperFull>
                    <AddLessonPopup
                        isOpened={PopupCreateLesson}
                        onClose={SetPopupCreateLesson}
                        AddLesson={Lesson => {
                            AddNewLesson(Lesson, ModuleData.id);
                            SetPopupCreateLesson(false)
                            EditorStore.setUpdateModulesAndLesson(true)

                        }}
                    />
                </>
                :
                null
            }
            {
                CurrentLessonId !== null && CurrentModuleId !== null && lid !== undefined//Если выбран конкретный урок    
                    ?
                    <>

                        <DefaultWrapperFullNP>

                            <LessonDetail
                                lesson={ModuleData?.lesson.find(elem => elem.id == CurrentLessonId)}
                                cours_id={CurrentLessonId ?? cid}
                                deleteLesson={deleteLesson}
                                lessonsState={ModuleData?.lesson}
                                index={ModuleData?.order}
                                rebaseLesson={rebaseLesson}
                                AllModules={AllModules}
                                CurrentModulId={CurrentModuleId ?? mid}
                                filterLessons={filterLessons}
                            />
                        </DefaultWrapperFullNP>
                    </>
                    : null
            }
        </>
    );
});

export default ModuleWindow;