import React, { useEffect, useState } from 'react';
import cl from './LessonDetail.module.css'
import cn from 'classnames';
import _ from 'lodash';
import { api } from "app/api"
import LessonShowData from '../../entities/TeacherArea/TeacherCourseEdit/LessonDataForm/LessonShowData';
import LessonEditData from '../../features/LessonEditForm/LessonEditData';
import ConstructorComponent from 'pages/TeacherArea/TeacherCourseRedactor/Components/ConstructorComponent';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import EditorStore from 'shared/store/EditorStore';



const LessonDetail = observer(({ lesson, cours_id, deleteLesson, AllModules, rebaseLesson, CurrentModulId, index, filterLessons }) => {
    const [DefaultLessonData, setDefaultLessonData] = useState({})
    const [LessonData, setLessonData] = useState()
    const [LessonModulePlace, setLessonModulePlace] = useState(null)
    const [Edit, setEdit] = useState(false)

    const { cid, mid, lid, tid } = useParams()

    useEffect(() => {
        setLessonData(lesson)
        setDefaultLessonData(lesson)
    }, [lesson])

    //Добавление теста в данные урока
    const createTest = (testData) => {
        setLessonData({ ...LessonData, surveys: [testData] })
        EditorStore.setUpdateModulesAndLesson(true)
    }
    const RemoveTest = async (idTest) => {
        api.delete(`survey/${idTest}`)
            .then(() => {
                setLessonData(prev => { return { ...prev, surveys: [] } })
                EditorStore.setUpdateModulesAndLesson(true)
            })
    }

    const updateDefaultLesson = () => {
        api.put(`/Lesson/sovok/${lesson.id}`, { ...LessonData, ModuleId: CurrentModulId })
            .then(result => {
                setDefaultLessonData(LessonData)
            })
    }

    //настройка демоурока
    const ToggleDemo = (e) => {
        LessonData[e.target.name] === false
            ? setLessonData({ ...LessonData, [e.target.name]: true })
            : setLessonData({ ...LessonData, [e.target.name]: false })
    }

    //удаляет урок из текущего модуля, скрывает popup
    const ChangeLessonModule = (moduleId, lessonId, oldModuleID, lessonData) => {
        filterLessons(moduleId, lessonId, oldModuleID, lessonData)
    }
    return (

        <>
            <div key={lesson?.id} className={cn(cl.LessonCard)}>
                {!Edit
                    ?
                    < LessonShowData
                        lesson={LessonData}
                        LessonData={LessonData}
                        setEdit={setEdit}
                        cours_id={cid}
                        index={index}
                        createTest={createTest}
                        RemoveTest={RemoveTest}
                        deleteLesson={deleteLesson}
                    />
                    :
                    <LessonEditData
                        LessonData={LessonData}
                        lesson={LessonData}
                        updateDefaultLesson={updateDefaultLesson}
                        setLessonData={setLessonData}
                        setLessonModulePlace={setLessonModulePlace}
                        LessonModulePlace={LessonModulePlace}
                        ChangeLessonModule={ChangeLessonModule}
                        setEdit={setEdit}
                        cours_id={cid}
                        AllModules={AllModules}
                        CurrentModulId={CurrentModulId}
                        DefaultLessonData={DefaultLessonData}
                        ToggleDemo={ToggleDemo}
                        index={index}
                    />
                }
            </div>

            {(cours_id != null &&
                lesson?.id != null) ?
                <ConstructorComponent
                    IdParams={{ course_id: cid, lesson_id: lid }} />
                : null
            }

        </>
    )
});
export default LessonDetail;