import React from 'react';
import { PayHistoryElement } from 'widgets/PersonalArea/PersonalPayments';
import PaymentsCards from 'widgets/PersonalArea/PersonalPayments/PaymentsCards/PaymentsCards';

const Payments = (props) => {
    return (
        <>
            <div style={{ width: '100%', display: "flex", justifyContent: 'flex-start' }}>
                <p>Платёжные карты</p>
            </div>         
            <PaymentsCards/>
            <PayHistoryElement/>
        </>
    );
};

export default Payments;