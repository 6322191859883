import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { Loader } from 'shared/UI/Loaders';
import { Link, useParams } from 'react-router-dom';
import { api } from 'app/api'
import { ListVertical, MainWrap } from 'shared/UI/Layouts';
import { Layout1200 } from 'shared/UI/Layouts';
import { TextAndBackHead } from 'shared/UI/Other';
import { TeacherCourseCardEditable, TeacherCourseContent } from 'widgets/TeacherArea/CoursInformation';
import { IoIosArrowRoundBack } from 'react-icons/io';

function CoursInformation() {
    const { cid } = useParams()
    const [modulesAndLessons, setModulesAndLessons] = useState()

    //функция получения
    useEffect(() => {
        const SetFirstData = async () => {
            const course = await api.get("/Module/author/Course/" + cid)
            setModulesAndLessons(_.sortBy(course?.data?.results, 'order'))
        }
        SetFirstData()
    }, [])

    return (
        <MainWrap
        // className={cl.teacherCabinet}
        // headerTitle={headerTitle}
        >

            <Layout1200>
                <TextAndBackHead
                    // LeftOn = {false}
                    AnotherLeft={
                        <Link to={'/teachercabinete/course/list'}><IoIosArrowRoundBack />К курсам</Link>
                    }
                    text={'Изменение курса'}
                />
                {modulesAndLessons === null
                    ?
                    <Loader />
                    :
                    <ListVertical>
                        <TeacherCourseCardEditable
                            cid={cid}
                            setModulesAndLessons={setModulesAndLessons}
                        />
                        <TeacherCourseContent
                            setState={setModulesAndLessons}
                            modulesAndLessons={modulesAndLessons}
                            cid={cid}
                        />
                    </ListVertical>}
            </Layout1200>
        </MainWrap >

    )
}
export default CoursInformation;