import React, { useEffect, useState } from 'react';
import cl from './OneSwitchCheckBoxValidate.module.css'
import { CheckBox } from 'shared/UI/Inputs';

const OneSwitchCheckBoxValidate = ({ RadioClass, RadioList, validateSet, error, onChange }) => {

    return (
        <>
            <div className={RadioClass}>
                <CheckBox
                    title={RadioList.title ?? RadioList.value}
                    id={RadioList.id}
                    key={RadioList.id}
                    value={RadioList.value}
                    validate={validateSet}
                    onChange={onChange}
                />
                {error && <div className={cl.ValidateError}> {error.message || "Пожалуйста, выберите параметр"}</div>}
            </div>
        </>
    );
};

export default OneSwitchCheckBoxValidate;