import React from 'react';
import cl from './../ProfilePage.module.css'
import UserPhoto from 'widgets/PersonalArea/PersonalInformation/UserPhoto/UserPhoto';
import UserForm from 'widgets/PersonalArea/PersonalInformation/UserForm/UserForm';
import { AccountInformation, AccountSection, NameSectionAccount, Section } from './ui';
import DownloadDocCard from 'features/docs/download-doc-card/DownloadDocCard';
import { link_project_docs } from 'app/globals/server';

const Profile = () => {
    return (
        <>
            <AccountSection>
                <NameSectionAccount>
                    <p>Персональные данные</p>
                </NameSectionAccount>
                <AccountInformation>
                    <UserPhoto />
                    <UserForm />
                </AccountInformation>
            </AccountSection>
            <Section>
                <NameSectionAccount>
                    <p>Правовые документы</p>
                </NameSectionAccount>
                <AccountInformation>
                    <DownloadDocCard
                        title={'Пользовательское соглашение'}
                        href={link_project_docs.user_policy}
                    />
                    <DownloadDocCard
                        title={'Политика обработки персональных данных'}
                        href={link_project_docs.policy_pdn}
                    />
                </AccountInformation>
            </Section>
        </>
    );
};

export default Profile;