import styled from "styled-components";

export const GreenTag = styled.div`
     padding: 10px 20px;
     background-color: var(--color-yellow-hover);
     border-radius: 10px;
     overflow: hidden;
     font-size: 14px;
     font-weight: 600;
     line-height: 150%;
     color: #fff;
     white-space: nowrap;
`