import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { api } from 'app/api'
import { Link, useLocation, useNavigate, redirect, Outlet } from 'react-router-dom';
import { useParams } from 'react-router';
// import { RejectButton, ModuleCardHeaderFull} from '../TeacherCourseEdit/ModulesAndLessons(Unused)/components/styled/ModulesLessons.styled';
import { RejectButton, ModuleCardHeaderFull } from 'pages/TeacherArea/TeacherCourseEdit/ModulesAndLessons(Unused)/components/styled/ModulesLessons.styled';
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
import { Layout1200, ListVertical } from 'shared/UI/Layouts';
import ModuleWindow from 'widgets/ModuleWindow/ModuleWindow';
import { DefaultWrapperNoPadding, EscapeLesson, Lesson, LessonsNavigate, LessonsNavigateWrap, LessonsSidebar, MoveBtn, NavBtns, NavigateLesson, NavigateModule, StudyingWrap } from 'shared/UI/LearnPrev/LearmPrev.styled';
import { AcceptButton } from 'shared/UI/Buttons';
import { MainPopup } from 'shared/UI/Popups';
import { observer } from 'mobx-react-lite';
import EditorStore from 'shared/store/EditorStore';

const MainEditor = observer(() => {

    const { cid, mid, lid, tid } = useParams()

    const [Modules, setModules] = useState([])
    const [currentModule, setCurrentModule] = useState(null)
    const [currentLesson, setCurrentLesson] = useState(null)
    const [course, setCourse] = useState({})
    const [modulesAndLessons, setModulesAndLessons] = useState()


    const location = useLocation()
    const navigate = useNavigate();

    useEffect(() => {
        setCurrentLesson(lid)
        setCurrentModule(mid)
    }, [cid, mid, lid, tid])

    const [ActionError, setActionError] = useState(null)

    const { register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors }
    } = useForm({ mode: 'onChange' });

    const resetModuleAdd = () => {
        EditorStore.setShowNewModuleForm(false)
        setValue('newModuleName', null)
    }

    const SortSubArrays = (array, param) => {
        return ([...array.map(subarray =>
            subarray = { ...subarray, [param]: _.sortBy(subarray[param], 'order') })])
    }
    useEffect(() => {
        api.get(`/Module/author/Course/${cid}`)
            .then((result) => {
                setCourse(result.data.course)
                let modules = result.data.results
                const OrderedLessonsList = SortSubArrays([...result.data.results], 'lesson')
                setModules(_.sortBy(modules, 'order'))
                setModulesAndLessons(OrderedLessonsList)
                if (location?.state?.lesson !== null) {
                    setCurrentModule(location?.state?.module)
                    setCurrentLesson(location?.state?.lesson)
                } else if (location?.state?.module !== null) {
                    setCurrentModule(location?.state?.module)
                    setCurrentLesson(null)
                }
                if (location?.state == null) {
                    setCurrentModule(null)
                    setCurrentLesson(null)
                }
                if (mid) {
                    setCurrentModule(mid)
                }
                if (lid) {
                    setCurrentLesson(lid)
                }
            }
            )
    }, [cid])


    const scrollNavigation = useRef(null);

    useEffect(() => {
        const scrolableRef = (ref, number) => {
            ref?.current?.scroll({
                top: [0, 1, 2, 3].indexOf(number) != -1 ? 0 :
                    (number - 5) * 40,
                behavior: 'smooth'
            });
        };
        var counter = 0
        Modules.map(module => {
            counter += 1
            if (currentLesson == null && module.id == currentModule) {
                scrolableRef(scrollNavigation, counter)
            } else {
                _.sortBy(module.lesson, 'order').map(lesson => {
                    if (lesson.id != currentLesson) {
                        counter += 1
                    } else {
                        scrolableRef(scrollNavigation, counter)
                    }
                })
            }
        })
    }, [currentLesson, currentModule])


    const deleteModule = async (id) => {
        try {
            const deleteModule = await api.delete("/Module/" + id)
            api.delete("/Module/" + id)
                .then((result) => {
                    setModules(Modules.filter(p => p.id != id))
                    setCurrentModule(null)
                    setCurrentLesson(null)
                })
        } catch (error) {
            console.log(error?.status)
            setActionError(`Невозможно удалить модуль пока в нем есть уроки`)
        }
    }

    const AddNewModule = async (data) => {
        api.post("Module", { CourseId: cid, Name: data.newModuleName })
            .then((result) => {
                navigate(`/teachercabinete/course/${cid}/edit/module/${result.data.id}`)
                setCurrentModule(result.data.id)
                setCurrentLesson(null)
                EditorStore.setShowNewModuleForm(false)


                // const { course, ...AdditionalLesson } = result.data
                // setModules(prev => { return ([...prev, { ...AdditionalLesson, lessons: [] }]) })
                // setValue('newModuleName', null)
                // EditorStore.setShowNewModuleForm(false)
                // setModulesAndLessons(prev => { return ([...prev, { ...AdditionalLesson, lessons: [] }]) })


                // setCurrentModule()
            }
            )
    };

    const AddNewLesson = (newLesson, moduleId) => {
        api.post("/Lesson", {
            ...newLesson,
            Description: newLesson.description,
            LessonsDurationTypeId: 1,
            ModuleId: moduleId,
            Name: newLesson.label,
            isDemo: false
        })
            .then(result => {
                //добвавить добавление в список уроков
                var allModules = [...Modules]
                if (allModules.find(module => module.id == moduleId).lesson == null) {
                    allModules.find(module => module.id == moduleId).lesson = [result.data]
                } else {
                    allModules.find(module => module.id == moduleId).lesson.push(result.data)
                }
                setModules(allModules)
            })
    }

    const deleteLesson = (id, moduleId) => {

        const DelLess = (DeleteId, thenAction) => {
            api.delete(`/Lesson/` + DeleteId)
                .then(() => {
                    var allModules = [...Modules]
                    let ModuleIndex = allModules.findIndex(module => module.id == currentModule)
                    allModules[ModuleIndex].lesson = (allModules[ModuleIndex].lesson).filter(lesson => lesson.id != currentLesson)
                    setCurrentLesson(null)
                    setModules(allModules)
                    EditorStore.setDeleteLessonCommand(DeleteId)
                    thenAction()
                })
        }
        //    Найти урок меньший по индексу или модуль
        var allModules = [...Modules]
        var ModuleIndex = allModules.findIndex(module => module.id == currentModule)
        var CurrntIndex = allModules[ModuleIndex].lesson.findIndex(less => less.id == id)
        if (CurrntIndex != 0) {
            var PrevLessonIndex = allModules[ModuleIndex].lesson.findIndex(less => less.id == id)
            var prevLessonId = allModules[ModuleIndex].lesson[PrevLessonIndex - 1].id

            DelLess(id, () => { navigate(`lesson/${prevLessonId}`) })
        }
        else {
            DelLess(id, () => { navigate(`/teachercabinete/course/${cid}/edit/module/${allModules[ModuleIndex].id}`) })

        }




        // api.delete(`/Lesson/` + id)
        //     .then(() => {
        //         var allModules = [...Modules]
        //         let ModuleIndex = allModules.findIndex(module => module.id == currentModule)
        //         allModules[ModuleIndex].lesson = (allModules[ModuleIndex].lesson).filter(lesson => lesson.id != currentLesson)
        //         setCurrentLesson(null)
        //         setModules(allModules)
        //     })
    }

    const rebaseLesson = (newModule, lessonId, oldModuleID, rebaseLessonData) => {
        let ModulesCopy = [...Modules]
        let tLesson
        ModulesCopy.map(mod => {
            if (mod.id == oldModuleID) {
                tLesson = mod.lesson.filter(lesson => lesson.id == lessonId)[0]
            }
        })

        //удаление из старого модуля
        ModulesCopy.map((mod, index) => {
            if (mod.id == oldModuleID) {
                mod.lesson = [...mod.lesson].filter(les => les.id !== lessonId)
            }
        })
        //добавление в новый модуль
        ModulesCopy.map(mod => {
            if (mod.id == newModule) {
                mod.lesson.push(rebaseLessonData)
            }
        })
        setCurrentLesson(null)
        setModules(ModulesCopy)
    }

    const UpdateModuleData = (data) => {
        return api.put(`/Module/` + currentModule, { ...data })
            .then((result) => {
                var allModules = [...Modules]
                allModules.find(module => module.id == currentModule).name = data.Name
                setModules(allModules)
            })
    }
    return (
        <>
            {/* должно быть перенесено в outlet */}
            <Layout1200>
                <ListVertical style={{ paddingTop: '50px' }}>
                    {/* <div style={{ width: '-webkit-fill-available', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <h2 style={{ color: '#163C85', fontSize: '32px' }}>{course?.label}</h2>
                    </div> */}
                    <DefaultWrapperNoPadding>
                        {/* Форма добавления нового модуля */}
                        {
                            // showNewModuleForm ?
                            EditorStore.showNewModuleForm ?
                                <ModuleCardHeaderFull>
                                    <InputValidateUseForm
                                        title={'Название нового модуля'}
                                        type={'text'}
                                        error={errors?.newModuleName}
                                        validateSet={register("newModuleName", {
                                            required: "Пожалуйста, введите название",
                                        })}
                                    />
                                    <AcceptButton onClick={handleSubmit(AddNewModule)}
                                        title={'Сохранить'} />
                                    <RejectButton onClick={e => EditorStore.setShowNewModuleForm(false)}
                                        title={'Отмена'} />
                                </ModuleCardHeaderFull>
                                :
                                < ModuleWindow
                                    deleteLesson={deleteLesson}
                                    AddNewLesson={AddNewLesson}
                                    rebaseLesson={rebaseLesson}
                                    deleteModule={deleteModule}
                                    UpdateModuleData={UpdateModuleData}
                                    ModuleData={Modules.find(elem => elem?.id == currentModule) || null}
                                    CurrentModuleId={currentModule ?? mid}
                                    CurrentLessonId={currentLesson ?? lid}
                                    setLesson={setCurrentLesson}
                                    AllModules={Modules}
                                    course_id={cid}
                                />
                        }
                    </DefaultWrapperNoPadding>
                </ListVertical>
            </Layout1200>

            <MainPopup
                isOpened={(ActionError != null) ? true : false}
                onClose={e => setActionError(null)}
                title={'Внимание'}
                showHeader={true}
            >
                {ActionError}
                <RejectButton title={'Ок'} onClick={() => setActionError(null)} />
            </MainPopup>
        </>
    );
});

export default MainEditor;