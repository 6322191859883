import { getUserData, newLogIn, refreshToken, logout, api } from "app/api"
import { EmptyAvatar } from "shared/UI/Fillers"
import { makeAutoObservable } from "mobx"
import { host } from "app/globals"

class User {
    auth = false
    authInProgress = true
    online = false
    data = {
        id: '',
        mail: '',
        role_id: '',
        user_photo: null,
        user_nikname: '',
        user_name: '',
        user_surname: '',
        user_patronymic: '',
        user_gmt_time: '',
        user_photo_ID: '',
        user_sex: ''
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    async changeUser(result) {
        this.data = { ...this.data, ...result }
        // localStorage.setItem('userId', id)
        if (!localStorage.getItem('userId')) {
            this.setLocalStorageItem('userId', result.id)
        }
    }

    changeJwt(jwt) {
        this.setLocalStorageItem('token', jwt)
    }

    handlerStatus(message) {
        if (message.online) {
            this.online = true
        } else {
            this.online = false
        }
    }

    signIn() {
        this.auth = true
        this.authInProgress = false
    }

    signOut() {
        this.data = {}
        this.auth = false
    }

    setLocalStorageItem(key, value) {
        return new Promise((resolve) => {
            localStorage.setItem(key, value);
            resolve();
        });
    }

    getUserPhoto(user) {
        fetch(`${host}/api/GetFile/${user.data.user_photo_ID}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
            .then(response => response.status === 200 && response.blob(response))
            .then(blob => {
                if (blob) {
                    const fileURL = URL.createObjectURL(blob)
                    this.changeUser({ user_photo: fileURL })
                }
                else {
                    this.changeUser({ user_photo: EmptyAvatar })
                }
            })
            .catch((error) => { if (error.response.status === 404) { this.changeUser({ user_photo: EmptyAvatar }) } })
    }

    async logIn(fields) {
        try {
            const result = await newLogIn(fields);
            await this.setLocalStorageItem('token', result.data.token)
            const user = await getUserData();
            this.changeUser(user.data)
            await this.setLocalStorageItem('userId', user.data.id)
            this.getUserPhoto(user)
            this.signIn()
        } catch (error) {
            throw error
        }
    }

    async checkAuth() {
        this.authInProgress = true
        try {
            if (localStorage.getItem('token')) {
                const user = await getUserData();
                this.changeUser(user.data);
                this.getUserPhoto(user);
                this.signIn()
            } else {
                this.authInProgress = false
            }
        } catch (error) {
            localStorage.clear()
            this.signOut()
            this.authInProgress = false
        }
    }

    async logOut() {
        this.isAuthInProgress = true;
        try {
            await logout();
            localStorage.clear();
            this.signOut()
        } catch (err) {
        } finally {
            this.authInProgress = false;
        }
    }
}

export default new User()