import { makeAutoObservable } from "mobx"

class MySurveysStore {

    ActiveTry = false
    CurrentTry = null
    SendAnswerAction = null
    HasAnswer = false
    IsLastAnswer = false

    constructor() {
        makeAutoObservable(this)
    }
    async setHasAnswer(result) {
        this.HasAnswer = result
    }
    async setActiveTry(result) {
        this.ActiveTry = result
    }
    async setCurrentTry(result) {
        this.CurrentTry = result
    }
    async setAnswerAction(result) {
        this.SendAnswerAction = result
    }
    async SendIsLastAnswer(result) {
        this.IsLastAnswer = result
    }
}

export default new MySurveysStore();