import React, { useState, useEffect, useRef } from 'react';
import Output from 'editorjs-react-renderer';
import _ from 'lodash';
import renderers from 'entities/CustomRerenders/CustomRerenders';
import EmptyLesson from 'models/EmptyLesson/EmptyLesson';
import { ShowClasses } from 'entities/CustomRerenders/ShowConfigs/DefaultConfig';
import { StudyingWrap, LessonHeader, LessonArea, NavBtns, MoveBtn } from 'shared/UI/LearnPrev/LearmPrev.styled';
import { useParams } from 'react-router-dom';
import { api } from 'app/api';
import { LoaderBlueFullView } from 'shared/UI/Loaders';
import { observer } from 'mobx-react-lite';
import EducationNavigationList from 'models/StundentEducationNavigationList/StundentEducationNavigationList';
import { BlueWrap, ContentHorizontal } from 'shared/UI/Layouts';
import NavigateBtns from 'features/PersonalArea/CourseReaderNavigateBts/NavigateBtns';
import { CustomLink } from 'shared/UI/Links';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import { AcceptButton } from 'shared/UI/Buttons';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import { LNavBlueButton, LNavGrayButton } from './ui/LessonNavigation.styled';

const CoursesLesson = ({ LessonConten }) => {

    const { id_course, id_lesson, id_test } = useParams()
    const [lessonData, setLessonData] = useState(null)
    const [Loader, setLoader] = useState(false)

    useEffect(() => {
        setLoader(true)
        const getLessonContent = async () => {
            var response = await api.get("/Lesson/" + id_lesson)
            var result = { ...response.data, content: JSON.parse(response.data.content) }
            setLessonData(await result)
        }
        if (id_test === undefined) {
            getLessonContent()
        }
        setLoader(false)
    }, [id_lesson, id_test])

    const checkLast = () => {
        const ModuleIndex = EducationNavigationList.navigationList?.findIndex(module => module.id == EducationNavigationList.eductionIds.m_id)
        if (ModuleIndex === EducationNavigationList.navigationList?.length - 1) {
            if (EducationNavigationList.navigationList[ModuleIndex]?.lesson?.findIndex(lesson => lesson.id == id_lesson) === EducationNavigationList.navigationList[ModuleIndex]?.lesson?.length - 1) {
                return true
            }
            else {
                return false
            }
        } else {
            return false
        }
    }
    const checkFirst = () => {
        const ModuleIndex = EducationNavigationList?.navigationList?.findIndex(module => module?.id == EducationNavigationList?.eductionIds?.m_id)
        if (ModuleIndex === 0) {
            if (id_test === undefined && EducationNavigationList?.navigationList[0].lesson?.findIndex(lesson => lesson?.id == id_lesson) === 0) {
                return true
            } else {
                return false
            }
        }
        else {
            return false
        }
    }

    return (
        <StudyingWrap >
            <LessonHeader> {lessonData?.name} </LessonHeader>
            {
                Loader
                    ?
                    <LoaderBlueFullView />
                    :
                    <LessonArea >
                        <Output
                            classNames={ShowClasses}
                            data={(JSON.stringify(LessonConten) === '{}')
                                ?
                                EmptyLesson
                                :
                                lessonData?.content
                            }
                            renderers={renderers}
                        />

                    </LessonArea>

            }
            <LessonArea >

                <NavigateBtns
                    id_course={EducationNavigationList?.eductionIds?.c_id}
                    id_module={EducationNavigationList?.eductionIds?.m_id}
                    NavigateArrary={JSON.parse(JSON.stringify(EducationNavigationList?.navigationsActions))}
                    wrap={<NavBtns style={{ justifyContent: 'flex-end' }} />}
                    from={'learn'}
                    buttons={{
                        back:
                            <CustomLink style={{ width: 'min-content' }} >
                                {checkFirst()
                                    ? <LNavGrayButton><FaArrowLeft /></LNavGrayButton>
                                    : <LNavGrayButton><FaArrowLeft /></LNavGrayButton>}
                            </CustomLink>,
                        next:
                            <CustomLink style={{ maxWidth: '470px' }}>
                                {checkLast()
                                    ? <LNavBlueButton><p>Следующий урок</p> <FaArrowRight /></LNavBlueButton>
                                    : <LNavBlueButton><p>Следующий урок</p> <FaArrowRight /></LNavBlueButton>
                                }
                            </CustomLink>
                    }}
                />
            </LessonArea>
        </StudyingWrap>
    );
};

export default observer(CoursesLesson);