import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { api } from 'app/api';
import useDeviceInfomation from 'shared/hooks/AuthHooks';
import { PasswordRecovery } from 'pages/PersonalArea/PasswordRecovery';
import SetNewEmail from 'pages/PersonalArea/EmailChange/SetNewEmail';
import PageConfirmation from 'pages/Confirmation/PageConfirmation';
import userStore from 'shared/store/userStore';
import HocInviteRoute from './HocInviteRoute'

const HocConfirmRoute = observer(() => {
    const device = useDeviceInfomation()
    const [renderResut, setRenderResult] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation();
    const navigate = useNavigate()

    useEffect(() => {
        CheckAndSet()
    }, [])

    const CheckAndSet = async () => {
        const str = location.pathname.split('/')
        var cs = await api.post('/user/CheckString/' + str[str.length - 1], {
            device_type_id: device.type,
            platform: '123',
            geo: device.geo == {} ? "" : JSON.stringify(device.geo),
            system: device.system
        })
            .then((result) => { setRenderResult('error'); return (true) })
            .catch((error) => { if (error.response.status === 403) { setRenderResult('error'); return (false) } })

        if (cs === true) {
            location.pathname.indexOf('/confirmation/RecoveryPassword') > -1
                ?
                setRenderResult('RecoveryPassword')
                :
                location.pathname.indexOf('/SuccessRecovery') > -1
                    ?
                    setRenderResult('SuccessRecovery')
                    :
                    location.pathname.indexOf('/EmailChangeFirst') > -1
                        ?
                        setRenderResult('EmailChangeFirst')
                        :
                        location.pathname.indexOf('/EmailChangeSecond') > -1
                            ?
                            setRenderResult('EmailChangeSecond')
                            :
                            location.pathname.indexOf('/confirmation/ChangePassword') > -1
                                ?
                                setRenderResult('PasswordChange')
                                :
                                location.pathname.indexOf('/confirmation') > -1
                                    ?
                                    setRenderResult('Confirm')
                                    :
                                    location.pathname.indexOf('invite_group') > -1
                                        ?
                                        setRenderResult('invite_group')
                                        :
                                        ConfirmChange()
        }
    }

    const ConfirmChange = () => {
        api.post(`/user${location.pathname}`, {
            device_type_id: device.type,
            platform: '123',
            geo: device.geo == {} ? "" : JSON.stringify(device.geo),
            system: device.system
        })
            .catch(error => {
                setRenderResult('error');
                if (error.response.status === 403) {
                }
            })
            .then(result => {
                if (result.data.type) {
                    setRenderResult(result.data.type)
                }
                localStorage.setItem('token', result.data.token)
            })
    }

    const ChangePasswordApi = () => {
        const string = location.pathname.split('/confirmation/ChangePassword')
        return api.post(`/user/confirmation${string[1]}`, {})
    }

    switch (renderResut) {
        case 'RecoveryPassword':
            return (<PasswordRecovery />);
        case 'Confirm':
            return (<PageConfirmation />);
        case 'PasswordChange':
            ChangePasswordApi()
                .then((response) => {
                    userStore.changeJwt(response.data.token)
                    navigate('/profile/password', { state: { change: 'success', message: 'Пароль успешно изменен' } })
                })
                .catch(() => { return console.log('ошибка') })
            break
        case 'EmailChangeFirst':
            return (<SetNewEmail />);
        case 'EmailChangeSecond':
            return (<SetNewEmail emailChangeSecond={true} />);
        case 'invite_group':
            return (<HocInviteRoute />);
        // return (<></>
        // <Navigate to={'/profile/mail'} state={{ change: 'success', message: 'Почта успешно изменена' }} />
        // );
        case 'error':
            return <p>Ссылка более недействительна</p>;
        case null:
            return setRenderResult('Неизвестная ошибка');
    }
});

export default HocConfirmRoute;