import { api, getUserData } from 'app/api';
import { MainWrap } from 'shared/UI/Layouts';
import { observer } from 'mobx-react-lite';
import useDeviceInfomation from 'shared/hooks/AuthHooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import userStore from 'shared/store/userStore';

const PasswordConfirm = observer(() => {
    const { link } = useParams()
    const [Result, setResult] = useState('Ваш пароль изменён')
    const device = useDeviceInfomation()
    const [isResult, setIsResult] = useState(false);

    useEffect(() => {
        ConfirmChange()
    }, [])

    const ConfirmChange = () => {
        api.post(`/user/confirmation/` + link, {
            device_type_id: device.type,
            platform: '123',
            geo: device.geo == {} ? "" : JSON.stringify(device.geo),
            system: device.system
        })
            .catch(error => {
                if (error.response.status === 403) {
                    setResult('Ссылка более недействительна');
                }
            })
            .then(result => {
                localStorage.setItem('token', result.data.token)
                getUserData()
                    .then((result) => {
                        localStorage.setItem('userId', result.data.id)
                        userStore.changeUser(result.data);
                        userStore.signIn()
                        setIsResult(true)
                        // timer = setTimeout(goHome, 5000)
                        userStore.getUserPhoto(result)
                    })
            })
    }

    return (
        <MainWrap>
            {Result}
            <button> ОК </button>
        </MainWrap>
    );
})

export default PasswordConfirm;