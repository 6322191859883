import { CourseContentDetailed } from 'entities/TeacherArea';
import { ModuleCardDetailed } from 'entities/TeacherArea/CourseContentDetailed/styled/CourseContentDetailedStyled';
import { ProfileBackButton } from 'shared/UI/Buttons/ProfileBackButton/ProfileBackButton';
import React from 'react';
import { CustomLink } from 'shared/UI/Links';

const TeacherCourseContent = ({ modulesAndLessons, cid, setState }) => {
    return (
        <>
            {modulesAndLessons !== undefined ?
                <>
                    {
                        modulesAndLessons == null || modulesAndLessons.length == 0 ?
                            <ModuleCardDetailed style={{ alignItems: 'center' }}>
                                <p>
                                    В вашем курсе пока что нет уроков, чтобы опубликовать курс, наполните его модулями и уроками
                                </p>
                                <CustomLink to={`/teachercabinete/course/${cid}/edit/module`}>
                                    <ProfileBackButton>
                                        Перейти к наполнению
                                    </ProfileBackButton>
                                </CustomLink>
                            </ModuleCardDetailed>
                            :
                            modulesAndLessons?.map((module, index) => (
                                <CourseContentDetailed
                                    module={module}
                                    index={index}
                                    cid={cid}
                                    key={module.id}
                                    mode='edit'
                                    setState={setState}
                                />))
                    }
                </>
                :
                null

            }

        </>
    );
};

export default TeacherCourseContent;