import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import userStore from 'shared/store/userStore';
import { ListVertical } from 'shared/UI/Layouts';
import { FaPen } from 'react-icons/fa6';
import { EditProfileForm, ProfileHeader } from 'pages/PersonalArea/PersonalInormation/styled/ProfileStyles';
import ChangeField from 'entities/PersonalSecurity/ChangeField/ChangeField';

const SecurityMainWidget = observer(() => {
    useEffect(() => {

    }, [])

    const HideMail = () => {
        const pad = (userStore.data.mail).split('@')
        return (pad[0].slice(0, 2) + '***@' + pad[1])
    }

    const SecurityParam = [
        { param: 'Пароль', value: '******', button: <FaPen />, to: '/profile/password' },
        { param: 'Электронная почта', value: HideMail(), button: <FaPen />, to: '/profile/mail' }
    ]

    return (
        <EditProfileForm  >
            <ListVertical>
                <ProfileHeader>Безопасность</ProfileHeader>
                {SecurityParam.map((elem, index) => (
                    <ChangeField param={elem.param} key={index} value={elem.value} to={elem.to} button={elem.button} />
                ))}
            </ListVertical>
        </EditProfileForm >
    );
});

export default SecurityMainWidget;