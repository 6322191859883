import { AcceptButton, SimpleWhiteButton } from 'shared/UI/Buttons';
import { ContentHorizontal, ListVertical } from 'shared/UI/Layouts';
import React, { useEffect, useState } from 'react';
import { TestTimer } from 'features/TestArea/TestTimer';
import { LessonsSidebar, DefaultWrapper2 } from 'shared/UI/LearnPrev/LearmPrev.styled';
import { observer } from 'mobx-react-lite';
import MySurveysStore from 'shared/store/MySurveysStore';
import { ColRowWrap } from 'widgets/QuestionRender/styled/ColRowWrap.styled';
import { LoaderBlueFullView } from 'shared/UI/Loaders';

const QuestionStepper = observer(({ List, CurrentId, setCurrent, EndAction, children, testParams, LastAction, AnswersList, ...props }) => {
  const [LocalList, setLocallist] = useState([])
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    setShowLoader(false)
  }, [CurrentId])

  useEffect(() => {
    AnswersList.indexOf(CurrentId) > -1 && setShowLoader(false)
  }, [AnswersList])

  // useEffect(() => {
  //   MySurveysStore.IsLastAnswer && LastAction()
  // }, [MySurveysStore.IsLastAnswer])



  useEffect(() => {
    setLocallist(List)
  }, [List])

  const TestLength = LocalList?.length
  const FindIndex = (id) => { return (LocalList?.indexOf(LocalList.find(el => el.question.id == id))) }

  const PrevOrNext = (direction) => {
    const CurrentIndex = LocalList.indexOf(LocalList.find(q => q.question.id == CurrentId))
    if (direction == 'next') {
      setCurrent(LocalList[CurrentIndex + 1].question?.id)
      setShowLoader(false)
    } else {
      setCurrent(LocalList[CurrentIndex - 1].question?.id)
      setShowLoader(false)
    }
  }
  return (
    <>
      {/* {console.log({ testParams })} */}
      <LessonsSidebar top='0'>
        <DefaultWrapper2 padding={'20px'} gap={'10px'}>
          <TestTimer TestDuration={testParams?.duration} EndAction={EndAction} />
          <ColRowWrap gap={'10px'} repeat='repeat(5, 1fr)'>
            {//Перебор по списку вопросов
              LocalList?.map((Elem, index,) => (
                <SimpleWhiteButton
                  style={CurrentId == Elem.question.id ?
                    {//Если это вопрос сейчас открыт то он имеет !синеватый! цвет
                      backgroundColor: '#163C85',
                      color: '#FFF'
                    }
                    :
                    //Если  вопрос не открыт, но он в списке отвеченных
                    AnswersList?.find(el => el == Elem.question.id) != null
                      ?
                      {
                        backgroundColor: '#FFC977', // желтоватый
                        color: '#B17923'
                      }
                      : {
                        backgroundColor: '#E8F1FF', // желтоватый
                        color: '#163C85'
                      } //иначе без цвета
                  }
                  key={Elem?.question.id}
                  onClick={e => { setCurrent(Elem?.question.id) }}>
                  {index + 1}
                </SimpleWhiteButton>
              ))
            }
          </ColRowWrap >
        </DefaultWrapper2>
      </LessonsSidebar>
      <DefaultWrapper2 padding='40px max(8%, 10px)' style={{ position: 'relative' }}>
        <ListVertical style={{ width: '100%', height: '100%', justifyContent: 'space-between' }}>
          {children}
          <ContentHorizontal style={{ width: '100%', justifyContent: 'space-between' }}>
            <ContentHorizontal>


              <AcceptButton title='Завершить тест' onClick={e => LastAction()} />

            </ContentHorizontal>
            <ContentHorizontal>
              <AcceptButton disabled={FindIndex(CurrentId) == 0} title='Назад' onClick={e => PrevOrNext('prev')} />
              <AcceptButton
                // disabled={!MySurveysStore.HasAnswer}
                title={showLoader ? <LoaderBlueFullView style={{ width: '24px', height: '24px', border: '2px solid #fff', borderTop: '2px solid transparent' }} /> : 'Далее'}
                onClick={() => {
                  if (MySurveysStore.HasAnswer) {
                    // console.log('есть отает')
                    MySurveysStore.setAnswerAction(true);
                    setShowLoader(true)
                  }
                  else {
                    PrevOrNext('next')
                  }
                }}
                style={{ height: '48px', width: '134px' }}
              />
              {/* <AcceptButton disabled={FindIndex(CurrentId) == (TestLength - 1)} title='Далее' onClick={e => PrevOrNext('next')} /> */}
            </ContentHorizontal>
          </ContentHorizontal>
        </ListVertical>
      </DefaultWrapper2>
    </ >

  );
});

export default QuestionStepper;