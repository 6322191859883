
import React from 'react';
import { CustomLink } from 'shared/UI/Links';
import { ChangerWrap, ProfileTextBold } from './ui';

const ChangeField = ({param, value, to, button}) => {
    return (
        <ChangerWrap>
            <ProfileTextBold>{param}</ProfileTextBold>
            <ProfileTextBold>{value}</ProfileTextBold>
            <CustomLink to={to}><ProfileTextBold>{button}</ProfileTextBold></CustomLink>
        </ChangerWrap>
    );
};

export default ChangeField;