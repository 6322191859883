import styled from "styled-components";
import { DefaultWrapper } from "shared/UI/Wrappers";
export const PageRegTeacherWrap = styled.div`
       display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0 30px 0;
`

export const RegFormWrap = styled(DefaultWrapper)`
    padding: 32px;
    width: 376px;
    border-radius: 30px;
`