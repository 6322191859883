import { ListVertical } from "shared/UI/Layouts";
import styled from "styled-components";


//Заготовка, пока что не используется из - за сложностей
export const nameLesson = styled.div`
p {
    margin-bottom: 15px;}
`

export const descriptionLesson = styled.div`
& p{
    margin-bottom: 15px;

}
`

export const radioWrap = styled.div`
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    gap: 15px;
    & div{
        gap: 5px;
    }
`

export const LessonOrder = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
`

export const addLessonSection = styled.div`
    /* composes: defaultStyle from 'styleexport const moduleexport const css'; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    /* max-width: 80%; */
    min-width: 100%;
    margin: 0 auto;
    /* margin-bottom: 20px; */
`

export const addLessonBtn = styled.div`
    padding: 16px 20px;
    width: 100%;
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
`


export const durationlesson = styled.div`
    flex-direction: row;
    align-items: stretch;
    gap: 10px;
    display: flex;
    width: 100%;
    max-width: unset;
    & p { 
        min-width: max-content;
    }
`

/* export const durationlesson input = styled.div`
    max-width: 100px;
` */

export const Radio = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    & h2 
    {    font-size: 14px;}

    & li {display: flex;
        gap: 10px;}
`



export const RadioField = styled.div`
    position: relative;
        & input[type = radio] { 
            overflow: hidden;
            display: grid;
            place-content: center;
            appearance: none;
            background-color: hsl(0, 0%, 82%);
            margin: 0;
            font: inherit;
            color: currentColor;
            width: 130px;
            height: 40px;
            border-radius: 12px;
            cursor: pointer;
            position: relative;
        }

       &  input[type = "radio"]:checked
        {
            background-color: #246ffb;
            transition: background-color 0export const 2s;
        }

        & input[type = "radio"]:checked + label  { 
            color: #fff;
        }

        & input[type = "radio"]:checked+span { 
            color: #fff;
        }
        & label { 
            position: absolute;
            z-index: 9;
            width: 100%;
            height: 100%;
            z-index: 10;
            top: 0;
            margin-bottom: 0;
        }
        & span { 
            display: flex;
            justify-content: center;
            cursor: pointer;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            align-items: center;
        }
`

export const AddLessonDurationInput = styled.div`
input{
    width: 100px;
}
`


export const OrderInput = styled.div`
    width: 75px;
`

export const addLessonTime = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: unset;
`

export const durationWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

export const FormContent = styled(ListVertical)`
    max-height: 500px;
    overflow-y: scroll;
    padding: 0 20px;

    &::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;  
    background-color: #D9D9D9;
}

&::-webkit-scrollbar-thumb {
    border-radius: 10px;   
    background-color:var(--base-blue-color);   
}
    &>section{
        align-items: flex-start;
        gap: 0;
    }
    &>p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    position: relative;
}

&>p>span.activeIcon{
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}
    `