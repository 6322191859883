import ContentHorizontal from "shared/UI/Layouts/ContentHorizontal";
import styled from "styled-components";

export const SurveyLobbyParamsWrap = styled.div`
display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 800px;
    margin: 0 auto;
    gap: 40px 30px;
`
