import React from 'react';
import { FullWrap } from 'shared/UI/Wrappers';
import ChangePassword from 'widgets/PersonalSecurity/Password/ChangePassword';

const PasswordChanger = () => {
    return (
        <FullWrap>
            <ChangePassword />
        </FullWrap >
    );
};

export default PasswordChanger;