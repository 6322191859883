import React, { useEffect, useState } from 'react';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { LessonsNavigate, LessonsNavigateWrap, NavigateLesson, NavigateModule, NavigateTest } from 'shared/UI/LearnPrev/LearmPrev.styled';

const СourseСontentNavigation = ({ cid, mid, lid, tid, ScrollRef, modulesAndLessons, onClickModule, onClickLesson, onClickTest, ActiveModule, ActiveLesson, ActiveTest }) => {
    const navigate = useNavigate()

    const [newLocation, setnewLocation] = useState(null)
    useEffect(() => {
        navigate(newLocation)
    }, [newLocation])

    return (
        <LessonsNavigateWrap>
            {/* <LessonsNavigateWrap> */}
            <LessonsNavigate ref={ScrollRef} >
                {modulesAndLessons?.map((module, Mindex) => (
                    <ol style={{ width: '100%' }} key={'module_' + Mindex + '_' + 'id_' + module?.id}>
                        <NavigateModule
                            $active={module.id == mid && ActiveModule}
                            onClick={() => { onClickModule(module.id, module?.lesson[0]?.id) }}
                        >
                            <p title={(Mindex + 1) + '. ' + module.name}>
                                {(Mindex + 1) + '. ' + module.name}
                                {module.id == mid && ActiveModule ? <span className={'activeIcon'}><FaChevronRight /></span> : null}
                            </p>
                        </NavigateModule>
                        {module?.lesson?.map((lesson, Lindex) => (
                            <>
                                <NavigateLesson
                                    onClick={() => onClickLesson(module.id, lesson.id)}
                                    $active={(lid == lesson.id || ActiveLesson) && tid == undefined}
                                >
                                    <p title={`${Mindex + 1}.${Lindex + 1} ${lesson.name}`}>
                                        {`${Mindex + 1}.${Lindex + 1} ${lesson.name}`}
                                        {(lid == lesson.id || ActiveLesson) && tid == undefined ? <span className={'activeIcon'}><FaChevronRight /></span> : null}
                                    </p>
                                </NavigateLesson>
                                {lesson?.surveys?.length > 0
                                    ?
                                    <NavigateTest
                                        onClick={() => onClickTest(module.id, lesson.id, lesson?.surveys[0].id)}
                                        $active={tid == lesson?.surveys[0].id}
                                        key={'test' + Mindex + '_' + lesson.id + '_' + lesson?.surveys[0].id}
                                    >
                                        <p>
                                            Тест: {lesson?.surveys[0].label}
                                            {tid == lesson?.surveys[0].id ?
                                                <span className={'activeIcon'}><FaChevronRight /></span>
                                                : null}
                                        </p>
                                    </NavigateTest>
                                    :
                                    null
                                }
                            </>
                            // </div>
                        ))}
                    </ol>
                )
                )}
            </LessonsNavigate >
        </LessonsNavigateWrap >
    );
};

export default СourseСontentNavigation;