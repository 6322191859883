import React from 'react';
import { Link } from 'react-router-dom';
import cl from './AcountAvatar.module.css';
import { observer } from 'mobx-react-lite';
import userStore from 'shared/store/userStore';

const AccountAvatar = observer(({ size = '50px' }) => {
    const avatar = `${userStore.data.user_photo}`
    return (
        <div className={cl.account_Avatar} style={{ width: size, minWidth: size, height: size, minHeight: size }} >
            <img src={avatar} />
        </div>
    )
});

export default AccountAvatar;