import styled from "styled-components";

export const TeacersCoursesListStyled = styled.div`
display: flex;
    flex-direction: column-reverse;
    gap: 15px;
    margin-bottom: 20px;
    /* width: 70%; */
    /* max-width: 1130px; */
    width: 100%;
`