import React, { useEffect, useState } from 'react';

const TestTimer = ({ TestDuration, EndAction }) => {
    const [seconds, setSeconds] = useState(TestDuration)
    const [isSendConfirm, setIsSendConfirm] = useState(true)
    useEffect(() => {
        setSeconds(TestDuration)
        console.log(TestDuration)
    }, [TestDuration])

    useEffect(() => {
        console.log(seconds, isSendConfirm)
        if (seconds > 0 && isSendConfirm) {
            const timer = setInterval(() => setSeconds(seconds - 1), 1000)
            return () => clearInterval(timer)
        } else if (seconds <= 0 && isSendConfirm) {
            setIsSendConfirm(false)
            setSeconds(TestDuration)
            EndAction()            
        }
    }, [seconds, isSendConfirm])
    return (
        <div> 
            Осталось: {seconds > 60}
            <span>{(seconds > 60 ? Math.floor(seconds / 60) : '') +
                (seconds > 60 ? ' мин. ' : '')}
            </span>
            <span>
                {seconds > 0 ?
                    + (seconds - (seconds - seconds % 60)).toFixed(0) + ' с. ' : 'тест окончен'}
            </span>
        </div>
    );
};
export default TestTimer;   