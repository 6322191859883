const GmtLoactions =  [
    { id: 'time_1', value: '-1' },
    { id: 'time_2', value: '0' },
    { id: 'time_3', value: '1' },
    { id: 'time_4', value: '2' },
    { id: 'time_5', value: '3' },
    { id: 'time_6', value: '4' },
    { id: 'time_7', value: '5' },
    { id: 'time_8', value: '6' },
    { id: 'time_9', value: '7' },
    { id: 'time_10', value: '8' },
    { id: 'time_11', value: '9' },
] 
export default GmtLoactions