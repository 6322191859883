import { invite_group, token } from "app/globals";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";

const HocInviteRoute = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const cookies = new Cookies()

    const url = location.pathname.split('/')[location.pathname.split('/').indexOf('group') + 1]
    const urlParam = searchParams.get('invite_group')

    useEffect(() => {
        if (token() && url !== null || urlParam !== null) {
            if (urlParam !== null) {
                cookies.set('invite_group', urlParam)
            }
            if (url !== undefined) {
                cookies.set('invite_group', url)
            }
            navigate(`/mylearn/mycourses`)
        } else {
            cookies.set('invite_group', url)
            navigate(`/login`)
        }
    }
        , [])
};

export default HocInviteRoute;