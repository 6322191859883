import React from 'react';
import { MainPopup } from 'shared/UI/Popups';
import { CourseCreateModalStyled } from './ui';
import { CourseCreationSteps } from 'entities/TeacherArea';

const CourseCreateModal = ({ Visibility, VisibilityToggle, Courses, Subjects, setCourses }) => {
    return (
        <CourseCreateModalStyled
            isOpened={Visibility}
            onClose={e => VisibilityToggle(false)}
            title={'Создание курса'}>
            <CourseCreationSteps
                Courses={Courses}
                subjects={Subjects}
                setCourses={setCourses}
                setNewCoursPopupVisibility={VisibilityToggle}
            />
        </CourseCreateModalStyled>
    );
};

export default CourseCreateModal;