import { BlueButton, ColorBtnSample, RedButton } from "shared/UI/Buttons/ColorBtns";
import styled from "styled-components";


export const RedModuleBtn = styled(RedButton)`
    padding: 12px 20px;
    line-height: 1;
    max-width: 120px;
    height: fit-content;
    font-size: 16px;
    font-weight: 500;
`
export const BlueModuleBtn = styled(BlueButton)`
    padding: 12px 20px;
    line-height: 1;
    max-width: 120px;
    height: fit-content;
    font-size: 16px;
    font-weight: 500;
    
`