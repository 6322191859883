import React from 'react';
import cl from './InputValidateRadio.module.css'
import cn from 'classnames';
import _ from 'lodash'
import { ValidateInputStyled } from '../InputValidateUseForm';
const InputValidateRadio = ({ InputClass, RadioClass, RadioList, validateSet, checked, error, title, ...props }) => {
    return (
        <>
            <ValidateInputStyled className={cn(cl.checkBoxes, InputClass)}>
                {title ? <label>{title}</label> : null}
                <li>
                    {RadioList?.map(radio => (
                        <div key={radio.id} className={RadioClass}>
                            {/* {String(String(radio.value) == String(checked))} */}
                            <input
                                id={radio.id}
                                type="radio"
                                value={radio.value}
                                checked={ _.isEqual(radio.value, checked)}
                                // defaultChecked={_.isEqual(radio.value, checked)}
                                {...validateSet}
                            />
                            <label htmlFor={radio.id}>
                                <span>{radio.title ?? radio.value}</span>
                            </label>
                        </div>
                    ))
                    }
                </li>

                {error && <div className={cl.ValidateError}> {error.message || "Пожалуйста выберите параметр"}</div>}
            </ValidateInputStyled>
        </>
    );
};

export default InputValidateRadio;