import { motion } from 'framer-motion'
import styled from "styled-components";

export const scaleAnim = {
    scale: { scale: 1.05 }
}

export const moveIcon = {
    scale: { y: -24, scale: 1.05 }
}

export const MotionA = styled(motion.a)`
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 20px;
    background: #fff;
    flex: 1;
    position: relative;
    overflow: hidden;
    font-weight: 400;
    box-shadow: 1px 4px 30px #00000010;
    justify-content: space-between;

    &> p {
    font-size: 24px;
    }
    & span {
        font-size: 14px;
    }
    &> p,
    &> span {
        z-index: 1;
        color: #163C85
    }
    &:hover{
        box-shadow: 2px 10px 20px #00000030;
    }
`
export const MotionI = styled(motion.i)`
    position: absolute;
    right: 33px;
    bottom: -120px;
    color: #A5BDEA;
    z-index: 0;
`