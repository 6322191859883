import React from 'react';
import { CancelButton } from 'shared/UI/Buttons';
import { ListVertical } from 'shared/UI/Layouts';

const RegistrationGoesWrong = ({ CancelAction, error }) => {
    return (
        <ListVertical>
            <p>{error?.responseError ??' Что-то пошло не так!'}</p>
            <CancelButton
                title={'Попробовать ещё раз?'}
                onClick={e => CancelAction()}
            />
        </ListVertical>
    )
};

export default RegistrationGoesWrong;