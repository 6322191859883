import React from 'react';
import './style.css'
import { MainWrap } from 'shared/UI/Layouts';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import cl from './style.module.css'

const TeacherCabinete = observer(({ headerTitle }) => {
    return (
        //Ну что смотришь, у тебя наверное вопрос зачем, а гланое нахуя? А я ваще хз, просто)
            <Outlet />
   
    )
})
export default TeacherCabinete