import styled from "styled-components";
import { CustomLink } from "shared/UI/Links";

export const ToCourseBtn = styled(CustomLink)`
    text-align: left;
    width: 100%;
    justify-content: flex-start;
    color: #163C85;
    font-size:  16px ;
    font-weight: 600;
    gap: 5px;
    padding:  10px 10px 10px 0;
    &>svg{
        font-size: 30px
    }
    &>p{
        width: 100%;
        text-align: left;
    }
`