import React, { useState, useEffect } from 'react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import { ListVertical, MainWrap } from 'shared/UI/Layouts';
import cl from './CourseShow.module.css'
import _ from 'lodash';
import { api } from "app/api"
import { TextAndBackHead } from 'shared/UI/Other';
import { Layout1200 } from 'shared/UI/Layouts';
import StudentCourseModulesAndLessons from 'widgets/StudentArea/StudentCourseModulesAndLessons/StudentCourseModulesAndLessons';
import CourseProgress from 'widgets/StudentArea/CourseProgress/CourseProgress';
import { GreenTag } from 'entities/StudentArea/CardCourseStudent/styled/greenTag.styled';

const CourseShow = () => {
    const { id_course, id_module, id_lesson, id_test } = useParams()
    const [dataCourse, setData] = useState(null)
    const [Directions, setDirections] = useState()
    const [ModulesAndLessons, setModulesAndLessons] = useState({})
    const [LessonsAmount, setLessonsAmount] = useState(0)
    var newlessonOrder;

    useEffect(() => {
        api.get(`/Module/Course/${id_course}`)
            .then((result) => {
                setData(result.data.course)
                setModulesAndLessons(_.sortBy(result.data.results, 'order'))
                var Lessons = 0
                result.data.results.map(module => Lessons += Number(module.lesson.length))
                setLessonsAmount(Lessons)
            })
    }, [id_course])

    if (dataCourse !== null && ModulesAndLessons !== null) {
        if (ModulesAndLessons[ModulesAndLessons.length - 1] === undefined) {
            newlessonOrder = 1;
        } else {
            newlessonOrder = Number(ModulesAndLessons[ModulesAndLessons.length - 1]['order']) + 1;
        }
        return (
            <>
                {id_lesson !== undefined || id_test !== undefined
                    ?
                    <Outlet />
                    :
                    <MainWrap headerTitle={'Просмотр курса'} >
                        <Layout1200>
                            <TextAndBackHead />
                        </Layout1200>                      
                        <ListVertical className={cl.CoursShowWrap} >
                            <CourseProgress
                                CourseData={dataCourse}
                                lessonsCount={LessonsAmount}
                            />
                            <StudentCourseModulesAndLessons
                                ModulesAndLessons={ModulesAndLessons}
                                id={id_course}
                            />
                        </ListVertical>
                    </MainWrap >
                }
            </>
        )
    }
}
export default CourseShow