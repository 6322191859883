import styled from "styled-components";
export const ModerationWrap = styled.div`
    width: 100%;
`

export const ModerationLabel = styled.div`   
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;  
`



export const Statuses = styled.div`
    width: 150px;
    line-height: 150%;
    max-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 11px;
    font-size: 14px;
    font-weight: 600;
      border-radius: 11px;`

export const Publication = styled(Statuses)`
    background-color:  ${props => props.$status === true
        ? 'var(--color-green2-light-default)'
        : 'var(--color-red-light-default)'};
    color:  ${props => props.$status
        ? 'var(--color-green2-default)'
        : 'var(--color-red-default)'};
  
    `

export const Moderation = styled(Statuses)`
    background-color:  ${props => props.$status === 0
        ? 'var(--color-green2-light-default)'
        : props.$status === 1
            ? 'var(--color-yellow-default)'
            : props.$status === 2
                ? 'var(--color-red-light-default)'
                : 'var(--base-disable-bgc2)'};
    color:  ${props => props.$status === 0
        ? 'var(--color-green2-default)'
        : props.$status === 1
            ? 'var(--color-yellow-dark-default)'
            : props.$status === 2
                ? 'var(--color-red-default)'
                : 'var(--base-disable-text)'};
    border-radius: 11px;
`