import styled from "styled-components";
import { AcceptButton } from "shared/UI/Buttons";

export const LNavBlueButton = styled.div`
    max-width: 471px;
    /* height: 64px; */
    padding: 20px 20px;
    color: #fff;
    background-color: var(--blue-button-color);
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    max-width: 470px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
    transition: .3s linear;
& > p{
    height: min-content;
    width: 100%;
    text-align: center;
    transform: translateY(-1px);
    line-height: 1;
    white-space: nowrap

    
    
}
&:hover{
    background-color: #5f9dff
}

`
export const LNavGrayButton = styled(LNavBlueButton)`
    width: min-content;
    color: #000;
    background-color: #DCDCDC;
    
&:hover{
    background-color: #c5c5c5
}

`

