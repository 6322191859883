import { Route, Routes, Navigate } from 'react-router-dom';
import { useEffect } from 'react';

import TeacherCabinete from '../pages/TeacherArea/TeacherCabinete';
import TeacherСourses from 'pages/TeacherArea/TeacherСourses/TeacherСourses';
import MyLearn from '../pages/StudentArea/StudentCoursesList/MyLearn';
import Constructor from '../pages/TeacherArea/TeacherCourseRedactor/Constructor';
import CourseEdit from '../pages/TeacherArea/TeacherCourseEdit/CoursEdit';
import CourseShow from '../pages/StudentArea/StudentCoursesList/courseShow/CourseShow';
import HocPrivateRoute from 'shared/hoc/HocPrivateRoute';
import PageRegTeacher from 'pages/Registration/PageRegTeacher';
import PageLogin from 'pages/Autorisation/PageLogin';
import Layout from 'shared/UI/Layouts/Layout';
import { observer } from 'mobx-react-lite';
import userStore from 'shared/store/userStore';
import MyCourses from 'pages/StudentArea/StudentCoursesList/MyCourses';
import SearchCourses from 'pages/SearchCourses';
import CoursInformation from 'pages/TeacherArea/CoursInformation/CoursInformation';
// import ModulesAndLessons from 'pages/TeacherArea/TeacherCourseEdit/ModulesAndLessons/ModulesAndLessons';
import ProfilePage from 'pages/PersonalArea/PersonalInormation';
import Profile from 'pages/PersonalArea/PersonalInormation/Profile/Profile';
import Payments from 'pages/PersonalArea/ResonalPayments';
import AboutProduct from 'pages/AboutProduct';
import NotFound from 'app/components/system/NotFound/NotFound';
import HomePage from 'pages/HomePage';
import PageConfirmation from 'pages/Confirmation/PageConfirmation';
import Connection from 'pages/TeacherArea/ConnectionPage/Connection';
import LearnPrev from 'pages/StudentArea/StudentCoursesList/LessonShow/LearnPrev/LearnPrev';
import { PasswordChanger } from 'pages/PersonalArea/PasswordChange';
import { PasswordConfirm } from 'pages/PersonalArea/PasswordConfirm';
import { PasswordRecovery } from 'pages/PersonalArea/PasswordRecovery';
import { HocConfirmRoute, HocDirectRoute } from 'shared/hoc';
import EmailChangeQuerry from 'pages/PersonalArea/EmailChange/EmailChangeQuerry';
import SetNewEmail from 'pages/PersonalArea/EmailChange/SetNewEmail';
import CourseContentEditor from 'pages/TeacherArea/CourseContentEditor/CourseContentEditor';
import AddTestPage from 'pages/TeacherArea/TeacherTestRedactor/AddTestPage';
import { SurveyLobby } from 'pages/StudentArea/StudentCoursesList/courseShow/SurveyLobby';
import { TestPassing } from 'pages/StudentArea/StudentCoursesList/courseShow/TestPassing';
import SecurityMain from 'pages/PersonalArea/PersonalSecurity/SecurityMain';
import CourseDetail from 'pages/CoursesDetail/CourseDetail';
import LessonDemo from 'pages/LessonDemo/LessonDemo';
import LessonEditPreview from 'pages/LessonEditPreview/LessonEditPreview';
import ConfirmationByLink from 'pages/Confirmation/ConfirmationByLink';
import TeacherModuledEditor from 'pages/TeacherArea/TeacherModuledEditor/TeacherModuledEditor';

const App = observer(() => {
  useEffect(() => {
    userStore.checkAuth()
  }, [])

  return (
    <>
      <Routes>
        <Route path="/regteacher" element={<PageRegTeacher />} />
        <Route path="/login" element={<PageLogin />} />
        <Route path="/SetNewEmail" element={<SetNewEmail />} />
        <Route path="/About" element={<AboutProduct headerTitle={'О нас'} />} />
        <Route path="/" element={<HocDirectRoute />}>
          <Route path='/confirmation/*' element={<ConfirmationByLink />} />
          <Route path='ChangePassword/:link' element={<PasswordConfirm />} />
          <Route path="/" element={<HocPrivateRoute />}>
            <Route element={<Layout />}>
              <Route path="/" element={<Navigate to='/home' replace />} />
              <Route path="home" element={<HomePage />} />
              <Route element={<HocPrivateRoute role={2} />}>
                <Route path="teachercabinete/*" element={<TeacherCabinete headerTitle={'Кабинет автора'} />} >
                  <Route path="course/:cid/edit/module/:mid/lesson/:lid/survey/:id" element={<AddTestPage headerTitle={'Создание теста'} />} />
                  <Route path="course/*">
                    <Route index path="list" element={<TeacherСourses />} />
                    <Route path=":cid/*" element={<CourseEdit />}>
                      <Route path="info" element={< CoursInformation />} />
                      <Route path="edit/*" element={<CourseContentEditor />} >
                        <Route path="module/:mid/*" element={<TeacherModuledEditor />}>
                          <Route path="lesson/:lid/*" element={<TeacherModuledEditor />}>
                            <Route path="survey/:tid" element={<AddTestPage headerTitle={'Создание теста'} />} />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route path='profile/*' element={<ProfilePage headerTitle={'Профиль'} />}>
                <Route path='main' element={<Profile />} />
                <Route path='payments' element={<Payments />} />
                <Route path='security' element={<SecurityMain />} />
                <Route path='password' element={<PasswordChanger />} />
                <Route path='mail' element={<EmailChangeQuerry />} />
              </Route>
              <Route path="/courses/allcourses" element={<SearchCourses headerTitle={'Поиск курсов'} />} />
              <Route path="/courses/coursedetail/:id" element={<CourseDetail />} />
              <Route path="*" element={<NotFound />} />
              {/* ------------------------------------------------- */}
              <Route path="/courses/constructor" element={<Constructor />} />
              <Route path="/lesson/edit/:id" element={<Constructor />} />
              <Route path="/lesson/edit/:course_id/:lesson_id" element={<Constructor />} />
              <Route path="/lesson/edit/preview/:id_course/:id_lesson" element={<LessonEditPreview />} />
              <Route path="/lesson/edit/demo/:id_course/:id_lesson" element={<LessonDemo />} />
              <Route path="/mylearn/*" element={<MyLearn headerTitle={'Моё обучение'} />} >
                <Route index element={<MyCourses pageTitle={'Мои курсы'} />} />
                <Route path="mycourses" element={<MyCourses pageTitle={'Мои курсы'} />} />
              </Route>
              <Route path="/mylearn/course/:id_course/module/:id_module/lesson/:id_lesson/survey/:id_test/pass" element={<TestPassing />} />
              <Route path="/mylearn/*" element={<CourseShow />} >
                <Route path="course/:id_course/*" element={<CourseShow />} >
                  <Route path="module/:id_module/*" element={<LearnPrev />} >
                    <Route path="lesson/:id_lesson/*" element={<LearnPrev />} >
                      <Route path="surveyLobby/:id_test" element={<LearnPrev />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route >
        <Route path="*" element={<h2 style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>404 not found </h2>} />
      </Routes >
    </>
  );
})

export default App;
