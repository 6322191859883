import styled from 'styled-components'

export const ActionBtn = styled.button`
    max-width: 120px;
    width: 100%;
    padding: 10px 24px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    border-radius: 14px;
`