import styled from "styled-components";
import { ColorBtnSample } from "./ColorBtnSampleStyled";



export const YellowButton = styled(ColorBtnSample)`
    background-color: #FDE5BF;
    color: #FB9703;
    &:hover { 
        color: #FB9703;
        background-color: #FCCF8C;
    }
    &:active {
        background-color: #F99500;  
        color: #a0a0a0
    }

    &:disabled {
        background-color: rgb(232 232 232);
        /* cursor: auto; */
        color: #a0a0a0
    }

    &:disabled:hover {
        background-color: rgb(232 232 232);
        /* cursor: auto; */
        color: #a0a0a0
    }
`