import axios from "axios";
import { host } from "app/globals";

export const api = axios.create({
  baseURL: `${host}/api`,
})
api.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('token')) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }
    return config
  }
)
api.interceptors.response.use(
  (config) => {
    return config
  },

  async (error) => {
    if (error.response.status === 423) {
      localStorage.clear()
      return window.location.replace('/login?isbanned')
    }
    const originalRequest = { ...error.config, _isRetry: true };
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry &&
      !error.config.cancel
    ) {
      try {
        const response = await api.get('user/refreshjwt');
        localStorage.setItem("token", response.data.token);
        return api.request(originalRequest);
      } catch (error) {
      }
    }
    throw error;
  }
);