import React, { useState, useRef } from 'react';
import { MyInput } from 'shared/UI/Inputs'
import { MyModal } from 'shared/UI/Modals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import userStore from 'shared/store/userStore';
import { observer } from 'mobx-react-lite';
import { sendFile } from 'shared/API/FileService/SendFile';

const ChangePhoto = observer(({ className }) => {
    const [visiblePopup, setVisiblePopup] = useState(false)
    const [profilePhoto, setProfilePhoto] = useState();
    const [photoFile, setPhotoFile] = useState();
    const clearValue = useRef("")
    const uploadPhoto = (event) => {
        var file = event.target.files[0];
        var prevUrl = URL.createObjectURL(file)
        setProfilePhoto(prevUrl)
        setPhotoFile(file)
        setVisiblePopup(true)
    };

    const sendPhoto = async () => {
        await sendFile(photoFile, 'uploadfile')
            .then(() => {
                userStore.changeUser({ user_photo: profilePhoto })
            })
    }
    return (
        <>
            <label htmlFor='addPhoto' className={className}>
                <FontAwesomeIcon icon={solid("camera")} className='icon' />
                <MyInput
                    refInput={clearValue}
                    id="addPhoto"
                    type="file"
                    accept='*'
                    onChange={uploadPhoto}
                />
            </label>
            <MyModal
                setVisible={setVisiblePopup}
                visible={visiblePopup}
                addNewItem={sendPhoto}
                refInput={clearValue}
            >
                <img src={profilePhoto} alt='Новое фото пользователя' />
            </MyModal>
        </>
    );
})

export default ChangePhoto;