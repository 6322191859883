import React from 'react';
import { MainWrap } from 'shared/UI/Layouts';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';
import EducationLayout from '../../../shared/UI/Layouts/EducationLayout/EducationLayout';
import { BackButton } from 'shared/UI/Buttons';
import { CustomLink } from 'shared/UI/Links';
import { DefaultWrapper, NavSidebarElem, NavigateSection } from 'shared/UI/Wrappers';
 
import { ContentHorizontal, ListVertical } from 'shared/UI/Layouts';
import pageState from 'shared/store/pageState';

const MyLearn = observer(({ headerTitle }) => {

    const defaultWay = '/mylearn/requests'
    return (
        <>
            <MainWrap headerTitle={headerTitle}>
                <EducationLayout>
                    <ListVertical>
                        {/* {pageState.title ?
                            <p className={cl.pageTitle}>
                                {pageState.title}
                            </p>
                            :
                            <p className={cl.pageTitle}>
                                пусто
                            </p>
                        } */}
                        <ContentHorizontal style = {{ width:'100%'}}>
                            <NavigateSection>
                                <NavSidebarElem back={true}>
                                    <BackButton/>
                                </NavSidebarElem>
                                <NavSidebarElem to="/mylearn/mycourses">
                                    <CustomLink  >Курсы</CustomLink>
                                </NavSidebarElem>                               
                            </NavigateSection>
                            <Outlet></Outlet>
                        </ContentHorizontal>
                    </ListVertical>
                </EducationLayout>
            </MainWrap >
        </>
    );
});

export default MyLearn;

