import { api } from 'app/api';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { MyInputMask } from 'shared/UI/Inputs';
import { InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
import { ListVertical } from 'shared/UI/Layouts';
import { MyInputMaskStyled } from './styled/MyInputMask.styled';
import { AcceptButton } from 'shared/UI/Buttons';
import { RejectButton } from 'pages/TeacherArea/TeacherCourseEdit/ModulesAndLessons(Unused)/components/styled/ModulesLessons.styled';

const BuyRequest = ({ c_id, endAction }) => {
    const [successRequest, setSuccessRequest] = useState(null)
    const { register,
        handleSubmit,
        reset,
        watch,
        setValue,
        control,
        formState: { errors }
    } = useForm({ mode: 'onSubmit' });

    const SendBuyrequest = (data) => {
        console.log(data, c_id)
        api.post('course/CompanyBuyCourse', { ...data, CourseId: c_id })
            .then((result) => {
                console.log(result);
                if (result.data.status == 200) {
                    setSuccessRequest(true)
                }
            })
            .catch((error) => {
                setSuccessRequest(false)
            })
    }  
    return (
        <>
            {successRequest === null
                ?
                <form onSubmit={handleSubmit(SendBuyrequest)}>
                    <ListVertical>
                        <InputValidateUseForm
                            title={`${errors?.CompanyName ? '* ' : ''} Название компании`}
                            type={'text'}
                            error={errors?.CompanyName}
                            validateSet={register("CompanyName", {
                                required: 'Пожалуйста, укажите компанию ',
                                minLength: {
                                    value: 1,
                                    message: 'Минимум 1 буквы в название компании'
                                },
                            })}
                        />
                        <InputValidateUseForm
                            title={`${errors?.Email ? '* ' : ''} Ваш email`}
                            type={'mail'}
                            placeholder={''}
                            error={errors?.Email}
                            autocomplete="off"
                            validateSet={register("Email", {
                                required: "Пожалуйста, введите email.",
                                pattern: {
                                    value: /^(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#%&*\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{2,63}\.)+[a-zA-Z]{2,15}$/,
                                    message: 'Неверный формат email',
                                }
                            })}
                        />
                        <InputValidateUseForm

                            title={`${errors?.INN ? '* ' : ''} ИНН (вашей компании)`}
                            error={errors?.INN}
                            type={'text'}
                        >
                            <MyInputMaskStyled
                                $error={errors?.INN}
                                validateSet={register("INN", {
                                    required: 'Пожалуйста, напишите ИНН организации ',
                                    pattern: {
                                        value: /^[\d+]{10}$/,
                                        message: 'Неверный формат ИНН',
                                    },
                                    minLength: {
                                        value: 1,
                                        message: 'Минимум 1 буквы в название'
                                    },
                                })}
                                type={'text'}
                                defaultValue={''}
                                mask='9999999999'

                            />
                        </InputValidateUseForm>
                        <InputValidateUseForm
                            title={'Ваше имя'}
                            type={'text'}
                            error={errors?.GuestName}
                            validateSet={register("GuestName", {
                                required: 'Пожалуйста, напишите ваше имя для обратной связи ',
                                pattern: {
                                    value: /^[А-Я][а-яё]+$/,
                                    message: 'Имя должно быть латинскими буквами без цифр',
                                },
                                minLength: {
                                    value: 1,
                                    message: 'Минимум 1 буква в название'
                                },
                                onChange: (e) => {
                                    setValue('GuestName', e.target.value = e.target.value[0].toUpperCase() + e.target.value.slice(1))
                                }
                            })}
                        />
                        <AcceptButton
                            title={'Отправить заявку'}
                            type={'submit'}

                        />
                    </ListVertical>
                </form>
                : null
            }

            {
                successRequest === true ?
                    <ListVertical>
                        <p>Заявка успешно отправлена</p>
                        <AcceptButton
                            title={'Отлично'}
                            onClick={() => endAction()} />
                    </ListVertical>
                    : null
            }

            {
                successRequest === false ?
                    <ListVertical>
                        <h2>Неизвестная ошибка</h2>
                        <p>Попробуйте позже</p>
                        <RejectButton
                            title={'Хорошо'}
                            onClick={() => endAction()}
                        />
                    </ListVertical>
                    : null
            }
        </>




    );
};

export default BuyRequest;