import React, { useEffect, useState } from 'react';
import InputArea from 'shared/UI/Inputs/InputArea';
import { IoChevronBackOutline } from 'react-icons/io5';
import Select from 'react-select'
import cl from './Select.module.css'

const MainSelect = ({ title, map, value, label, onChange, error, options, placeholder, defaultValue = null, props }) => {

    /*
    * map - пропс массива с обектами
    * value - пропс пути полей в объекте из map для value option "['field1', 'field2']"
    * label - пропс пути полей в объекте из map для label option "['field1', 'field1']"
    */

    const arrayKeys = (obj, keys) => {
        const value = keys.reduce((p, c) => p?.hasOwnProperty(c) ? p[c] : null, obj);
        return value
    }

    const [local, setLocal] = useState([])
    useEffect(() => {
        var tempSubject = []
        map?.map((elem, i) => {
            const v = arrayKeys(elem, value)
            const l = arrayKeys(elem, label)
            tempSubject.push({ value: v, label: l })
        })
        setLocal(tempSubject)
    }, [])

    const customStyles = {
        control: (defaultStyled, state) => ({
            ...defaultStyled,
            borderRadius: '12px',
            height: '40px',
            boxShadow: state.isFocused && '0 0 10px rgba(83, 143, 255, 0.466)'
        }),
        dropdownIndicator: (defaultStyle, state) => ({
            ...defaultStyle,
            transform: !state.isFocused && 'rotate(90deg)'
        })
    }
    return (
        <InputArea
            title={title}
        >
            <Select
                styles={customStyles}
                options={options || local}
                placeholder={placeholder}
                onChange={onChange}
                defaultValue={defaultValue}
                {...props}
            />
            {error && <div className={cl.ValidateError}> {error?.message || "Пожалуйста заполните поле"}</div>}
        </InputArea>
    );
};

export default MainSelect;