import { DefaultWrapper } from "shared/UI/Wrappers";
import styled from "styled-components";

export const CourseProggress = styled(DefaultWrapper)`
      display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
    padding: 20px;
    border-radius: 30px;
    margin-bottom: 0px;
`
export const MyCourseInfo = styled.div`
     display: flex;
    flex-direction: column;
    width: 100%;
    gap: 14px;
`
export const CourseTitle = styled.h2`
    font-size: 30px;
    font-weight: 700;
`

