import React, { useState } from 'react';
import { FullWrap } from 'shared/UI/Wrappers';
import SuccessPopupChange from 'entities/PersonalSecurity/SuccessPopupChangePasword/SuccessPopupChange';
import EmailParametrsList from 'entities/PersonalSecurity/EmailParametrsList/EmailParametrsList';

const ChangeMailWidget = () => {
    const [changeNotice, setChangeNotice] = useState(false)
    return (
        <>
            <FullWrap>
                <EmailParametrsList setChangeNotice={setChangeNotice} showPopupFunction={e => setChangeNotice(e)} />
            </FullWrap >
            <SuccessPopupChange
                isOpen={changeNotice}
                onClose={setChangeNotice}
                text={'Инструкция по смене почты выслана на ваш текущий почтовый ящик.'}
            />
        </>
    );
};

export default ChangeMailWidget;