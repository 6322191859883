import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cl from './LessonShowData.module.css'
import _ from 'lodash';
import { ModalSmall } from 'shared/UI/Modals';
import { BlueButton, ButtonNo, ButtonYes, GreenButton, RedButton, SimpleWhiteButton, YellowButton } from 'shared/UI/Buttons';
import LessonsTests from 'entities/TeacherArea/TeacherCourseEdit/LessonTests/LessonTests';

const LessonShowData = ({ lesson, LessonData, setEdit, cours_id, index, createTest, deleteLesson, RemoveTest }) => {

    const [DeleteConfimVisibility, setDeleteConfimVisibility] = useState(false)
    return (

        <>
            <div className={cl.PrevtopSectionLesson}>
                <div className={cl.prevContent}>
                    <h4>{`Урок ${index}.${lesson?.order}: ${lesson?.name}`}</h4>
                    {lesson?.id_difficulty}
                    <div className={cl.durationlesson}>
                        <p><span className={cl.label}>Время прохождения:</span>
                            {
                                LessonData?.duration ?
                                    LessonData?.duration < 60 ?
                                        <>
                                            {LessonData?.duration + ' мин.'}
                                        </>
                                        :
                                        <>
                                            {(LessonData?.duration / 60).toFixed() + ' ч. ' + ((LessonData?.duration) % 60).toFixed(0) + ' мин.'}

                                        </>
                                    : '0 ч. 0 мин.'
                            }
                        </p>
                        <p>
                            <span className={cl.label}>Пробный урок: </span>
                            {LessonData?.isDemo === true
                                ? 'да'
                                : 'нет'
                            }
                        </p>
                        <p>
                            <span className={cl.label}>Cтатус:</span>
                            {LessonData?.isPublic === true
                                ? 'опубликован'
                                : 'не опубликован'
                            }
                        </p>
                    </div>
                    <div>{LessonData?.description}</div>

                </div>
                <div className={cl.prevBtns}>
                    <GreenButton
                        style={{ padding: ' 6px 23px', }}
                        data-id={lesson?.id}          
                        onClick={() => setEdit(true)}>
                        изменить
                    </GreenButton>
                    <RedButton
                        style={{ padding: ' 6px 23px' }}
                        data-id={lesson?.id}       
                        onClick={(e) => setDeleteConfimVisibility(true)}>
                        удалить
                    </RedButton>                  
                </div>
            </div>
            <LessonsTests
                // ipdateTest = { }
                lesson={lesson}
                createTest={createTest}
                RemoveTest={RemoveTest}
            />

            {/* Попап подтверждения удаления теста */}
            <ModalSmall
                visible={DeleteConfimVisibility}
                setVisible={() => setDeleteConfimVisibility(false)}
                className={cl.LessonDeleteAlert}>
                <p>Вы точно хотите удалить урок  {lesson?.label}</p>
                <div className={cl.LessonDeleteAlertBtns}>
                    <ButtonYes onClick={() => deleteLesson(lesson?.id)}></ButtonYes>
                    <ButtonNo onClick={() => setDeleteConfimVisibility(false)}></ButtonNo>
                </div>
            </ModalSmall>
        </>
    );
};

export default LessonShowData;