import AddLessonForm from 'features/TeacherArea.jsx/AddLessonForm/AddLessonForm';
import React from 'react';
import { useParams } from 'react-router-dom';
import { MainPopup } from 'shared/UI/Popups';


const AddLessonPopup = ({isOpened, onClose, AddLesson}) => {
    const { course_id } = useParams()
    return (
        <MainPopup
            isOpened={isOpened}
            onClose={e => onClose(false)}
            title={'Создание Урока'}>
            <AddLessonForm
                cours_id={course_id}
                newlessonOrder={1}
                setLessons={AddLesson}
            />
        </MainPopup>
    );
};

export default AddLessonPopup;