import React, { useEffect, useState, useRef } from 'react';
import { host } from 'app/globals';
import _ from 'lodash'
import SimpleImg from 'shared/UI/Images/SimpleImg/SimpleImg';
import OptionAnswer from 'entities/TestEditor/OptionAnswer';
import { api } from 'app/api';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
import { SpacedApartWrap } from 'shared/UI/Wrappers';
import { ErrorField } from 'shared/UI/Errors';
import { ChevronRotate } from 'shared/UI/Other';
import { FaPlus } from 'react-icons/fa6';
import { ContentHorisontalCenter, ContentHorizontal, ListVertical } from 'shared/UI/Layouts';
import { BlueQuestionButton, DarkGreenQuestionButton, QuestionTextStyled, RedAnswerBtn, RedQuestionBtn } from 'shared/UI/Buttons/OtherButtons/QuestionTextStyled';
import { ImgRemove, ImgSubUploadQuestion, ImgUploadQuestion } from 'entities/TestEditor/OptionAnswer/styled/ImgUpload.styled';
import { MyInput } from 'shared/UI/Inputs';
import { BsUpload } from 'react-icons/bs';
import { HiOutlineTrash } from "react-icons/hi";
import { ContentHorizontalCenter } from 'pages/StudentArea/StudentCoursesList/courseShow/SurveyLobby/SurveyLobbyStyled';
import { BorderedButton } from 'shared/UI/LearnPrev/LearmPrev.styled';

const QuestionSelectable = ({ question, isEdit, toggleEdit, DeleteAction, type, AddNewAnswer, updateQuestion, QuestionIndex, CancelEUpdate }) => {
    const [LocalQuestion, setLoaclQuestion] = useState({})
    const [QuestionImg, setQuestionImg] = useState(null)
    const [EditErrors, setEditErrors] = useState(false)
    const [NoRightError, setNoRightError] = useState(false)
    const [ShowFull, setShowFull] = useState(false)
    const { id, tid } = useParams()
    const clearVal = useRef("")

    const { register,
        handleSubmit,
        setValue,
        clearErrors,
        reset,
        watch,
        formState: { errors }
    } = useForm({ mode: 'onSubmit' });

    useEffect(() => {
        if (question.imgQuest == null) {
            setQuestionImg(null)
        } else {
            fetch(`${host}/api/GetFile/${question.imgQuest
                }`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
                .then(response => response.status === 200 && response.blob(response))
                .then(blob => {
                    if (blob) {
                        const fileURL = URL.createObjectURL(blob)
                        setQuestionImg(fileURL)
                    }
                    else {
                        setQuestionImg(null)
                    }
                })
        }
        //setLoaclQuestion({ ...JSON.parse(JSON.stringify(question)) })

        const qData = JSON.parse(JSON.stringify(question))
        const ValidateData = { textQuest: qData?.textQuest, questionAnswer: qData?.answerOptions[0]?.textAnswer || '' }
        reset(ValidateData)
        setLoaclQuestion(qData)
    }, [question])

    //Финальное сохранение
    const saveData = () => {
        var old = JSON.parse(JSON.stringify(question))

        let { answerOptions, ...mainData } = { ...LocalQuestion }
        //Если обновлены данные вопроса
        mainData.textQuest = watch('textQuest')

        if (mainData.imgQuest !== question.imgQuest || mainData.textQuest !== question.textQuest) {
            api.put(`Question/${LocalQuestion.id}`, { ...mainData, SurveyId: tid, })
            .then((result) => {
                if (!result.status == 200) {
                    var { answerOptions, ...other } = question
                    mainData = other
                }
            })
        }
        
        
        //TODO надо бы слить несколько if в один
      
        if (mainData.type == 2) { // если чекбоксы
            if (!_.isEqual(answerOptions, question.answerOptions)) {
                answerOptions.map(
                    (answer, index) => {
                        let InQuestion = old.answerOptions[index]
                        let InLocal = answerOptions[index]
                        //Если такого ответа не существует в изначальном question
                        if (InQuestion === null || InQuestion === undefined) { //Если в старом не существует, то создать
                            // создать
                            api.post(`AnswerOption`, { ...answer, QuestionId: LocalQuestion.id, Survey: tid })
                                .then((result) => {
                                    answerOptions[index] = { ...answerOptions[index], id: result.data.id }
                                })
                        } else if (!_.isEqual(InQuestion, InLocal)) //если существует проверитиь совпадение
                        {    //обновить ответ                  
                            if (InQuestion !== InLocal) {
                                api.put(`AnswerOption/${answer.id}`, { ...answer, QuestionId: LocalQuestion?.id ? LocalQuestion?.question?.id : LocalQuestion.id, Survey: tid })
                                    .then((result) => {
                                        answerOptions[index] = { ...answerOptions[index], id: result.data.id }
                                    })
                            }
                        }
                    }
                )
            }
            AddNewAnswer(LocalQuestion.id, LocalQuestion.answerOptions, mainData, question?.question?.type ? question?.question?.type : question?.type)
            toggleEdit(false)
        } else if (mainData.type == 3) {  //если радиокнопки
            LocalQuestion.answerOptions.map((answer, index) => {
                let InQuestion = old.answerOptions[index]
                let InLocal = answerOptions[index]
                console.log('запрос на сохранение данных', InQuestion , InLocal )
                if (InQuestion === null || InQuestion === undefined) { //Если в старом списке ответов нее было ответа с этим индексом, то создаём его 
                    // создать
                    api.post(`AnswerOption`, { ...answer, QuestionId: LocalQuestion?.id, Survey: tid })
                        .then((result) => {
                            answerOptions[index] = { ...answerOptions[index], id: result.data.id }
                        })
                } else if (InQuestion!== InLocal) { // Если сменилась правильность
                    if (InQuestion !== InLocal) {
                        api.put(`AnswerOption/${answer.id}`, { ...answer, QuestionId: LocalQuestion.id, Survey: tid })
                            .then((result) => {
                                answerOptions[index] = { ...answerOptions[index], id: result.data.id }
                            })
                    }
                }
            })
            AddNewAnswer(LocalQuestion.id, LocalQuestion.answerOptions, mainData, question?.question?.type ? question?.question?.type : question?.type)
            toggleEdit(false)
        }
        // Если обновлены данные ответов, то нужно проверять каждый ответ, если его не было в старом списке  создать, если был то проверить сходство и обновить по надобности 
    }

    const CancelUpdate = () => {
        setEditErrors(false)
    }

    const uploadImage = async (event) => {
        var file = event.target.files;
        const formData = new FormData();
        formData.append('file', file[0]);
        const NewImg = await api.post('/uploadfile', formData)
        setLoaclQuestion({ ...LocalQuestion, imgQuest: await NewImg.data.id })
        setQuestionImg(URL.createObjectURL(file[0]))
    }

    const removeImage = (PhotoId) => {
        api.delete(`deletefile/${PhotoId}`)
            .then(() => {
                setLoaclQuestion({ ...LocalQuestion, imgQuest: null })
                setQuestionImg(null)
            },
                (error) => { }
            )
    }

    const EditRightAnswer = (status, AnswerIndex) => {
        var QuestionData = { ...LocalQuestion }
        let AnswersList = [...LocalQuestion.answerOptions]
        if (type == 'checkbox') {
            AnswersList[AnswerIndex].Right = status
            setLoaclQuestion({ ...LocalQuestion, answerOptions: AnswersList })
        }
        if (type == 'radio') {
            QuestionData.rightAnswer = AnswerIndex
            AnswersList.answers.map((answer, index) => {
                if (index == AnswerIndex) { answer.right = true }
                else { answer.right = false }
            })
            setLoaclQuestion({ ...LocalQuestion, answerOptions: AnswersList })
            //удаление ответа для радиокнопока
        }
    }

    //удаление варианта  ответа по id (ElId) или по индексу (LocalQuestion)
    const DeleteAnswer = (ElId, elIndex) => {

        var QuestionData = LocalQuestion
        var tempAnswersList = QuestionData.answerOptions
        tempAnswersList.splice(elIndex, 1)
        ElId == null
            ?
            setLoaclQuestion({ ...QuestionData, answerOptions: tempAnswersList })
            :
            api.delete(`AnswerOption/${ElId}`)
                .then(result => {
                    if (result.status == 204 || result.status == 200) {
                        setLoaclQuestion({ ...QuestionData, answerOptions: QuestionData.answerOptions.filter(elem => elem.id != ElId) })
                    }
                })
    }

    const AddAnswer = (currenSData) => {
        let plusAnswer = JSON.parse(JSON.stringify(LocalQuestion))
        plusAnswer.answerOptions.push({
            QuestionId: id,
            ImgId: null,
            TextAnswer: null,
            right: false,
            Survey: null
        })
        setLoaclQuestion(plusAnswer)
    }

    const UpdateLocalAnswer = (data, index) => {
        var Answers = JSON.parse(JSON.stringify(LocalQuestion.answerOptions))
        //Если приходящие данные не имют right
        data.right && LocalQuestion.type == 3 && Answers.map(a => a.right = false) // Если тип 3  - радиокнопки, то сначала у всех ставим right = false
        Answers[index] = { ...JSON.parse(JSON.stringify(data)) } //у ответа с нужным индексом сохраняем значение right 

        setLoaclQuestion({ ...LocalQuestion, answerOptions: [...Answers] })
    }

    const CheckHasRight = () => {

        const checkResult = LocalQuestion.answerOptions.findIndex(e => e.right == true) > -1 ? false : true
        setNoRightError(checkResult)
        return (!checkResult)
    }

    return (
        <>
            {isEdit
                ?
                <>
                    {/*Вывод редактируемой карточки */}


                    <InputValidateUseForm
                        title={`Вопрос ${QuestionIndex + 1}`}
                        type={'text'}
                        error={errors?.textQuest}
                        validateSet={register("textQuest", {
                            required: "Пожалуйста, введите текст вопроса",
                            minLength: {
                                value: 2,
                                message: 'Минимум 2 символа'
                            }
                        })}
                    />

                    <QuestionTextStyled style={{ justifyContent: 'center', flexDirection: 'column' }}>
                        <label htmlFor='addMainImg'
                        >
                            <ImgUploadQuestion >
                                {QuestionImg !== null
                                    ?
                                    <SimpleImg
                                        src={QuestionImg}
                                        maxW={'400px'}
                                    />
                                    :
                                    <>
                                        <BsUpload />
                                        <p>
                                            Добавить картинку
                                        </p>
                                    </>
                                }
                                <MyInput
                                    refInput={clearVal}
                                    id="addMainImg"
                                    type="file"
                                    onChange={uploadImage}
                                    accept='image/jpeg,image/png'
                                />
                            </ImgUploadQuestion>
                        </label>
                        <ContentHorizontalCenter>
                            {QuestionImg !== null
                                ?
                                <>
                                    <ImgSubUploadQuestion >
                                        <label htmlFor='addSubMainImg' >
                                            <BsUpload />
                                            <p>
                                                Заменить
                                            </p>
                                        </label>
                                        <MyInput
                                            refInput={clearVal}
                                            id="addSubMainImg"
                                            type="file"
                                            onChange={uploadImage}
                                            accept='image/jpeg,image/png'
                                        />
                                    </ImgSubUploadQuestion>
                                    <ImgRemove onClick={() => removeImage(LocalQuestion.imgQuest)}>
                                        <HiOutlineTrash />
                                        <p>
                                            Удалить
                                        </p>
                                    </ImgRemove>
                                </>
                                : null
                            }
                        </ContentHorizontalCenter>

                    </QuestionTextStyled>

                    <SpacedApartWrap>

                        <BorderedButton title={<p><FaPlus />  Добавить ответ</p>}
                            //  style={{ width: 'fit-content', gap: '20px' }} 
                            type={'button'} onClick={() => AddAnswer(LocalQuestion)}>

                        </BorderedButton>

                    </SpacedApartWrap>
                    {LocalQuestion?.answerOptions?.map((element, index) =>
                        <OptionAnswer
                            key={index}
                            isEdit={isEdit}
                            index={index}
                            imgAnswers={LocalQuestion?.imgAnswers}
                            element={element}
                            type={type}
                            questionIndex={LocalQuestion.id}
                            editAction={(data, index) => UpdateLocalAnswer(data, index)}
                            EditRightAnswer={EditRightAnswer}
                            DeleteAnswer={DeleteAnswer}
                            setEditErrors={(data) => setEditErrors(data)}
                        />
                    )
                    }
                    {NoRightError ? <ErrorField error={'Ответы отсутствуют, либо не выбран правильный'} /> : null}
                    <QuestionTextStyled style={{ padding: '0' }} />
                    <SpacedApartWrap>
                        <RedQuestionBtn type={'submit'} onClick={() => { CancelEUpdate(); setNoRightError(false) }}> Отменить</RedQuestionBtn>
                        <DarkGreenQuestionButton type={'submit'} onClick={() => { CheckHasRight() && !EditErrors && saveData() }}> Сохранить</DarkGreenQuestionButton>
                    </SpacedApartWrap>
                </>
                :
                <>
                    <ListVertical>
                        <QuestionTextStyled style={{ cursor: 'pointer' }} $ShowUnderLine={!ShowFull}
                            onClick={() => setShowFull(!ShowFull)}>
                            №{QuestionIndex + 1}  {' ' + LocalQuestion?.textQuest}
                            <ChevronRotate triggerParam={ShowFull} />
                        </QuestionTextStyled>

                        {ShowFull
                            ?
                            <>
                                {
                                    QuestionImg == null
                                        ? null
                                        :
                                        <QuestionTextStyled style={{ justifyContent: 'center' }}>
                                            <SimpleImg
                                                src={QuestionImg}
                                                maxW={'400px'}
                                            />
                                        </QuestionTextStyled>
                                }
                                {LocalQuestion?.answerOptions?.length == 0 ? <p> ответов пока нет  </p> : null}


                                <ListVertical style={!isEdit ? { gap: '0' } : null} >
                                    {LocalQuestion?.answerOptions?.map((element, index) =>
                                        <OptionAnswer
                                            isEdit={isEdit}
                                            key={index}
                                            index={index}
                                            imgAnswers={LocalQuestion?.imgAnswers}
                                            element={element}
                                            type={type}
                                            setEditErrors={(data) => setEditErrors(data)}
                                            editAction={(data, index) => UpdateLocalAnswer(data, index)}
                                        />
                                    )
                                    }
                                </ListVertical>

                                <QuestionTextStyled style={{ padding: '0' }} />

                                <SpacedApartWrap>
                                    <RedQuestionBtn type={'button'} onClick={() => DeleteAction(true)}> Удалить</RedQuestionBtn>
                                    <BlueQuestionButton type={'button'} onClick={() => toggleEdit(true)}> Изменить</BlueQuestionButton>
                                </SpacedApartWrap>

                            </>
                            : null}

                    </ListVertical>

                </>


            }
        </>
    );
};

export default QuestionSelectable;