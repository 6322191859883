 const convertTimeSpan = (minutes) => {
    const dateTime = new Date();
    const hours = Math.floor(minutes / 60);

    dateTime.setMinutes(minutes % 60);
    dateTime.setHours(hours);

    const formattedDateTime = `${String(dateTime.getHours()).padStart(2, '0')}:${String(dateTime.getMinutes()).padStart(2, '0')}:00`;

    return (formattedDateTime)
}
export default convertTimeSpan