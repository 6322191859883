import styled from "styled-components";
import { MyInput } from "shared/UI/Inputs";
import { AcceptButton } from "shared/UI/Buttons";

export const ValidateInputStyled = styled.div`   
   /* background-color: red; */
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    & > label{
        color: #163C85;
        font-size: 14px;
        font-weight: 500;
    }
`
export const ValidationInputWrap = styled.div` 
    display: flex;    
    width: 100%;
    align-items: center;
    gap: 10px;  
    max-width: unset;
`

export const MyInputStyled = styled(MyInput)` 
 background-color:   ${props => props.$error === true ? '#fff' : '#E8F1FF'};
 border:   ${props => props.$error === true ? '1px solid #FF5B5B;' : '0px;'}; 
 color:   ${props => props.$error === true ? '#FF5B5B;' : '#163C85'}; 

 &:focus,  &:active,  &:target, &:focus-within, &:focus-visible {  
    border-color: #246FFB;
    font-size: 16px;
    font-weight: 400;
    box-shadow: 0 0 10px rgba(83, 143, 255, 0.466);
    background-color: #fff !important;
    border:   ${props => props.$error === true ? '1px solid #FF5B5B;' : '0px;'}; 
 }

 &:disabled:focus,  &:disabled:active,  &:disabled:target, &:disabled:focus-within, &:disabled:focus-visible {  
    background-color: #F3F3F3 !important;
     color: #838383;
 }
 &:disabled {
     background-color: #F3F3F3 !important;
     color: #838383;
 }
`

export const InputError = styled.div`
font-weight: 500;
font-size: 16px;
color: #ff5858;
`

export const SplitLine = styled.p`
height: 1px;
width: 100%;
background-color: #E0E0E0;
`

export const BlueAcceptButton = styled(AcceptButton)`
width: 100%;
`





// export const DisabledField = styled.p`
//  background-color: #E8F1FF;
//  border-width: 0px;
// `

