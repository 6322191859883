import { ContentHeader } from 'pages/PersonalArea/PersonalInormation/styled/ProfileStyles';
import { SimpleWhiteBtn } from 'shared/UI/LearnPrev/LearmPrev.styled';
import React from 'react';
import { IoAdd } from 'react-icons/io5';
import { TextAndBackHead } from 'shared/UI/Other';

const CreateCourseHeader = ({onClick}) => {
    return (
        <>
            {/* <TextAndBackHead /> */}
            <ContentHeader>
                <h2>Курсы</h2>
                <SimpleWhiteBtn onClick={() => onClick()}>
                    <IoAdd />
                    <p> Создать курс </p>
                </SimpleWhiteBtn>
            </ContentHeader>
        </>
    );
};

export default CreateCourseHeader;