import { makeAutoObservable } from "mobx"

class EditorStore {
    Command = null
    SaveMarker = true
    showNewModuleForm = false 
    deleteLessonCommand = undefined
    updateModulesAndLesson = false


    constructor() {
        makeAutoObservable(this)
    }

    setCommand(result) {
        this.Command = result
    }
    setSaveMarker(result) {
        this.SaveMarker = result
    }
    setShowNewModuleForm(result){
        this.showNewModuleForm = result
    }
    setDeleteLessonCommand(element) {
        this.deleteLessonCommand = element
    }
    setUpdateModulesAndLesson(status) {
        this.updateModulesAndLesson = status
    }




}

export default new EditorStore()