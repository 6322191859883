import React, { Children, cloneElement, useEffect, useState } from 'react';
import './style.css';
import SortList from './Components/SortList';
import cl from './ListAndFilters.module.css';
import SearchFilter from './Components/SearchFilter';
import mobxState from 'pages/TutorsMarket(Unused)/store/mobxState';
import { observer } from 'mobx-react-lite';
import ElementsOnPage from './Components/ElementsOnPage/ElementsOnPage';
import ListFilters from './Components/ListFilters';
import cn from 'classnames';
import PaginationList from 'shared/UI/PaginationList/PaginationList';
import useGetElemsByFilters from './API/useGetElemsByFilters';
import { ContentHorizontal, ListVertical } from 'shared/UI/Layouts';

import { api } from 'app/api';

const ListAndFilters = observer(
    (
        {
            gFiltersArray = [
                { parametr: 'duration', title: 'Длительность', type: 'Interval' },
                { parametr: 'price', title: 'Цена', type: 'Interval' },
                { parametr: 'learning_subjects', title: 'Предметы', type: 'Checkbox' },
                { parametr: 'course_support_types', title: 'Поддержка', type: 'Checkbox' },
                { parametr: 'learning_stages', title: 'Этап', type: 'Checkbox' },
            ],
            gPageElemsArray =
            [
                { id: 4 },
                { id: 6 },
                { id: 10 },
                { id: 25 },
                { id: 50 },
                { id: 100 },
                { id: 500 }
            ],
            gOrdersList = [
                { id: 1, name: "рейтинг по возрастанию", parametr: 'rating', direction: 'asc' },
                { id: 2, name: "рейтинг по убыванию", parametr: 'rating', direction: 'desc' }
            ],
            urlFilterByParams = "/courses/getfiltredpage",
            urlGetDirectories = "/courses/getdirectories",
            getmMinMaxFilters = "/courses/getminmaxfilters",
            viewMode = {
                list: true,
                paggination: true,
                filters: true,
                search: true,
                sort: true,
                elementsOnPage: true,
                grid: true,
                topMenu: true
            },
            children
        }
    ) => {
        const getElemsByFilters = useGetElemsByFilters(urlFilterByParams)
        const changeGrid = () => {
            if (mobxState.ElementsGrid === 'tiles') {
                mobxState.SetData({ ElementsGrid: 'list' })
            }
            else if (mobxState.ElementsGrid === 'list') {
                mobxState.SetData({ ElementsGrid: 'tiles' })
            }
        };

        const getCBFilters = async () => {
            var resultation = await api.get(urlGetDirectories)
            return resultation.data
        }
        const getdirectories = async () => {
            var result = await api.get(getmMinMaxFilters)
            return result.data
        }
        const getData = async () => {
            let linearFilters = await getdirectories()
            let cbFilters = await getCBFilters()
            let resDl = []
            for (const prop in cbFilters) {
                var bfparam = cbFilters[prop]
                let dl = bfparam.map(el => el.id)
                resDl[prop] = dl
            }
            mobxState.SetData({
                CheckBoxListFilters: cbFilters,
                DefaultFilters: { ...linearFilters, ...resDl },
                FiltersToFetch: { ...mobxState.FiltersToFetch, ...resDl }
            })
                .then(() => {
                    getFirstList()
                    return true
                })
        }
        const getFirstList = async (start) => {
            var result = await getElemsByFilters(mobxState.FiltersToFetch)
            mobxState.SetData({ ElementsList: await result?.data?.results })
        }
        useEffect(() => {
            const preReset = async () => {
                return await ResetData()
            }
            preReset()
            getData()
            return async () => {
                await ResetData()
            };
        }, [])
        const ResetData = async () => {
            mobxState.ResetData()
        }
        const goPageEvent = async (page) => {
            var result = await getElemsByFilters({ page: page - 1 })
            mobxState.SetData(
                {
                    CurrentPage: page - 1,
                    ElementsList: result.data
                }
            )
        }
        const setElementsOnPage = async (e) => {
            var result = await getElemsByFilters({ page: 0, ...mobxState.FiltersToFetch, count: e.target.value })
            mobxState.SetData({ ElementsList: result.data })
            mobxState.setTotalCountOfElements(result.headers['x-total-count'])
            mobxState.setElementsOnPage(e.target.value)
        }
        return (
            <ContentHorizontal className={cl.content}>
                <ListVertical className={cl.filterSection}>
                    {
                        (viewMode?.filters || viewMode?.filters == null) &&
                        <ListFilters
                            FiltersArray={gFiltersArray}
                            urlFilterByParams={urlFilterByParams}
                        />
                    }
                </ListVertical>
                <section className={cn(cl.ContentSection, "centerSectionContent")}>
                    {(viewMode?.topMenu || viewMode?.topMenu == null) && <div className={cl.actionPanel}>
                        {
                            (viewMode?.search || viewMode?.search == null) &&
                            <SearchFilter
                                urlFilterByParams={urlFilterByParams}
                            />}

                        {
                            (viewMode?.elementsOnPage || viewMode?.elementsOnPage == null) &&
                            <ElementsOnPage
                                onChange={e => setElementsOnPage(e)}
                                selectlist={gPageElemsArray}
                            />}
                        {
                            (viewMode?.sort || viewMode?.sort == null) &&
                            <SortList
                                urlFilterByParams={urlFilterByParams}
                                parametrsList={gOrdersList}
                            />}

                    </div>}
                    <div className={cl.FiltersAndList} >
                        {
                            (viewMode?.list || viewMode?.list == null) &&
                            <ListVertical className={cn(cl.list, cl[mobxState.ElementsGrid])}>
                                {mobxState.ElementsList?.map(element => (

                                    Children.map(children, child => {
                                        return cloneElement(child, {
                                            element: element,
                                            cardStyle: mobxState.ElementsGrid
                                        })
                                    })
                                ))}
                            </ListVertical>
                        }
                        {
                            (viewMode?.paggination || viewMode?.paggination == null) &&
                            <PaginationList
                                totalPages={mobxState.CountOfPage}
                                page={mobxState.CurrentPage}
                                changePageEvent={page => goPageEvent(page)}
                            />
                        }
                    </div>
                </section>
            </ContentHorizontal >
        )
    })

export default ListAndFilters