
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { LessonManageTabsBtns, MainMoMargin } from './ui';

const CourseEdit = observer(({ headerTitle }) => {
    const { cid, ...params } = useParams()
    const [CurrentTab, setCurrentTab] = useState()

    useEffect(() => {
        var ost = params['*']
        switch (ost) {
            case 'info': return setCurrentTab('Общая информация');
            case 'lessons': return setCurrentTab('Уроки');
            case 'streams': return setCurrentTab('потоки');
            case null: return setCurrentTab('');
            default: return setCurrentTab('');
        }
    }, [params])

    return (
        // <MainMoMargin headerTitle={headerTitle + ': ' + CurrentTab}>
            <LessonManageTabsBtns>
                <Outlet/>
            </LessonManageTabsBtns>
        // </MainMoMargin>
    )
})
export default CourseEdit