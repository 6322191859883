import React from 'react';
import CardCoursWrapper from '../../entities/Market/CardCoursWrapper';
import { MainWrap } from 'shared/UI/Layouts';
import ListAndFilters from 'widgets/ListAndFilters';

const SearchCourses = ({ headerTitle }) => {

    return (
        <MainWrap style={{ padding: '40px 20px 0px' }} headerTitle={headerTitle}>
            <ListAndFilters
                urlFilterByParams='/Course/Filter'
                urlGetDirectories='/Course/SA'
                getmMinMaxFilters='/Course/Filter'
                gFiltersArray={[
                    { parametr: 'price', title: 'Цена', type: 'Interval' },
                    { parametr: 'subjectsId', title: 'Предмет', type: 'Checkbox' },
                    // { parametr: 'supportTyp', title: 'Группа', type: 'Checkbox' },
                ]}
                gPageElemsArray={
                    [
                        { id: 5 },
                        { id: 10 },
                        { id: 20 },
                    ]
                }
                viewMode={{
                    topMenu: false
                }}
            >
                <CardCoursWrapper />
            </ListAndFilters>
        </MainWrap>
    );
};
export default SearchCourses;