import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ModalSmall } from 'shared/UI/Modals';
import { ButtonNo, ButtonYes } from 'shared/UI/Buttons';
import { observer } from 'mobx-react-lite';
import userStore from 'shared/store/userStore';
import { api } from 'app/api';
import { EmptyAvatar } from 'shared/UI/Fillers';

const RemovePhoto = observer(({ className }) => {
    const [visiblePopup, setVisiblePopup] = useState(false)
    const removePhoto = () => {       
        api.delete(`deletefile/${userStore.data.user_photo_ID}`)
            .then(() => {
                userStore.changeUser({ user_photo: EmptyAvatar })
                setVisiblePopup(false)
            },
                (error) => {  }
            )
    }
    return (
        <div className='delete_photo' >
            <button type="button" className={className} onClick={() => setVisiblePopup(!visiblePopup)}>
                <FontAwesomeIcon icon={solid("trash-can")} className='icon' />
            </button>
            {visiblePopup
                ?
                <ModalSmall visible={visiblePopup} setVisible={() => setVisiblePopup(false)} style={{ flexDirection: 'row', gap: '10px' }}>
                    <ButtonYes onClick={() => removePhoto()} />
                    <ButtonNo onClick={() => setVisiblePopup(false)} />
                </ModalSmall>
                :
                null}
        </div>
    );
})

export default RemovePhoto;