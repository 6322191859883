import { DefaultWrapper } from "shared/UI/Wrappers";
import styled from "styled-components";

export const StudentCoursesModules = styled(DefaultWrapper)`      
    padding: 0px;  
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 10px;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 0;

`