import React from 'react';
import cl from './ReviewsCounter.module.css'
import { IoStarSharp } from 'react-icons/io5';
import ElementsIterator from 'features/Stars/StarsGrade';

const ReviewsCounter = ({ rate, stars, reviesCount }) => {
    
    const viewMode = false;

    if (viewMode) {
        return (
            <div className={cl.RateArea}>
                <div className={cl.Rate}>
                    <span>4.5</span>
                </div>
                <div className={cl.Stars}>
                    <ElementsIterator
                        CurrtentCount={Math.floor(4.5 + 1)}
                        maxCount={5}
                        BlackStar={<IoStarSharp className={cl.BlackStar} />}
                        LightStar={<IoStarSharp className={cl.YellowStar} />}
                        className={cl.starStyle}
                    />
                </div>

                <p className={cl.ReviewsCount}>{'256 '} отзывов</p>
            </div>
        );
    }

};

export default ReviewsCounter;