import React from 'react';
import { CourseDescription, EscapeLesson, LessonsSidebar } from 'shared/UI/LearnPrev/LearmPrev.styled';
import { CustomLink } from 'shared/UI/Links';

const PreviewSidebar = ({ id_course, id_lesson, id_module, lessonData, type, courseName }) => {
    return (
        <>
            {type == 'demo'
                ?
                <LessonsSidebar>
                    <CourseDescription>
                        <h2>Курс: {courseName}</h2>
                        <p> предпросмотр урока </p>
                    </CourseDescription>
                    <CustomLink
                        state={{ module: lessonData?.moduleId || null, lesson: id_lesson }}
                        to={`/courses/coursedetail/${id_course}`}
                    >
                        <EscapeLesson>
                            Выйти с демоурока
                        </EscapeLesson>
                    </CustomLink>
                </LessonsSidebar>
                :
                type == 'EditPrev' ?
                    <LessonsSidebar>
                        <CourseDescription>
                            <h2>Курс: {courseName}</h2>
                            <p>предпросмотр урока</p>
                        </CourseDescription>
                        <CustomLink
                            // to={`/teachercabinete/course/${id_course}/edit/module/${lessonData?.moduleId}/lesson/${id_lesson}`}>
                            to={`/teachercabinete/course/${id_course}/edit/module/${lessonData?.moduleId}/lesson/${id_lesson}`}>
                            <EscapeLesson>
                                Выйти с предпросмотра
                            </EscapeLesson>
                        </CustomLink>
                    </LessonsSidebar>
                    : null
            }
        </>

    );
};

export default PreviewSidebar;