import React from 'react';
import { useParams } from 'react-router-dom';
import { SimpleWhiteButton } from 'shared/UI/Buttons';
import { CustomLink } from 'shared/UI/Links';
import { MainPopup } from 'shared/UI/Popups';

const SurveyContinuePopup = ({ isOpened, onClose, CreateNewTry, id_test }) => {
    const {id_course, id_module, id_lesson} = useParams()
    return (
        <MainPopup
            isOpened={isOpened}
            onClose={e => onClose(false)}
            title={'Внимание?'}
            showHeader={false}>
            <p>У вас есть незавершённая попытка, хотите продолжить?</p>
            <div  style = { { display: 'flex', justifyContent: 'space-between' }}>
                <SimpleWhiteButton  style = { { backgroundColor: 'var(--base-green-color)'  }}>
                    <CustomLink to={`/mylearn/course/${id_course}/module/${id_module}/lesson/${id_lesson}/survey/${id_test}/pass`}>
                        Продожить
                    </CustomLink>
                </SimpleWhiteButton>
                <SimpleWhiteButton style = { { backgroundColor: 'var(-active-color)'   }}
                    onClick={e => CreateNewTry(false)}
                >
                    Начать заново
                </SimpleWhiteButton>
                <SimpleWhiteButton style = { { backgroundColor: 'var(--color-red-default)',  }}
                    onClick={e => onClose(false)}
                >
                    Отмена
                </SimpleWhiteButton>
            </div>
        </MainPopup>
    );
};

export default SurveyContinuePopup;