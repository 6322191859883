import { MainWrap } from "shared/UI/Layouts";
import styled from "styled-components";

export const MainMoMargin = styled(MainWrap)`
   margin-top: 0;
   padding-top: 0px;
   padding-left: 0px;
   padding-right: 0px;

`

export const LessonManageTabsBtns = styled.div`
 width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 40px 0 0;
    gap: 20px;
`