import React from 'react';
import { MyInputMask, MyTextarea } from 'shared/UI/Inputs';
import { InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
import { InputValidateRadio } from 'shared/UI/Inputs/HookForm/InputValidateRadio';
import InputArea from 'shared/UI/Inputs/InputArea';
import cl from './PersonalInputs.module.css'
import HideMail from 'shared/hooks/hideMail';
import { Controller } from 'react-hook-form';
import { MainSelect } from 'shared/UI/Selects';

const PersonalInput = ({ title, inputType = 'input', type, checked, validateSet, error, control, setValue, list, ...props }) => {
    return (
        <div className='input_personalData'>
            <p>{title}</p>

            {inputType == 'input' &&
                <InputValidateUseForm
                    error={error}
                    type={type}
                    validateSet={validateSet}
                    {...props}
                />
            }
            {
                inputType === 'email' &&
                <InputValidateUseForm
                    placeholder={HideMail()}
                    disabled={props.disabled}
                    value={''}
                // {...props}
                />
            }
            {inputType == 'mask' &&
                <InputValidateUseForm
                    error={error}
                    type={'text'}
                >
                    <MyInputMask
                        {...props}
                        validateSet={validateSet}
                        type={'text'}
                        defaultValue={''}

                    />
                </InputValidateUseForm>
            }
            {inputType == 'date' &&
                <InputValidateUseForm
                    {...props}
                    error={error}
                    validateSet={validateSet}
                    type={'date'}
                />
            }
            {inputType == 'number' &&
                <InputValidateUseForm
                    error={error}
                    validateSet={validateSet}
                    {...props}
                />
            }
            {inputType == 'gender' &&
                <InputValidateUseForm error={error}>
                    <InputValidateRadio
                        RadioList={[
                            { id: 1, value: "М" },
                            // { id: 1, value: "M" },
                            { id: 2, value: "Ж" }
                        ]}
                        checked={checked}
                        error={error}
                        InputClass={cl.sexInput}
                        RadioClass={cl.radio}
                        {...props}
                        validateSet={validateSet}
                    />
                </InputValidateUseForm>
            }
            {inputType == 'textarea' &&
                <InputArea>
                    <MyTextarea
                        minRows={5}
                        maxRows={7}
                        error={error}
                        validateSet={validateSet}
                        {...props}
                    />
                </InputArea>
            }
            {inputType == 'select' &&
                <Controller
                    name={props.name}
                    control={control}
                    rules={{ required: 'Пожалуйста, выберите параметр' }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <MainSelect
                            placeholder={checked || 'Выбор...'}
                            map={list}
                            value={['id']}
                            label={['value']}
                            onChange={e =>{setValue(props.name, e.label)}}
                            error={error?.message != null ? null : error}
                            defaultValue={list.filter(el => el.label == checked).label}
                        />
                    )}
                />
            }
            {inputType == null && null}

        </div >
    );
};

export default PersonalInput;