export const uppFistChar = (string) => {
    const firstupperChar = string[0]?.toUpperCase()
    if (string !== undefined && string !== '' && string[0] !== firstupperChar ) {
        return (string[0]?.toUpperCase() + string?.slice(1))
    }
    else {
        return string
    }

}

