import React from 'react';
import { BsFileEarmarkText } from "react-icons/bs";
import DownloadCard from 'shared/UI/Links/DownloadCard/DownloadCard';

const DownloadDocCard = ({ ...props }) => {
    return (
        <DownloadCard
            icon={<BsFileEarmarkText size={'233px'} />}
            {...props}
        />
    );
};

export default DownloadDocCard;