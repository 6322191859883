import mobxState from "pages/TutorsMarket(Unused)/store/mobxState";
import { api } from "app/api";
export default function useGetElemsByFilters(urlPart) {  
    const defaultData = {
        page: mobxState.CurrentPage ?? '0',
        filters: mobxState.FiltersToFetch,
        count: mobxState.ElementsOnPage ?? '100',
        order: mobxState.OrderParams.parametr ?? 'label',
        direction: mobxState.OrderParams.direction ?? 'asc',
        SearchValue: mobxState.SearchValue ?? '%%'
    }
    async function getData(filterParametrs, count = defaultData.count){     
        const response = await api.post(urlPart, {...filterParametrs, count, skip: Number(Number(mobxState.CurrentPage) * Number(count) ) })
        return response
    }
    return getData   
}