import styled from "styled-components";

export const ChangePhotoInput = styled.div`
    max-width: 235px;
    max-height: 136px;
    width: 100%;    
    & input{
        display: none;
    }
    `

export const ImgPreview = styled.div`
    margin-bottom: 10px;
    max-width: 235px;
    width: 100%;
    height: 136px;
    border-radius: 15px;
    overflow: hidden;
    `

export const CourseSmallImg = styled.img`
object-fit: cover;
    width: 100%;
    height: 100%;
    `



export const ManageBtn = styled.div`
    height: 38px;
    display: flex;
    gap: 10px;
    width: 100%;
`
export const BtnsWrap = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    `

export const InputButtonLabel = styled.label`
     cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    `
