import { ContentHorizontal, ListVertical } from "shared/UI/Layouts";
import styled from "styled-components";
export const CourseInformationCard = styled.div`
    background: var(--default-style-back);
    box-shadow: var(--default-style-shadow);
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    overflow: hidden;
    border: none;
    gap: 0px;
    padding: 0;
`
export const MainCardBlock = styled(ContentHorizontal)`
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    margin-bottom: 15px;
    width: 100%;

`
export const ControlPanel = styled(ContentHorizontal)`
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;

`
export const HorisontalBtns = styled(ContentHorizontal)`
     display: flex;
    align-items: center;
    max-width: 250px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-end;
`

export const MainBlockText = styled(ListVertical)`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`
export const PInput = styled.p`
    font-size: 24px;
    font-weight: 700;
    line-height: 100%;
`

export const PLabel = styled.p`
      
        white-space: nowrap;
        font-size: 18px;
        font-weight: 600;
        line-height: 100%;
`

export const DeleteBtnsWrap = styled.div`
         width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 13px;
`