import PuplicMoadulesAndLessons from 'entities/MarketCourseDetail/CourseContent/PuplicMoadulesAndLessons';
import React from 'react';
import cl from './PublicCoursStyle.module.css'

const CourseContent = ({AllCourseData}) => {
    return (
        <div className={cl.InformationBlock}>
            <h2>Содержание курса</h2>
            {
                AllCourseData?.results?.map((module, index) => (
                    <PuplicMoadulesAndLessons
                        key={module.id}
                        Modul={module}
                        index={index}
                    />
                ))
            }

        </div>
    );
};

export default CourseContent;