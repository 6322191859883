import { api } from 'app/api';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AcceptButton } from 'shared/UI/Buttons';
import { InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
import { EntryArea, MailRecoveryForm, SendMailBtn } from './ui';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { MainPopup } from 'shared/UI/Popups';
import { Loader } from 'shared/UI/Loaders';

const EmailRecovey = ({ cancelAction }) => {
    const [dispatchNotice, setDispatchNotice] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    const { register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors }
    } = useForm({ mode: 'onChange' });

    const SendResetQuerry = (mail) => {
        setShowLoader(true);
        setDispatchNotice(true);
        api.post(`/user/PasswordRecovery/${watch('recoveryEmail')}`)
    }

    return (
        <MailRecoveryForm>
            <p>Укажите электронную почту для восстановления пароля</p>
            <InputValidateUseForm
                title={'Электронная почта'}
                type={'recoveryEmail'}
                error={errors?.recoveryEmail}
                validateSet={register("recoveryEmail", {
                    required: "Пожалуйста, введите e-mail.",
                    minLength: {
                        value: 1,
                        message: 'минимум 1 символа',
                    },
                    pattern: {
                        value: /^(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#%&*\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{2,63}\.)+[a-zA-Z]{2,15}$/,
                        message: 'неверный формат email',
                    }
                })}
            />
            <EntryArea>
                <SendMailBtn
                    type='button'
                    style={{ width: '100%' }}
                    title={showLoader ? <Loader style={{ border: '2px solid #ffffff', borderTop: '2px solid transparent' }} /> : 'Отправить письмо'}
                    onClick={() => { showLoader ? console.log() : SendResetQuerry() }}
                />
            </EntryArea>
            <button onClick={() => cancelAction(faLessThanEqual)} >назад</button>
            <MainPopup
                isOpened={dispatchNotice}
                onClose={e => setDispatchNotice(false)}
                title={'!!!'}
                showHeader={true}>
                Письмо для смены пароля отправлено вам на электроную почту!!
                <AcceptButton title={'Отлично'} onClick={() => setDispatchNotice(false)}></AcceptButton>
            </MainPopup>

        </MailRecoveryForm>
    );
};

export default EmailRecovey;