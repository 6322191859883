import React, { useState } from 'react';
import { ContentHorizontal, ListVertical } from 'shared/UI/Layouts';
import { useForm } from 'react-hook-form';
import { api } from 'app/api';
// import { AddQuestionSidebar, SelectQParamBtn } from './styled/AddQuestionStyled';
import { QuestionTypeCard } from './components/QuestionTypeCard';
import { RedQuestionBtn } from 'shared/UI/Buttons/OtherButtons/QuestionTextStyled';
import { ContentHorizontalCenter } from 'pages/StudentArea/StudentCoursesList/courseShow/SurveyLobby/SurveyLobbyStyled';
import { DarkGreenButton} from 'features/TeacherArea.jsx/MyLearnCourseLesson/styled/MyLearnCourseLessonStyled';
import { AddQuestionSidebar, SelectQParamBtn } from './styled/AddQuestionStyled';
const AddQuestionForm = ({ addAction, cancel }) => {
    const [NewQestionAnswers, setNewQestionAnswers] = useState(null)
    const defautltTest = {
        imgQuest: null,
        type: null,
        manyAnswers: false,
        imgAnswers: false,
    }

    const { register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors, isTouched, isDirty, isValid }
    } = useForm(
        {
            mode: 'onChange',
            defaultValues: { ...defautltTest }
        },
    );

    //функция сохранения вопроса 
    const saveQuestion = (questionData) => {
        var { writeOrChose, manyAnswers, imgAnswers, ...SendingData } = questionData    

        if (questionData.type === 1) {
            SendingData.type = '1'
        } else
            if (questionData.type === 2) {
                SendingData.type = '2'
                SendingData.manyAnswers = true
                SendingData.rightAnswer = []
            } else
                if (questionData.type === 3) {
                    SendingData.type = '3'
                    SendingData.manyAnswers = false
                    SendingData.rightAnswer = null
                    NewQestionAnswers !== null && NewQestionAnswers.map((element, index) => {
                        if (element.true == true) { SendingData.rightAnswer = index }
                    })
                }
        JSON.parse(imgAnswers) == true
            ? SendingData.imgAnswers = true
            : SendingData.imgAnswers = false
        SendingData.answers = NewQestionAnswers
        SendingData.textQuest = ''

        addAction(SendingData)
    }

    const saveCancel = () => {
        reset({ ...defautltTest })
        cancel()
    }

    return (
        <form>
            <AddQuestionSidebar>
                <SelectQParamBtn active={watch('type') == 1} type='button' onClick={() => { setValue('type', 1); setValue('imgAnswers', null) }}>Текст</SelectQParamBtn>
                <SelectQParamBtn active={watch('type') == 3} type='button' onClick={() => { setValue('type', 3); setValue('imgAnswers', null) }}>Один ответ</SelectQParamBtn>
                <SelectQParamBtn active={watch('type') == 2} type='button' onClick={() => { setValue('type', 2); setValue('imgAnswers', null) }}>Много ответов</SelectQParamBtn>
            </AddQuestionSidebar>
            <ListVertical>
                {
                    watch('type') == 1
                        ?
                        <>
                            <h2>Текстовые вопросы</h2>
                            {/* {/* <p onClick={() => setValue('imgAnswers', true)}>С картинками</p> */}
                            <QuestionTypeCard
                                title='Текстовый вопрос'
                                text='Вопрос с ответом в виде текста'
                                srcImg={window.location.origin + '/images/QuestionsPreviews/TextAnserPrev.png'}
                                active={watch('imgAnswers') == false}
                                onClick={() => setValue('imgAnswers', false)} />
                        </>
                        :
                        watch('type') == 3
                            ?
                            <>
                                <h2> Один ответ</h2>
                                <ContentHorizontalCenter>
                                    <QuestionTypeCard
                                        title='Ответы с картинками'
                                        text={'Один ответ, ответы с картинками'}
                                        srcImg={window.location.origin + '/images/QuestionsPreviews/ManyRpund.png'}
                                        active={watch('imgAnswers') === true}
                                        onClick={() => setValue('imgAnswers', true)} />
                                    <QuestionTypeCard
                                        title='Ответы без картинок'
                                        text={'Один ответ, ответы без картинок'}
                                        active={watch('imgAnswers') === false}
                                        srcImg={window.location.origin + '/images/QuestionsPreviews/QuestionWithEllipse.png'}
                                        onClick={() => setValue('imgAnswers', false)} />
                                </ContentHorizontalCenter>
                            </>
                            :
                            watch('type') == 2
                                ?
                                <>
                                    <h2> Много ответов</h2>
                                    <ContentHorizontalCenter>
                                        <QuestionTypeCard
                                            title='Ответы с картинками'
                                            text={'Несколько правильных ответов, ответы с картинками'}
                                            srcImg={window.location.origin + '/images/QuestionsPreviews/ManyBox.png'}
                                            active={watch('imgAnswers') === true}
                                            onClick={() => setValue('imgAnswers', true)} />
                                        <QuestionTypeCard
                                            title='Ответы без картинок'
                                            text={'Несколько правильных ответов, ответы без картинок'}
                                            srcImg={window.location.origin + '/images/QuestionsPreviews/QuestionWithBox.png'}
                                            active={watch('imgAnswers') === false}
                                            onClick={() => setValue('imgAnswers', false)} />
                                    </ContentHorizontalCenter>
                                </>
                                :
                                <p>Выберите тип создаваемого вопроса</p>
                }
                {watch('type') !== null
                    ?
                    <ContentHorizontal style={{ width: ' 100%' }}>
                        <RedQuestionBtn style={{ width: ' 100%', padding: ' 16px 0' }} type={'button'} onClick={e => { saveCancel() }} > Отмена</RedQuestionBtn>
                        <DarkGreenButton style={{ width: ' 100%', padding: ' 16px 0', }} onClick={handleSubmit(saveQuestion)} type={'submit'}>Создать</DarkGreenButton>
                    </ContentHorizontal>
                    : null
                }
            </ListVertical>

        </form>
    );
};
export default AddQuestionForm;
