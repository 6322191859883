import { SimpleWhiteButton } from 'shared/UI/Buttons';
import React from 'react';

const CourseActionBtn = ({text, action, className }) => {
    return (
        <SimpleWhiteButton
        className={className}
        onClick={() => action()} >
       {text}
    </SimpleWhiteButton>
    );
};

export default CourseActionBtn;