import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import HocConfirmRoute from './HocConfirmRoute';
import HocInviteRoute from './HocInviteRoute';

const HocDirectRoute = () => {

    const location = useLocation()

    return (
        location.pathname.indexOf('/confirmation') === 0 ?
            <HocConfirmRoute />
            :
            location.pathname.indexOf('/invite') === 0 ?
                <HocInviteRoute />
                :
                <Outlet />
    );
};

export default HocDirectRoute;