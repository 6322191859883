import React, { useState } from 'react';
import SuccessPopupChange from 'entities/PersonalSecurity/SuccessPopupChangePasword/SuccessPopupChange';
import PasswordParametrsList from 'entities/PersonalSecurity/PasswordParametrsList/PasswordParametrsList';

const ChangePassword = () => {
    const [SendOnMail, setSendOnMail] = useState(false)
    return (
        <>
            <PasswordParametrsList SendOnMail={SendOnMail} setSendOnMail={setSendOnMail} />
            <SuccessPopupChange
                isOpen={SendOnMail}
                onClose={setSendOnMail}
                text={'Письмо с подтверждением смены пароля отправлено вам на электроную почту!!'}
            />

        </>
    );
};

export default ChangePassword;