import { BlueButton } from "shared/UI/Buttons"
import { InputValidateUseForm } from "shared/UI/Inputs/HookForm"
import styled from "styled-components"


export const AddLessonSectionForm = styled.form`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    min-width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
`
export const CreateLessonBtn = styled(BlueButton)`     
    height: 32px;   
    padding: 8px 20px;
    width: fit-content;
    display: block;
    font-weight: 500;   
    width: 100%;
    line-height: 100%;
    `

export const DurationLesson = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: unset;

    input {
        max-width: 100px;
    }   

    p {
        min-width: max-content;
    }
`