import DescriptionElement from 'entities/TeacherArea/CardCourseTeacher/components/Description/Description';
import ModerationHeader from 'pages/TeacherArea/CoursInformation/Components/ModerationHeader/ModerationHeader';
import CourseImgChanger from 'features/TeacherArea.jsx/CourseImgChanger/CourseImgChanger';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AcceptButton, BlueButton, GreenButton, RedButton, YellowButton, ActionBtn } from 'shared/UI/Buttons';
import { MyTextarea } from 'shared/UI/Inputs';
import { InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
import InputArea from 'shared/UI/Inputs/InputArea';
import { ContentHorisontalSB, ContentHorizontal, ListVerticalStart } from 'shared/UI/Layouts';
import { MainPopup } from 'shared/UI/Popups';
import { MainSelect } from 'shared/UI/Selects';
import { ControlPanel, CourseInformationCard, DeleteBtnsWrap, HorisontalBtns, MainBlockText, MainCardBlock, PInput, PLabel } from './Styled/ui';
import { ContentHorizontalCenter } from 'pages/StudentArea/StudentCoursesList/courseShow/SurveyLobby/SurveyLobbyStyled';
import { api } from 'app/api';
import _ from 'lodash'
import { CourseDetilBlueBtn, CourseDetilGreenBtn, CourseDetilRedBtn, CourseDetilYellowBtn } from './Styled/EditCourseBtns.styled';

const TeacherCourseCardEditable = ({ cid, setModulesAndLessons }) => {
    const [serverModeration, setServerModeration] = useState(3)
    const [DefaultCourseData, setDefaultCourseData] = useState({})
    const [Subjects, setSubjects] = useState([])
    const [EditInfo, setEditInfo] = useState(false)
    const [PublicationError, setPublicationError] = useState(false)
    const [CourseData, setCourseData] = useState({})

    const {
        register,
        reset,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        mode: 'onBlur',
    });

    useEffect(() => {
        const SetFirstData = async () => {
            const subjects = await api.get("/Course/Subjects")
            setSubjects(subjects.data)
            const course = await api.get("/Module/author/Course/" + cid)
            setServerModeration(course.data.course.moderationStatus)
            setDefaultCourseData({ ...course.data.course })
            setModulesAndLessons(_.sortBy(course.data.results, 'order'))
            reset(course.data.course);
        }
        SetFirstData()
    }, [cid])

    const EditStatus = (currentStatus, reset) => {
        console.log(serverModeration == 0, !watch('isPublic'), !reset)
        console.log(serverModeration == 0 && !watch('isPublic') && !reset)
        return (
            serverModeration == 0 && !watch('isPublic') && !reset
                ? 0
                : currentStatus === 2
                    ? 3
                    : currentStatus === 3
                        ? 1
                        : currentStatus === 0
                            ? 1
                            : reset ? 3 : 1
        )
    }

    const saveCoursChange = (Date) => {
        var { fio, fileData, mimeType, ...fields } = Date;
        api.put("/Course/" + cid, { ...fields, previewImg: CourseData.previewImg, PreviewImg: CourseData.PreviewImg, isPublic: false })
            .then(result => {
                setDefaultCourseData({
                    ...Date, isPublic: false,
                    moderationStatus: EditStatus(watch('moderationStatus'), true)
                })
                setServerModeration(3)
                setEditInfo(false)
                setValue('isPublic', false)
            }
            )
    }

    const Publicate = () => {
        var { fio, fileData, mimeType, ...fields } = watch();
        api.put("/Course/" + cid, { ...fields, previewImg: CourseData.previewImg, PreviewImg: CourseData.PreviewImg, isPublic: !watch('isPublic') })
            .then(result => {
                setDefaultCourseData(
                    {
                        ...CourseData, isPublic: !watch('isPublic'),
                        moderationStatus: EditStatus(CourseData.moderationStatus, false)
                    })
                setEditInfo(false)
                setValue('isPublic', !watch('isPublic'))
            }
            )
            .catch(error =>
                error.response.status == 480 && setPublicationError(true)
            )
    }
    return (
        <CourseInformationCard>
            <ModerationHeader statusmod={DefaultCourseData.moderationStatus} isPublic={watch('isPublic')} />
            <ControlPanel style={{ padding: '10px 32px', height: '87px', alignItems: 'center', borderBottom: '1px solid #c0c0c0' }}>
                {EditInfo
                    ?
                    <InputValidateUseForm
                        type={'text'}
                        error={errors?.label}
                        validateSet={register("label", {
                            required: 'Поле должно быть заполнено',
                        })}
                    />
                    :
                    <PInput style={{ height: 'auto' }}>{watch('label')}</PInput>
                }
                <HorisontalBtns>
                    {
                        EditInfo
                            ?
                            <>
                                <CourseDetilBlueBtn
                                    type='submit'
                                    disabled={JSON.stringify(DefaultCourseData) == JSON.stringify(CourseData)}
                                    onClick={handleSubmit(saveCoursChange)} >Сохранить</CourseDetilBlueBtn>
                                <CourseDetilRedBtn
                                    type='button'
                                    disabled={JSON.stringify(DefaultCourseData) == JSON.stringify(CourseData)}
                                    onClick={e => {
                                        setEditInfo(false); reset(DefaultCourseData)
                                    }}
                                >Отменить</CourseDetilRedBtn>
                            </>
                            :
                            <>
                                {
                                    DefaultCourseData.moderationStatus === 2 ?
                                        <CourseDetilGreenBtn
                                            disabled={true}
                                        >Опубликовать</CourseDetilGreenBtn>
                                        :
                                        watch('isPublic')
                                            ?
                                            <CourseDetilYellowBtn
                                                onClick={() => Publicate()}
                                            >Снять</CourseDetilYellowBtn>
                                            :
                                            <CourseDetilGreenBtn
                                                onClick={() => Publicate()}
                                            >Опубликовать</CourseDetilGreenBtn>
                                }
                                <CourseDetilBlueBtn
                                    as={BlueButton}
                                    onClick={e => {
                                        setEditInfo(true);
                                    }}
                                >Редактирвать</CourseDetilBlueBtn>
                            </>
                    }
                </HorisontalBtns>
            </ControlPanel>
            <MainCardBlock style={{ padding: '14px 32px 32px', margin: '0' }} >
                <CourseImgChanger
                    EditAloow={EditInfo}
                    id={'img' + watch('id')}
                    DefaultCoursData={watch()}
                    updatePhotoAction={setCourseData}
                    RemovePhotoAction={e => {
                        setCourseData({ ...watch(), fileData: null, mimeType: null, PreviewImg: null, previewImg: null })
                    }}
                />
                <MainBlockText viewMode={'title'}>
                    <ContentHorisontalSB style={{ width: '100%' }} >
                        <ContentHorizontalCenter>
                            <PLabel>Длительность курса</PLabel>
                            {EditInfo ?
                                <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                                    <InputValidateUseForm
                                        type={'number'}
                                        error={errors?.duration}
                                        validateSet={register("duration", {
                                            required: 'Поле должно быть заполнено',
                                            min: {
                                                value: 0,
                                                message: 'Длительность должна быть положительной',
                                            }
                                        })}
                                    />
                                    <p>ч</p>
                                </div>
                                : <p>{watch('duration')} ч</p>
                            }

                        </ContentHorizontalCenter >
                        <ContentHorizontalCenter>
                            <PLabel >Стоимость</PLabel>
                            {EditInfo ?
                                <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                                    <InputValidateUseForm
                                        type={'number'}
                                        error={errors?.price}
                                        validateSet={register("price", {
                                            required: 'Поле должно быть заполнено',
                                            min: {
                                                value: 0,
                                                message: 'Цена должна быть от 0 и выше',
                                            }
                                        })}
                                    />
                                    <p>руб</p>
                                </div>
                                : <p>{watch('price')}</p>
                            }
                        </ContentHorizontalCenter >
                    </ContentHorisontalSB >

                    <ContentHorizontal style={{ alignItems: 'center', with: '100%' }} >
                        <PLabel style={{ height: '32px', display: 'flex', alignItems: 'center' }}>Предмет </PLabel>
                        {EditInfo
                            ?
                            <Controller
                                name="courseSubjectID"
                                control={control}
                                rules={{ required: 'Пожалуйста, выберите параметр' }}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <MainSelect
                                        style={{ width: ' 100%' }}
                                        placeholder={Subjects?.filter(sub => sub.id == watch("courseSubjectID", false))[0]?.subject}
                                        map={Subjects}
                                        value={['id']}
                                        label={['subject']}
                                        onChange={e => setValue("courseSubjectID", e.value)}
                                        error={errors?.CourseSubjectID}
                                        defaultValue={Subjects?.filter(sub => sub.id == watch("courseSubjectID", false))[0]?.subject}

                                    />
                                )}
                            />
                            :
                            Subjects.map(element => (element.id == watch('courseSubjectID')) && element.subject)
                        }
                    </ContentHorizontal>

                    <ListVerticalStart style={{ gap: '10px' }}>
                        <PLabel>Описание</PLabel>

                        {EditInfo
                            ?
                            <InputArea
                                type={'text'}
                                id={'description'}
                            >
                                <MyTextarea
                                    minRows={4}
                                    validateSet={{ ...register("description", {}) }}
                                />
                            </InputArea>
                            :
                            <DescriptionElement
                                descriptionText={watch('description')}
                                hiddenHeight={35}
                                stringCount={2}
                            />
                        }
                    </ListVerticalStart>
                </MainBlockText>
            </MainCardBlock>

            <MainPopup
                isOpened={PublicationError}
                onClose={e => setPublicationError(false)}
                title={'Невозможно опубликовать!!!'}
                showHeader={true}
            >
                <p>Возможно в курсе нет уроков</p>
                <DeleteBtnsWrap>
                    <AcceptButton style={{ width: '100%', textAlign: 'center' }}
                        title={'ОК'}
                        onClick={e => setPublicationError(false)}
                    />
                </DeleteBtnsWrap>
            </MainPopup>
        </CourseInformationCard >
    );
};

export default TeacherCourseCardEditable;