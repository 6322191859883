import ContentHorizontal from "shared/UI/Layouts/ContentHorizontal";
import styled from "styled-components";




export const ContentHorizontalCenter = styled(ContentHorizontal)`
justify-content: center;
 align-items: center;
 /* height: 40px; */
`



