import Cookies from "universal-cookie";

const cookies = new Cookies()

export const userId = Number(cookies.get('userId'));
export const device_token = cookies.get('device_token');
export const invite_group = (newInvite) => {
    if (newInvite) {
        cookies.set('invite_group', newInvite)
    }
    return cookies.get('invite_group');
}
// TODO в дальнейшем заменить на cookies
export const token = () => { return localStorage.getItem('token'); }

export const removeCookies = (stroke) => {
    cookies.remove(stroke)
}