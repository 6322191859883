import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import _ from 'lodash';
import { MainWrap } from 'shared/UI/Layouts';
import { Lesson } from 'shared/UI/LearnPrev/LearmPrev.styled';
import StudingSidebar from 'widgets/StudingArea/CourseNavigation/StudingSidebar';
import CoursesLesson from 'widgets/StudingArea/CourseLesson/CoursesLesson';
import TestPrev from 'widgets/StudingArea/TestPrev/TestPrev';
import { TestPassing } from '../../courseShow/TestPassing';

const LearnPrev = () => {
    const { id_test } = useParams()
    return (
        <MainWrap>
            <Lesson>
                <StudingSidebar />
                {id_test === undefined
                    ?
                    <CoursesLesson />
                    :
                    <TestPrev />
                }              
            </Lesson>
        </MainWrap>
    )
}
export default LearnPrev