import styled from "styled-components";
export const PersonalAvatarRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
`

export const PersonalPhoto = styled.div`
        display: flex;
        flex-direction: row;
        gap: 10px;
 
`


