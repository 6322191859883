import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'app/api';
import TestSettings from 'widgets/TestEditor/TestSettings/TestSettings';
import TeacherQuestionsList from 'widgets/TestEditor/TeacherQuestionsList';
import { convertTimeSpan, formateTimeSpanToMinute } from 'shared/hooks';

const AddTestPage = () => {
    const { tid } = useParams()
    const [TestData, setTestData] = useState() //данные теста (survey + questions)

    useEffect(() => {
        api.get(`Survey/${tid}`)
            .then((result) => {
                setTestData(result.data)
            })
    }, [tid])

    //добавление нового вопроса в список вопросов
    const PushNewQuestion = (data, ActualList) => {
        const { answers, imgQuest, ...any } = data
        api.post(`Question`, { ...any, SurveyId: tid, Points: 1 })
            .then(result => {
                if (result.status == 200) {
                    setTestData({
                        ...TestData, questions: ActualList.concat({ question: { id: result.data.id, ...data }, answerOptions: [] })
                        //  ...TestData, questions: TestData.questions.concat({ question: { id: result.data.id, ...data }, answerOptions: [] })
                    })
                }
            })
    }

    //Удаление вопроса из списка из списка
    const DeleteQuestion = (elId) => {
        api.delete(`Question/${elId}`)
            .then((result) => {
                if (result.status == 200) {
                    setTestData({ ...TestData, questions: TestData.questions.filter(elem => (elem.question.id != elId)) })
                }
            })
    }

    //Функция отправки данных теста в базу, здесь должен быть запрос
    const SaveSettings = (newSettings) => {
        api.put(`Survey/${tid}`, { ...newSettings, duration: convertTimeSpan(newSettings.duration) })
            .then(() => {
                const convertSettings = { ...newSettings, duration: convertTimeSpan(newSettings.duration) }
                setTestData({ ...TestData, survey: convertSettings })
            })

        // const convertSettings = { ...newSettings, duration: convertTimeSpan(newSettings.duration) }
        // setTestData({ ...TestData, survey: convertSettings })
    }

    const updateQuestionsList = (data) => {
        setTestData({ ...TestData, questions: data })
    }
    return (<>
        {/* //Добавление/создание и вывод вопросов теста */}
        <TeacherQuestionsList
            testLabel={TestData?.label}
            questionsList={TestData?.questions}
            addQuestion={PushNewQuestion}
            deleteQuestion={DeleteQuestion}
            updateQuestionsList={updateQuestionsList}
        />
        {TestData?.questions &&
            <TestSettings
                settings={TestData?.survey}
                saveSettings={SaveSettings}
                countOfQuestions={TestData?.questions.length}
            />
        }
        {/* <GreenButton text='Сохранить тест' onClick={e => SaveTest()}></GreenButton>*/}
    </>
    );
};

export default AddTestPage;