import React, { useEffect, useState } from 'react';
import { DefaultWrapper } from 'shared/UI/Wrappers';
import { observer } from 'mobx-react-lite';
import userStore from 'shared/store/userStore';
import { LoaderBlueFullView } from 'shared/UI/Loaders';
import { Link, useNavigate } from 'react-router-dom';
import RegistrationGoesWrong from 'widgets/Registration/RegistrationGoesWrong/RegistrationGoesWrong';
import RegSuccessMessages from 'widgets/Registration/RegSuccessMessages/RegSuccessMessages';
import RegistratonFormSteps from 'widgets/Registration/RegistratonFormSteps/RegistratonFormSteps';
import { PageRegTeacherWrap, RegFormWrap } from './ui';

const PageRegTeacher = observer((props) => {
    const [inLoad, setInLoad] = useState(null)
    const [IsSucces, setIsSucces] = useState(null)
    const [Step, setStep] = useState(1)
    const [error, setError] = useState({ error: '', messages: '' })
    const LastStep = 3
    const navigate = useNavigate()
    const goAuth = () => navigate('/login')
    const goHome = () => navigate('/')
    return (
        <PageRegTeacherWrap>
            <RegFormWrap>
                {
                    !inLoad
                        ?
                        {
                            null:
                                <RegistratonFormSteps
                                    Step={Step}
                                    setStep={setStep}
                                    setIsSucces={setIsSucces}
                                    setInLoad={setInLoad}
                                    LastStep={LastStep}
                                    setError={setError}
                                />
                            ,
                            true:
                                <RegSuccessMessages
                                    auth={userStore.auth}
                                    goAuth={() => goAuth()}
                                    goHome={() => goHome()}
                                />
                            ,
                            false:
                                <RegistrationGoesWrong
                                    error={error}
                                    CancelAction={() => { setIsSucces(null); setStep(0) }}
                                />
                            ,
                        }[IsSucces]
                        : <LoaderBlueFullView />
                }
            </RegFormWrap>
        </PageRegTeacherWrap >
    );
});

export default PageRegTeacher;