import React from 'react';
import { AiOutlineRollback } from 'react-icons/ai';
import { IoCaretBackOutline, IoCaretForwardOutline } from 'react-icons/io5';
import { cloneElement } from 'react';
import { LessonManageBtn } from './ui';

const NavigateBtns = ({ id_course, id_module, id_lesson, NavigateArrary, from, buttons, wrap = <LessonManageBtn /> }) => {
    console.log(NavigateArrary);
    return (
        cloneElement(wrap, {
            children:
                <>{from !== 'edit' && from !== 'learn' && from !== 'constructor' &&
                    <>
                        {
                            buttons?.back ?
                                cloneElement(buttons.back, {
                                    to: from == 'demo'
                                        ? `/courses/coursedetail/${id_course}`
                                        : `/mylearn/course/${id_course}`
                                })
                                :
                                <LessonManageBtn
                                    to={
                                        from == 'demo'
                                            ? `/courses/coursedetail/${id_course}`
                                            : `/mylearn/course/${id_course}`
                                    }
                                    type="button" title={'Вернуться'}
                                >
                                    <AiOutlineRollback />
                                </LessonManageBtn>
                        }
                    </>
                }
                    {
                        from !== 'edit' && from !== 'demo' && from !== 'constructor'
                        && <>
                            {
                                buttons?.back ?
                                    cloneElement(buttons.back, {
                                        to: NavigateArrary?.prev == 'back'
                                            ? `/mylearn/course/${id_course}`
                                            :
                                            NavigateArrary?.prev?.type == 'lesson'
                                                ? `/mylearn/course/${id_course}/module/${id_module}/lesson/${NavigateArrary?.prev?.id}`
                                                : `/mylearn/course/${id_course}/module/${id_module}/lesson/${NavigateArrary?.prev?.lesson_id}/surveyLobby/${NavigateArrary?.prev?.id}`
                                    })
                                    :
                                    <LessonManageBtn
                                        to={NavigateArrary?.prev == 'back'
                                            ? `/mylearn/course/${id_course}`
                                            :
                                            NavigateArrary?.prev?.type == 'lesson'
                                                ? `/mylearn/course/${id_course}/module/${id_module}/lesson/${NavigateArrary?.prev?.id}`
                                                : `/mylearn/course/${id_course}/module/${id_module}/lesson/${NavigateArrary?.prev?.lesson_id}/surveyLobby/${NavigateArrary?.prev?.id}`
                                        }
                                        type="button" title={'Назад'}
                                    >
                                        <IoCaretBackOutline />
                                    </LessonManageBtn>
                            }

                            {buttons?.back ?
                                cloneElement(buttons.next, {
                                    to:
                                        NavigateArrary?.next == 'end'
                                            ? `/mylearn/course/${id_course}`
                                            :
                                            NavigateArrary?.next?.type == 'lesson'
                                                ? `/mylearn/course/${id_course}/module/${id_module}/lesson/${NavigateArrary?.next?.id}`
                                                : `/mylearn/course/${id_course}/module/${id_module}/lesson/${NavigateArrary?.next?.lesson_id}/surveyLobby/${NavigateArrary?.next?.id}`
                                })
                                :
                                <LessonManageBtn
                                    to={NavigateArrary?.next == 'end'
                                        ? `/mylearn/course/${id_course}`
                                        :
                                        NavigateArrary?.next?.type == 'lesson'
                                            ? `/mylearn/course/${id_course}/module/${id_module}/lesson/${NavigateArrary?.next?.id}`
                                            : `/mylearn/course/${id_course}/module/${id_module}/lesson/${NavigateArrary?.next?.lesson_id}/surveyLobby/${NavigateArrary?.next?.id}`
                                    }
                                    type="button" title={'Вперёд'}
                                >
                                    <IoCaretForwardOutline />
                                </LessonManageBtn>
                            }
                        </>
                    }

                    {from === 'constructor' &&
                        <>
                            {buttons?.save ? buttons?.save : null}
                            {buttons?.clear ? buttons?.clear : null}
                            {buttons?.preview ? buttons?.preview : null}
                        </>
                    }
                </>
        })




    );
};

export default NavigateBtns;