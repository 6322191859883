import mobxState from "../store/mobxState";
import { api } from "app/api";
async function ResetFilters(url) {
   const defaultData = {
      page:  '0',     
      count: '100',
      order:  'label',
      direction:  'asc',
      SearchValue:  '%%',
      ...mobxState.DefaultFilters
   }
   const response = await api.post(url, {...defaultData })
   return response
}
export { ResetFilters }