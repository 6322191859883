import { ContentHorizontal } from "shared/UI/Layouts";
import styled from "styled-components";

export const ProfileBackButton = styled(ContentHorizontal)`  
  background-color: #E8F1FF;
  border-radius: 13px;
  padding: 12px 20px;
  color: #163C85;
  font-size: 16px;  
  font-weight: 500;  
 `