import ImgDefault from 'shared/UI/Images/ImgDefault/ImgDefault';

import React, { useEffect, useState } from 'react';
import { host } from 'app/globals';
import { ColRowWrap } from '../styled/ColRowWrap.styled';
import { AnswerWrap } from '../styled/AnswerWrap.styled';
import { observer } from 'mobx-react-lite';
import MySurveysStore from 'shared/store/MySurveysStore';
import { ListVertical } from 'shared/UI/Layouts';
import { AnswerText } from '../styled/AnswerText.styled copy';

const RenderSelect = observer(({ Question, setAnswer, isLast }) => {
    const [QAnswer, setQAnswer] = useState([])
    const [updateType, setUpdateType] = useState(false)

    useEffect(() => {
        QAnswer.length !== 0
            ? MySurveysStore.setHasAnswer(true)
            : MySurveysStore.setHasAnswer(false)
    },
    [QAnswer])

    useEffect(() => {
        MySurveysStore.SendAnswerAction &&
            setAnswer(
                Question?.type == 2
                    ? { id: Question.id, answer: QAnswer }
                    :
                    Question?.type == 3
                        ? { id: Question.id, answer: QAnswer }
                        : { id: QAnswer[0].id, answer: QAnswer }, Question.id, updateType)
        MySurveysStore.setAnswerAction(false)
        // console.log('отправка селекта завершена')
    }, [MySurveysStore.SendAnswerAction])

    useEffect(() => {
        var FirstList = []
        var updateStatus = false
        Question.answerOptions.map((option) => {
            option.answered && FirstList.push({ id: option.id, answered: option.answered });
            if (option.answered) { updateStatus = true }
        }
        )
        updateStatus && setUpdateType(updateStatus)
        setQAnswer(FirstList)
    }, [Question])

    const EditLoacalAnswer = (value, check) => {
        if (Question?.type == 2) { // радиокнопки
            if (!updateType) { //Если происходит обновление ответа чекбоксов
                if ((QAnswer.find(elem => elem.id == value.id))) {
                    setQAnswer([...QAnswer.filter(item => item.id !== value.id)])
                } else {
                    setQAnswer([...QAnswer.filter(item => item.id !== value.id), { id: value.id, answered: true }])
                }
            } else {
                setQAnswer([...QAnswer.filter(item => item.id !== value.id), { id: value.id, answered: check }])
            }
        }
        if (Question?.type == 3) { //чекбоксы             
            QAnswer.map(sel => sel.answered = false)
            setQAnswer([...QAnswer.filter(item => item.id !== value.id), { id: value.id, answered: true }])
        }
    }

    return (
        <ColRowWrap gap={'30px'} repeat={Question.imgAnswers ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'} >
            {Question?.answerOptions.map((answer, index) => (
                <AnswerWrap htmlFor={answer.id} key={answer.id}>
                    <input name={Question?.id} style={(answer.imgId && Question.imgAnswers) ? { top: '45px' } : { top: '50%' }}
                        checked={(QAnswer.find(elem => elem.id == answer.id)?.answered) ? true : false}
                        id={answer.id}
                        value={answer.id}
                        type={Question?.type == 2 ? "checkbox" : "radio"}
                        onChange={e => EditLoacalAnswer({ id: answer.id, answered: answer.answered }, e.target.checked)}
                    />
                    <ListVertical>
                        {(answer.imgId && Question.imgAnswers) ?
                            <ImgDefault width={'160px'} height={'90px'} src={`${host}/api/GetFile/${answer.imgId}`} />
                            : null}
                        <AnswerText>{answer.textAnswer}</AnswerText>
                    </ListVertical>
                </AnswerWrap>
            ))}
        </ColRowWrap>
    );
});
export default RenderSelect;