import { DarkGreenButton } from 'features/TeacherArea.jsx/MyLearnCourseLesson/styled/MyLearnCourseLessonStyled';
import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MainPopup } from 'shared/UI/Popups';
import { SpacedApartWrap } from 'shared/UI/Wrappers';
import AddQuestionForm from 'entities/TestEditor/QuestionsCards/AddQuestionForm/AddQestionForm';
import cl from './TeacherQuestionsList.module.css'

const AddTestQuestion = ({ addAction, children, showDownButton }) => {
    const [NewQuestionForm, setNewQuestionForm] = useState(false)
    return (
        <>
            <SpacedApartWrap>
                <h2>Список вопросов</h2>
                <DarkGreenButton onClick={e => setNewQuestionForm(true)}>
                    <FaPlus /> {' ' + '  Добавить вопрос'}
                </DarkGreenButton>
                <MainPopup
                    style={{ width: '100%', maxWidth: ' 1280px' }}
                    className={cl.AddTestForm}
                    isOpened={NewQuestionForm}
                    onClose={e => setNewQuestionForm(false)}
                    title={'Форма добавления вопроса'}>
                    <AddQuestionForm              
                        addAction={(data) => { addAction(data) ; setNewQuestionForm(false) } }
                        showForm = {(e) => setNewQuestionForm(e)}
                        cancel={e => setNewQuestionForm(false)}
                    />
                </MainPopup >
            </SpacedApartWrap >
            {children}
            {showDownButton
                ?
                <DarkGreenButton onClick={e => setNewQuestionForm(true)}>
                    <FaPlus /> {' ' + '  Добавить вопрос'}
                </DarkGreenButton>
                : null
            }

        </>
    );
};

export default AddTestQuestion;