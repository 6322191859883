import React, { useEffect, useState } from 'react';
import { MyTextarea } from 'shared/UI/Inputs';
import cl from './CourseCreationSteps.module.css'
import BackNextBtns from './BackNextBtns/BackNextBtns';
import Stepper from './Stepper/Stepper';
import { api } from 'app/api';
import { MainSelect } from 'shared/UI/Selects';
import { Controller, useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
import InputArea from 'shared/UI/Inputs/InputArea';
import { InputValidateRadio } from 'shared/UI/Inputs/HookForm/InputValidateRadio';

import { Navigate } from 'react-router-dom';
import { ListVertical } from 'shared/UI/Layouts';

const CourseCreationSteps = ({ Courses, setCourses, setNewCoursPopupVisibility, subjects }) => {
    const [Step, setStep] = useState(1)
    const [StepperSubjects, setStepperSubjects] = useState([])
    const [redirectionLink, setRedirectionLink] = useState(null)
    const LastStep = 2
    const { register,
        handleSubmit,
        watch,
        reset,
        control,
        formState: { errors }
    } = useForm({ mode: 'onTouched' });

    useEffect(() => {
        setStepperSubjects(subjects)
    }, [subjects])

    const EndOfCreate = () => {
        setNewCoursPopupVisibility(false); setStep(1)
    }

    const goNextStep = (FormData) => {
        if (Step === LastStep) {
            api.post("course", { ...FormData, creator_id: Number(localStorage.getItem('userId')), CourseSubjectID: FormData.CourseSubjectID.value, supportTyp: 1, groupTyp: 1 })
                .then(result => {
                    setCourses([...Courses, result.data])
                    setStep(Step + 1)
                    reset()
                    setRedirectionLink(`/teachercabinete/course/${result.data.id}/info`)
                })
                .catch(e => {

                })
        } else {
            setStep(Step + 1)
        }
    }
    return (
        redirectionLink !== null
            ?
            <Navigate to={redirectionLink} />
            :
            <>
                <Stepper
                    CurrentStep={Step}
                    MaxSteps={3}
                />
                <div className={cl.stageContainer}>
                    {Step === 1 ?
                        <>
                            <InputValidateUseForm
                                title={'Назовите курс'}
                                type={'text'}
                                className={cl.addModuleInput}
                                error={errors?.label}
                                validateSet={register("label", {
                                    required: true,
                                    minLength: {
                                        value: 2,
                                        message: 'Минимум 5 символа'
                                    },
                                })}
                            />
                            <InputArea
                                title={'Краткое описание курса'}
                                type={'text'}
                                id={'description'}
                            >
                                <MyTextarea
                                    minRows={6}
                                    validateSet={{ ...register("description", {}) }}
                                />
                            </InputArea>
                        </>
                        : null
                    }
                    {Step === 2 ?
                        <>
                            <Controller
                                name="CourseSubjectID"
                                control={control}
                                rules={{ required: 'Пожалуйста, выберите параметр' }}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <MainSelect
                                        title={'Предмет'}
                                        placeholder={watch("CourseSubjectID", false).label || 'Выбор...'}
                                        map={subjects}
                                        value={['id']}
                                        label={['subject']}
                                        onChange={onChange}
                                        error={errors?.CourseSubjectID}
                                        defaultValue={StepperSubjects?.filter(sub => sub.id == watch("CourseSubjectID", false))[0]?.subject}
                                    />
                                )}
                            />
                            <InputValidateUseForm
                                title={'Длительность курса'}
                                type={'number'}
                                after={'ч.'}
                                error={errors?.duration}
                                validateSet={register("duration", {
                                    required: 'Не указана длительность',
                                })}
                            />
                            <InputValidateUseForm
                                title={'Цена курса'}
                                type={'number'}
                                after={'руб.'}
                                error={errors?.price}
                                validateSet={register("price", {
                                    required: 'Не указана цена',
                                })}
                            />
                        </>
                        : null
                    }
                    {Step === 3
                        ?
                        <ListVertical>
                            <p className={cl.successText}>Курс успешно создан!</p>
                        </ListVertical>
                        : null
                    }
                </div>
                <BackNextBtns
                    Step={Step}
                    goBackStep={e => setStep(Step - 1)}
                    goNextStep={handleSubmit(goNextStep)}
                    final={e => { EndOfCreate() }}
                />
            </>
    );
};

export default CourseCreationSteps;