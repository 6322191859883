import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import MainEditor from 'widgets/MainEditor/MainEditor';

const TeacherModuledEditor = () => {
    const { cid, mid, lid, tid } = useParams()



    return (
        <>
            {/* {lid
                ?
                <Outlet />
                :
                <MainEditor />
            } */}
            {tid
                ?
                <Outlet />
                : lid !== undefined
                    ?
                    <MainEditor />
                    : mid !== undefined
                        ?
                        <MainEditor />
                        :
                        <Outlet />


            }
        </>
    );
};

export default TeacherModuledEditor;