import React from 'react';
import { QuestionTypeCardStyled } from './styled/QuestionTypeCardstyled';
import SimpleImg from 'shared/UI/Images/SimpleImg/SimpleImg';

const QuestionTypeCard = ({ title, text, srcImg, active, ...props }) => {
    return (
        <QuestionTypeCardStyled active={active}{...props}>
            <div>
                <label>{title}</label>
                <p>{text}</p>
            </div>
            {
                srcImg
                    ? <SimpleImg 
                     
                        maxW='100%'
                        maxH='100%'
                        src={srcImg} />
                    : null
            }

        </QuestionTypeCardStyled>
    );
};

export default QuestionTypeCard;