import React from 'react';
import { AccountAvatar } from 'shared/UI/Avatars';
import ButtonsPhotoUser from 'features/ButtonsPhotoUser/ButtonsPhotoUser';
// import cl from '../../ProfilePage.module.css'
import { PersonalAvatarRow, PersonalPhoto } from './ui';

const UserPhoto = (props) => {

    return (
        <PersonalAvatarRow>
            <AccountAvatar
                size={'130px'}
            />
            <PersonalPhoto>
                <ButtonsPhotoUser />
            </PersonalPhoto>
        </PersonalAvatarRow>
    );
};

export default UserPhoto;