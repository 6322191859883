import { ProfileBackButton } from 'shared/UI/Buttons/ProfileBackButton/ProfileBackButton';
import React from 'react';
import { ContentHorizontal } from 'shared/UI/Layouts';
import { CustomLink } from 'shared/UI/Links';

const BackButtonSecurity = ({to}) => {
    return (
        <ContentHorizontal style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
            <CustomLink style={{ width: 'fit-content' }} to={to}>
                <ProfileBackButton>Назад</ProfileBackButton>
            </CustomLink>
        </ContentHorizontal>
    );
};

export default BackButtonSecurity;