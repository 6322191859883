import Output from 'editorjs-react-renderer';
import renderers from 'entities/CustomRerenders/CustomRerenders';
import { ShowClasses } from 'entities/CustomRerenders/ShowConfigs/DefaultConfig';
import EmptyLesson from 'models/EmptyLesson/EmptyLesson';
import React from 'react';
import { LessonArea, LessonHeader, StudyingWrap } from 'shared/UI/LearnPrev/LearmPrev.styled';

const DemoLessonContent = ({lessonData}) => {
    return (
        <StudyingWrap >
            <LessonHeader>Урок: {lessonData?.name} </LessonHeader>
            <LessonArea>
                <Output
                    classNames={ShowClasses}
                    data={(JSON.stringify(lessonData) === '{}')
                        ?
                        EmptyLesson
                        :
                        lessonData?.content
                    }
                    renderers={renderers}
                />
            </LessonArea>
        </StudyingWrap>
    );
};

export default DemoLessonContent;