import styled from 'styled-components'
import { CardWrap } from 'entities/PersonalArea/PersonalPayments/CardPay/styled/CardWrap.styled'

export const NewCardWrap = styled(CardWrap)`
background-color: #ffffff;
display: flex;
justify-content: center;
align-items: center;
width: ${({ width }) => width && width + 'px'};
height: ${({ height }) => height !== null ? height + 'px' : null};
box-shadow: 1px 1px 8px #00000050;
/* min-height: 86px; */

&>svg {
    font-size: 38px;
}
`