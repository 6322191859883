import { host } from 'app/globals';
import BuyConfirmCard from 'entities/SearchCourses/BuyConfirmCard/BuyConfirmCard';
import BuyRequest from 'features/BuyRequest/BuyRequest';
import React from 'react';
import { MainPopup } from 'shared/UI/Popups';

const CourseBuyConfirmation = ({ isOpened, onClose, CourseData, setBuyStatus, directories_data }) => {
    return (
        <MainPopup
            isOpened={isOpened}
            onClose={e => onClose(false)}
            title= {`Заявка на курс ${CourseData?.label}`}
        // className={cl.buyPopup}
        >
            <BuyRequest
                c_id={CourseData?.id}
                endAction={() => onClose(false)}
            />
            {/* <BuyConfirmCardч
                item={{
                    ...CourseData,
                    courseSubject: { ...directories_data?.learning_subjects?.find(subject => subject.id == CourseData?.courseSubjectID) }
                }}
                buyAction={() => { setBuyStatus(true) }}
                Photo={host + '/api/getFile/' + CourseData?.previewImg}
            /> */}
        </MainPopup>
    );
};

export default CourseBuyConfirmation;