import React, { useEffect, useState } from 'react';
import cl from './StudentCourseInformation.module.css'
import { EmptyFile } from 'shared/UI/Fillers';
import { host } from 'app/globals';
import { DefaultWrapper } from 'shared/UI/Wrappers';
import ImgDefault from 'shared/UI/Images/ImgDefault/ImgDefault';
import { CourseProggress, CourseTitle, MyCourseInfo } from './ui';
import { BlueModuleBtn } from 'entities/TeacherArea/CourseContentDetailed/styled/localModuleBtn.styled';
import { GreenTag } from '../CardCourseStudent/styled/greenTag.styled';
import { ListVertical, ListVerticalStart } from 'shared/UI/Layouts';

const StudentCourseInformation = ({ CourseData, Directions, lessonsCount }) => {
    const [PreviewImg, setPreviewImg] = useState(null)
    useEffect(() => {
        fetch(`${host}/api/GetFile/${CourseData?.previewImg}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
            .then(response => response.status == 200 && response.blob(response)
            )
            .then(blob => {
                if (blob) {
                    setPreviewImg(URL.createObjectURL(blob))
                } else {
                    setPreviewImg(EmptyFile)
                }
            })
    }, [CourseData])

    return (
        <CourseProggress>
            <ImgDefault
                $maxWidth='240px'
                $maxHeight='151x'
                src={CourseData?.previewImg ? `${host}/api/GetFile/${CourseData?.previewImg}` : PreviewImg}
            />
            <MyCourseInfo>
                <CourseTitle>{CourseData?.label}</CourseTitle>
                <h4>Кол-во уроков {lessonsCount}</h4>
                <p>Время прохождения {CourseData?.duration} ч</p>
                <p>Предмет {Directions?.map(subj => subj?.id == CourseData?.courseSubjectID ? subj?.subject : null)} </p>
                <p>{CourseData?.description}</p>
            </MyCourseInfo>
            <div style={{height: '100%'}}>
                <ListVerticalStart>
                    
                {CourseData.isCourseCompleted ? <GreenTag>Курс пройден</GreenTag> : null}
                </ListVerticalStart>
            </div>
        </CourseProggress>
    );
};

export default StudentCourseInformation;