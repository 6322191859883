import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
import { usePassEncrypt } from 'shared/hooks';
import { api } from 'app/api';
import { useLocation } from 'react-router-dom';
import { ErrorField } from 'shared/UI/Errors';
import { BlueAcceptButton, SplitLine } from 'shared/UI/Inputs/HookForm/InputValidateUseForm/styled/BlueValidateInput.styled';
import { EditProfileForm, ProfileHeader } from 'pages/PersonalArea/PersonalInormation/styled/ProfileStyles';
import BackButtonSecurity from 'features/PersonalArea/BackButtonSecurity/BackButtonSecurity';
import { ListVertical } from 'shared/UI/Layouts';
import { Loader } from 'shared/UI/Loaders';

const PasswordParametrsList = ({ SendOnMail, setSendOnMail }) => {

    const { register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors }
    } = useForm({ mode: 'OnInput' });

    const [sendRequest, setSendRequest] = useState(false)

    const location = useLocation()

    const passEncrypt = usePassEncrypt()

    const SetNewPassWord = (data) => {
        setSendRequest(true)
        data.newpassword !== data.newPasswordConffirm
            ? setError('newPasswordConffirm', { validate: '' })
            : (() => {
                api.post(`/user/changepassword`, {
                    password: passEncrypt(data.password),
                    newpassword: passEncrypt(data.newpassword)
                }, { cancel: true })
                    .then(() => { setSendOnMail(true); setSendRequest(false) })
                    .catch(error => {
                        if (error.response.status === 401) {
                            setError('password', { type: 'custom', message: 'Неверный пароль' });
                            setSendRequest(false)
                        }
                    })
            })()
    }


    return (
        <EditProfileForm onSubmit={handleSubmit(SetNewPassWord)} >
            <ListVertical>
                <BackButtonSecurity to='/profile/security' />
                <ProfileHeader>Смена пароля</ProfileHeader>
                <ErrorField
                    confirm={location?.state?.change === 'success' ? 0 : 1}
                    error={location?.state?.message}
                />
                <InputValidateUseForm
                    title={'Старый пароль'}
                    type={'password'}
                    error={errors?.password}
                    validateSet={register("password", {
                        required: "Пожалуйста введите старый пароль.",
                        validate: "Пароль неверный"
                    })}
                />
                <InputValidateUseForm
                    title={'Новый пароль'}
                    type={'password'}
                    error={errors?.newpassword}
                    validateSet={register("newpassword", {
                        required: "Пожалуйста введите новый пароль.",
                    })}
                />
                <InputValidateUseForm
                    title={'Подтверждение пароля'}
                    type={'password'}
                    error={errors?.newPasswordConffirm}
                    validateSet={register("newPasswordConffirm", {
                        required: "Пожалуйста введите новый пароль ещё раз",
                        validate: value => value == watch('newpassword') || 'пароли не совпадают'
                    })}
                />
                <SplitLine />
                <BlueAcceptButton title={!sendRequest ? 'Сменить' : <Loader style={{ border: "2px solid #ffffff", borderTop: "2px solid transparent" }} />} />
            </ListVertical>
        </EditProfileForm>
    );
};

export default PasswordParametrsList;