import React from 'react';
import cl from './NavigateSection.module.css'
import cn from 'classnames'
import { ListVertical } from 'shared/UI/Layouts';
 

const NavigateSection = ({ children, className }) => {

    return (
        <ListVertical className={cn(cl.navigateSection, className)}>
            {children}
        </ListVertical>
    );
};

export default NavigateSection;