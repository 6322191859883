import React from 'react';
import cl from './ElementOnPage.module.css'
import { MySelect } from 'shared/UI/Selects';
import mobxState from 'pages/TutorsMarket(Unused)/store/mobxState';
import { DefaultWrapper } from 'shared/UI/Wrappers';

const ElementsOnPage = ({ selectlist = [{ id: 4 }, { id: 6 }, { id: 10 }, { id: 25 }, { id: 50 }, { id: 100 }, { id: 500 }], onChange }) => {
    return (
        <div>
            <p>Элементов на странице</p>
            <DefaultWrapper className={cl.elOnPage}>
                <MySelect
                    name="count_of_elements_id"
                    current={mobxState.ElementsOnPage}
                    onChange={e => onChange(e)}
                    selectlist={selectlist}
                    selectedvalue='id'
                />
            </DefaultWrapper>
        </div>
    );
};

export default ElementsOnPage;