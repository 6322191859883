const useDurationTransform = (time) => {
    const TransformDuration = (time) => {
        var transformTime =
            time ?
                (Math.floor((time / 60)) !== 0 ? Math.floor((time / 60)) : '')
                + ' ' + (time >= 60 ? ' минут ' : '') + ' ' +
                (time - (time - time % 60)).toFixed(0) + ' секунд '
                : 'нет'

        return transformTime
    }

    return TransformDuration
};

export default useDurationTransform;