import { BackButton } from "shared/UI/Buttons";
import styled from "styled-components";
import TABHead from './TextAndBackHead'

export const Back = styled(BackButton)`
font-size: 20px;
color: var(--base-blue-color);
font-weight: 600;
width: auto;


&>span { 
    font-size: 18px;
}

&>span>svg { 
    font-size: 25px;
}
`

export const TextAndBackHead = styled(TABHead)`
&> div{width: 100%;
max-width: 100%}


`

