import React, { useEffect, useState } from 'react';
import userStore from 'shared/store/userStore';
import { InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
import { useForm } from 'react-hook-form';
import { api } from 'app/api';
import { usePassEncrypt } from 'shared/hooks';
import { ErrorField } from 'shared/UI/Errors';
import { useLocation } from 'react-router-dom';
import { BlueAcceptButton, SplitLine } from 'shared/UI/Inputs/HookForm/InputValidateUseForm/styled/BlueValidateInput.styled';
import { ListVertical } from 'shared/UI/Layouts';
import BackButtonSecurity from 'features/PersonalArea/BackButtonSecurity/BackButtonSecurity';
import { ProfileText, EditProfileForm, ProfileHeader } from './ui';
import { Loader } from 'shared/UI/Loaders';
import { MainPopup } from 'shared/UI/Popups';
import { AxiosError, isAxiosError } from 'axios';
import { observer } from 'mobx-react-lite';

const EmailParametrsList = ({ showPopupFunction, changeNotice, setChangeNotice }) => {
    const passEncrypt = usePassEncrypt()
    const location = useLocation()

    const [showPopup, setShowPopup] = useState({ status: false, title: '' })
    const [sendRequest, setSendRequest] = useState(false)


    const { register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors }

    } = useForm({ mode: 'OnInput' });

    useEffect(() => {
        // location?.state?.change === 'success'  && userStore.checkAuth()
    }, [])

    const changeRequest = (data) => {
        setSendRequest(true)

        // setChangeNotice(true)
        api.post(`user/EmailChange`, {
            mail: data.mail,
            password: passEncrypt(data.password)
        })
            .then(() => { showPopupFunction(true); setSendRequest(false) })
            .catch(error => {
                setSendRequest(false)
                if (isAxiosError && !error.response) {
                    setShowPopup({ status: true, title: "Ошибка" })
                }
                if (error.response.status === 401) {
                    setError('password', { type: 'custom', message: 'Неверный пароль' });
                }
                if (error.response.status === 402) {
                    setError('mail', {type: 'custom', message: 'Новая почта совпадает со старой'})
                }
                if (error.response.status === 409) {
                    setError('mail', {type: 'custom', message: 'Такая почта уже существует'})
                }
            })
    }
    return (
        <EditProfileForm onSubmit={handleSubmit(changeRequest)} >
            <ListVertical>
                <BackButtonSecurity to='/profile/security' />
                <ErrorField
                    confirm={location?.state?.change === 'success' ? 0 : 1}
                    error={location?.state?.message}
                />
                <ProfileHeader>Смена почты</ProfileHeader>
                <ProfileText>Для смены почты введите новую почту и пароль от вашего аккаунта. </ProfileText>
                <ProfileText>Инструкция по смене почты будет выслана на ваш текущий почтовый ящик. </ProfileText>
                {/* <InputValidateUseForm
                    title={'Текущая почта'}
                    type={'CurrentMail'}
                    value={userStore?.data?.mail}
                    placeholder={userStore?.data?.mail}
                    disabled={true}
                    active={false}
                // placeholder={userStore?.data?.mail}
                /> */}
                <InputValidateUseForm
                    title={'Новая почта'}
                    type={'mail'}
                    placeholder={''}
                    error={errors?.mail}
                    autocomplete="off"
                    validateSet={register("mail", {
                        required: "Пожалуйста, введите новую почту.",
                        pattern: {
                            value: /^(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#%&*\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{2,63}\.)+[a-zA-Z]{2,15}$/,
                            message: 'неверный формат email',
                        }
                    })}
                />
                <InputValidateUseForm
                    title={'Пароль'}
                    type={'password'}
                    placeholder={''}
                    error={errors?.password}
                    validateSet={register("password", {
                        required: "Пожалуйста, введите пароль.",
                        validate: "Неверный пароль"
                    })}
                />
                <SplitLine />
                <BlueAcceptButton title={!sendRequest ? 'Запросить смену почты' : <Loader style={{ border: "2px solid #ffffff", borderTop: "2px solid transparent" }} />} />
            </ListVertical>
            <MainPopup isOpened={showPopup.status} onClose={() => setShowPopup({ status: false, title: '' })}>
                <p>{showPopup.title}</p>
            </MainPopup>
        </EditProfileForm >
    );
};

export default observer(EmailParametrsList);