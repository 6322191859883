import React, { useEffect, useState } from 'react';
import cl from './QuestionCard.module.css'
import { GreenButton, RedButton } from 'shared/UI/Buttons'; 
import { DefaultWrapper } from 'shared/UI/Wrappers';
import QuestionText from 'features/TestArea/QuestionCards/QuestionText';
import QuestionSelectable from 'features/TestArea/QuestionCards/QuestionSelectable';
import { MainPopup } from 'shared/UI/Popups';
import { ContentHorizontal, ListVertical } from 'shared/UI/Layouts';

const QuestionCard = ({ question, deleteQuestion, index, editAction, AddNewAnswer }) => {
    const [ShowDeletePopup, setShowDeletePopup] = useState(false)
    const [EditQuestion, setEditQuestion] = useState(false)
    const [LocalQuestion, setlocalQuestion] = useState()

    useEffect(() => {
        setlocalQuestion(question)
    }, [question])
    //удаление по индексу
    const DeleteCard = (id) => {
        setShowDeletePopup(false)
        deleteQuestion(id)
    }

    const CancelEUpdate = () => {
        setlocalQuestion(question)
        setEditQuestion(false)
    }

    return (
        <>
            <DefaultWrapper style={{ width: '100%', padding: '40px', borderRadius:' 20px'}}>
                <ListVertical className={cl.questionCard}>

                    {LocalQuestion?.question?.type == "1" ?
                        <QuestionText
                            QuestionIndex={index}
                            isEdit={EditQuestion}
                            question={{ ...LocalQuestion.question, answerOptions: LocalQuestion.answerOptions }}
                            setQuestionData = {setlocalQuestion}
                            EditAction={setEditQuestion}
                            DeleteAction={setShowDeletePopup}
                            AddNewAnswer={AddNewAnswer}
                            CancelEUpdate={CancelEUpdate}

                        /> : null
                    }
                    {LocalQuestion?.question?.type == "2" ?
                        <QuestionSelectable
                            QuestionIndex={index}
                            isEdit={EditQuestion}

                            type='checkbox'
                            question={{ ...LocalQuestion.question, answerOptions: LocalQuestion.answerOptions }}
                            toggleEdit={setEditQuestion}
                            DeleteAction={setShowDeletePopup}
                            AddNewAnswer={AddNewAnswer}
                            CancelEUpdate={CancelEUpdate}
                        /> : null}
                    {LocalQuestion?.question?.type == "3" ?
                        <QuestionSelectable
                            QuestionIndex={index}
                            isEdit={EditQuestion}
                            type='radio'
                            question={{ ...LocalQuestion.question, answerOptions: LocalQuestion.answerOptions }}
                            toggleEdit={setEditQuestion}
                            DeleteAction={setShowDeletePopup}
                            AddNewAnswer={AddNewAnswer}
                            CancelEUpdate={CancelEUpdate}
                        /> : null
                    }
                </ListVertical>
            </DefaultWrapper>
            {/* Окно удаления */}
            <MainPopup
                isOpened={ShowDeletePopup}
                onClose={e => setShowDeletePopup(false)}
                title={'Вы точно хотите удалить вопрос?'}>
                {/* Кнопки  удаления/отмены курса*/}
                <ContentHorizontal>
                    <GreenButton onClick={() => DeleteCard(LocalQuestion.question.id)}>Удалить</GreenButton>
                    <RedButton onClick={() => setShowDeletePopup(false)} > Отменить</RedButton>
                </ContentHorizontal>
            </MainPopup>
        </>

    );
};

export default QuestionCard;