import React from 'react';
import cl from './HomePage.module.css'
import cn from 'classnames'
import { MainWrap } from 'shared/UI/Layouts';
import { DefaultWrapper } from 'shared/UI/Wrappers';
import { PiStudentFill, PiUserFill } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import userStore from 'shared/store/userStore';

const HomePage = (props) => {

    const navigate = useNavigate()
    const redirect = (url) => {
        navigate(url)
    }

    const roleDirect = () => {
        if (userStore.data.role_id === 2) {
            return '/teachercabinete/course/list'
        } else if (userStore.data.role_id === 1) {
            return '/regteacher'
        }
    }

    const roleButton = () => {
        if (userStore.data.role_id === 2) {
            return <h2>Кабинет автора</h2>
        }
        if (userStore.data.role_id === 1) {
            return <h2>Стать автором</h2>
        }
    }

    return (
        <MainWrap
            className={cl.homePage}
        >
            <div className={cl.block}>
                <div className={cl.buttonsWrapper}>
                    <DefaultWrapper className={cl.buttonBig} onClick={() => redirect('/courses/allcourses')}>
                        <div className={cl.textButtonBig}>
                            <h2>Категории курсов</h2>
                            <h3>Поиск курсов по категориям</h3>
                        </div>
                    </DefaultWrapper>
                    <div className={cl.blockManyButton}>
                        <DefaultWrapper className={cl.buttonBig} onClick={() => redirect('/profile/main')}>
                            <div className={cn(cl.textButtonBig, cl.marginText)}>
                                <h2>Личный кабинет</h2>
                                <h3>Настройка учётной записи</h3>
                            </div>
                            <div className={cl.iconButtonBig}>
                                <PiUserFill size={'46px'} color={'#246FFB'} />
                            </div>
                        </DefaultWrapper>
                        <DefaultWrapper className={cl.buttonBig} onClick={() => redirect('/mylearn/mycourses')}>
                            <div className={cn(cl.textButtonBig, cl.marginText)}>
                                <h2>Моё обучение</h2>
                                <h3>Кабинет читателя</h3>
                            </div>
                            <div className={cl.iconButtonBig}>
                                <PiStudentFill size={'46px'} color={'#246FFB'} />
                            </div>
                        </DefaultWrapper>
                    </div>
                    <DefaultWrapper className={cl.buttonBig} onClick={() => redirect(roleDirect())}>
                        <div className={cl.textButtonBig}>
                            {roleButton()}
                        </div>
                    </DefaultWrapper>
                </div>
            </div>
        </MainWrap>
    );
};

export default HomePage;