import { MainWrap } from "shared/UI/Layouts";
import styled from "styled-components";

export const SizedSpan = styled.span`
     font-size: 14px;
`

export const Logo = styled.div`
  width: 100%;
    height: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0px;

    &> img 
    {
        height: 100%;
    }
`
export const Main = styled(MainWrap)`
    background: var(--lightBlue);
    padding: 20px 0 0 0;
`
export const BlockCards = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 100px 0;
    color: var(--darkBlue);
    max-width: 1400px;
`
export const Card = styled.div`
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 20px;
    background: #fff;
    flex: 1;
    position: relative;
    overflow: hidden;
    font-weight: 400;
    box-shadow: 1px 4px 30px #00000010;
    &>p {
        font-size: 24px;
    }
    &>p,  &>span  {
        z-index: 1;
    }
`
export const HorizontalBlockWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    color: var(--darkBlue);
`

export const TitleBlock = styled.div`
    width: 100%;
`

export const List = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const ElementList = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;

    &>h1{ 
        color: #1D6AFF;
        font-size: 40px;
        font-weight: 500;
    }
    &>p{  
        font-size: 24px;
        font-weight: 500;
    }
`
export const Elem = styled.div`
    display: flex;
    flex-direction: column;
    position: unset;
    gap: 10px;
    flex: 1;

`
export const HorizontalBlockWrapElem = styled(Elem)`
    display: flex;
    flex-direction: row;
    gap: 20px;
    color: var(--darkBlue);
`
export const HorizontalBlockRequisites = styled(HorizontalBlockWrapElem)`
     justify-content: space-between;
    display: contents;
`


export const ElemLeft = styled(Elem)`
    display: flex;  
    flex-direction: column;
    gap: 10px;
    max-width: 350px;
`
export const ElemRightAlign = styled(Elem)`
    text-align: right;
`

export const Requisites = styled.div`
    justify-content: space-between;
`
export const RequisitesElem = styled(Elem)`
    justify-content: space-between;
    display: contents;
`
export const SupportElem = styled(Elem)`
      display: flex;
    flex-direction: column;
    gap: 10px;
`
export const SupportCard = styled(Card)`
      display: flex;
    flex-direction: column;
    gap: 10px;
`
export const Label = styled(SizedSpan)`    
    font-weight: 400;
    white-space: nowrap;
    font-size: 24px;
`
export const BoldSpan = styled(SizedSpan)`
    position: unset;
     padding-left: 10px;
    font-size: 24px;
    font-weight: 600;
`
export const BoldSpanUppercase = styled(BoldSpan)`
        padding-left: 10px;
        text-transform: uppercase;

`
/* ----------------------------- */