import ContentHorizontal from "shared/UI/Layouts/ContentHorizontal";
import { DefaultWrapper } from "shared/UI/Wrappers";
import styled from "styled-components";

export const DateHighlighter = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
gap: 10px;
width: 100%;
color: #19305D;
padding-bottom: 20px;
border-bottom: 1px solid #246FFB;

`

export const DateCreation = styled.p`
color: #868686;
font-size: 14px;
line-height: 1;

`
