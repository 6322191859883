import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import './Fonts/Golos/font_golos.css';
import './shared/Fonts/Golos/font_golos.css';
import './shared/Fonts/Mont/font-mont.css';
// import './Fonts/Mont/font-mont.css';
import App from 'app/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<>
   <BrowserRouter>
       <App/>
   </BrowserRouter>
</>
);


reportWebVitals();