import styled from "styled-components";
export const SectionContent = styled.section`
   width: 100%;
   max-width: 1200px;
`
export const CoursesMainWrap = styled.div` 
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

`