import React from 'react';
import { AcceptButton } from 'shared/UI/Buttons';
import { MainPopup } from 'shared/UI/Popups';

const SuccessPopupChange = ({ isOpen, onClose, text, title }) => {
    return (
        <MainPopup
            isOpened={isOpen}
            onClose={e => onClose(false)}
            title={'Внимание!!!'}
            showHeader={true}>
            {text}
            <AcceptButton title={'Отлично'} onClick={() => onClose(false)}></AcceptButton>
        </MainPopup>
    );
};

export default SuccessPopupChange;