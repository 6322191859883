import React, { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { DarkGreenButton, LessonCardHeader, LessonCardHeaderContent, LessonDuration, LessonElement, LessonElementWrap, TestElement, TestLink, TestLinkText, TestLinkWrap } from './styled/MyLearnCourseLessonStyled';
import { RotateShevron } from 'entities/TeacherArea/CourseContentDetailed/styled/CourseContentDetailedStyled';
import { formateTimeSpanToMinute, useDurationTransform } from 'shared/hooks';

const MyLearnCourseLesson = ({ less, LessonIndex, cid, mode, moduleIndex, mid }) => {
    const [ShowTest, setShowTest] = useState(false)
    const TransformTime = useDurationTransform()

    return (
        <LessonElementWrap>
            <LessonElement
                id={`lesson_${moduleIndex}_${LessonIndex}`}
                onClick={(e) => { e.stopPropagation(); setShowTest(!ShowTest) }} >
                <LessonCardHeader>
                    <LessonCardHeaderContent>
                        <h4>
                            <RotateShevron $rotate={ShowTest ? 90 : 0} />
                            Урок №{LessonIndex + 1}: {less.name}
                        </h4>
                        <LessonDuration>
                            <span>Время прохождения: </span>
                            <span>{less.duration} минут</span>
                        </LessonDuration>
                    </LessonCardHeaderContent>
                    <Link
                        to={mode === 'edit'
                            ? `/teachercabinete/course/${cid}/edit/module/${less.mid}/lesson/${less.id}`
                            : `/mylearn/course/${cid}/module/${mid}/lesson/${less.id}`}>
                        <DarkGreenButton onClick={(e => e.stopPropagation())}>
                            <p>{mode === 'edit' ? 'Перейти' : 'К уроку'}</p>
                        </DarkGreenButton>
                    </Link>
                </LessonCardHeader>
            </LessonElement>
            {ShowTest
                ?
                less.surveys[0] ?
                    <TestLinkWrap>
                        <TestLink>
                            <TestLinkText>
                                <p> {`Тест: ${less.surveys[0]?.label}`}</p>
                                <p> {'Длительность: '}
                                    {formateTimeSpanToMinute(less.surveys[0]?.duration) + ' минут'}
                                </p>
                            </TestLinkText>

                            <Link to={mode === 'edit'
                                ? `/teachercabinete/course/${cid}/edit/module/${less.mid}/lesson/${less.id}/survey/${less?.surveys[0]?.id}`
                                : `/mylearn/course/${cid}/module/${less.mid}/lesson/${less.id}/surveyLobby/${less?.surveys[0].id}`
                            }>
                                <DarkGreenButton>
                                    <p style={{ textWrap: 'nowrap' }}>{mode === 'edit' ? 'Изменить' : 'К тесту'}</p>
                                </DarkGreenButton>
                            </Link>
                        </TestLink>

                    </TestLinkWrap>
                    : null

                :
                null}
        </LessonElementWrap>
    );
};

export default MyLearnCourseLesson;