import styled from "styled-components";
import { ColorBtnSample } from "./ColorBtnSampleStyled";


export const RedButton = styled(ColorBtnSample)`
    background: #FCD3D3;
    color: var(--color-red-default);
    &:hover { 
        background: #FAB0B0;
        color: var(--color-red-default);
    }
    &:active {
    background-color: var(--color-red-default);
    color: #fff;
}
`