import { CourseContentDetailed } from 'entities/TeacherArea';
import React from 'react';
import { DefaultWrapper } from 'shared/UI/Wrappers';
import { StudentCoursesModules } from './ui';

const StudentCourseModulesAndLessons = ({ ModulesAndLessons, id }) => {
    return (
        <>
            {ModulesAndLessons.map((module, index) => (
                <CourseContentDetailed
                    module={module}
                    index={index}
                    key={`module_${module.id}_${index}`}
                    cid={id} />
            ))}
        </>
    );
};

export default StudentCourseModulesAndLessons;