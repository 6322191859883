
import { BlueButton, RedButton } from "shared/UI/Buttons";
import SimpleImg from "shared/UI/Images/SimpleImg/SimpleImg";
 import { ContentHorizontal, ListVertical, ListVerticalCenter } from 'shared/UI/Layouts';
import { DarkGreenButton } from "features/TeacherArea.jsx/MyLearnCourseLesson/styled/MyLearnCourseLessonStyled";
import { SpacedApartWrap } from "shared/UI/Wrappers";
import styled from "styled-components";

export const UnderlineDiv = styled.div`
    padding-bottom:30px;
    border-bottom:  1px solid #DADADA ;   
`
export const QuestionTextStyled = styled(SpacedApartWrap)`
    padding-bottom:  ${props => (props.$ShowUnderLine == null || props.$ShowUnderLine == false) ? '30px' : null}   ;
    border-bottom: ${props => (props.$ShowUnderLine == null || props.$ShowUnderLine == false) ? '1px solid #DADADA' : null}   ;
    font-size: 24px;
    font-weight: 600;
    flex-direction: row;
    gap: 20px;

`
export const QuestionImgStyled = styled(SimpleImg)`
    padding-bottom:30px;
    border-bottom:  1px solid #DADADA ; 
`
export const RedQuestionBtn = styled(RedButton)`
    background-color: #FF5F5F;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    padding: 18px ;  
    line-height: 100%;
    color: #fff;
    &:hover{
        background-color: #FF8C8C;
        color: #fff
    }
`

export const BlueQuestionButton = styled(BlueButton)`
    background-color: #DAE8FF;
    line-height: 100%;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 18px; 
    color: #163C85;   
    &:hover{
        background-color: var(--base-hover-blue-color)
    }
`
export const DarkGreenQuestionButton = styled(DarkGreenButton)`
    line-height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 18px;
    width: 100%;
`

export const RedAnswerBtn = styled(RedButton)`
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 10px 25px ;
    width: fit-content;
    line-height: 100%;
    border-radius: 14px;
    font-weight: 600;
`
export const AnswerOptionInputStyled = styled(ContentHorizontal)`
    border-radius: 1px;
    padding: 20px;   
    transition: 0.3s linear ;  
`

