import { SwitchButton } from 'shared/UI/Buttons/SwitchButton/SwitchButton.styled';
import styled from 'styled-components';

export const BlueWrap = styled(SwitchButton)`
width: 100%;
display: flex;
justify-content: ${props => props.center || 'center'};
background-color: #A7C5FD;
background: ${props => props.$active ? '#A7C5FD' : '#E8F1FF'};
`
export const BlueWrapSBCenter = styled(BlueWrap)`
justify-content: space-between;
padding: 10px 20px;
transition: background 0.2s linear;
&:hover {
    background-color: var(--base-hover-blue-color);
    
}
&:hover > p {
    text-decoration: underline;

}
`