export const link_project_docs = {
    policy_pdn: process.env.REACT_APP_POLICY_PDN,
    descript_function: process.env.REACT_APP_DESCRIPTION_FUNCTION,
    install_instruction: process.env.REACT_APP_INSTALL_INSTRUCTION,
    user_guide: process.env.REACT_APP_USER_GUIDE,
    user_policy: process.env.REACT_APP_USER_POLICY
}

export const host = process.env.REACT_APP_API_URL

export const vks = process.env.REACT_APP_VKS_URL

export const ws = process.env.REACT_APP_WS_URL;