import React, { cloneElement, useCallback, useEffect, useRef, useState } from 'react';
import { DescriptionWrap } from './Styled';
import useResizeObserver from 'shared/hooks/useResizeObserver';

const DescriptionElement = ({ descriptionText, hiddenHeight, stringCount, CustomButton }) => {
    const [ShowAll, setShowAll] = useState(false)
    const [ShowBtn, setShowBtn] = useState(false)
    const [height, setHeight] = useState(null)
    const [LimitOutput, setLimitOutput] = useState(false)
    const [AllowRessize, setAllowResize] = useState(true)


    useEffect(() => {
        setLimitOutput(false)
        setAllowResize(true)
    }, [descriptionText])

    const DescriptionRef = useRef(null)

    useEffect(() => {
        if (AllowRessize) {
            if (height != null && DescriptionRef?.current != null) {
                if (height >= hiddenHeight) {
                    setLimitOutput(true)
                    setShowBtn(true)
                } else {
                    setLimitOutput(false)
                    setShowBtn(false)
                }
                setAllowResize(false)
            }
        }
    }, [height])

    const heightArea = useCallback((target) => {
        setHeight(target?.offsetHeight)
    }, [descriptionText])

    const ref = useResizeObserver(heightArea)

    const ToggleShow = () => {
        ShowAll == false ? setLimitOutput(false) : setLimitOutput(true);
        setShowAll(!ShowAll);
    }

    return (
        <>
            <DescriptionWrap
                $full={ShowAll}
                ref={DescriptionRef}
                style={{ WebkitLineClamp: LimitOutput == false ? 'unset' : `${stringCount}` }}
            >
                <p ref={ref}>{descriptionText}</p>
            </DescriptionWrap>

            {ShowBtn
                ?
                CustomButton ?
                    cloneElement(CustomButton, {
                        onClick: () => ToggleShow()
                    }) :
                    <p style={{ cursor: 'pointer', color: 'var(--base-green-color)' }}
                        onClick={() => ToggleShow()}>
                        {ShowAll ? 'Скрыть' : 'Показать ещё'}
                    </p>
                : null}
        </>

    );
};

export default DescriptionElement;