import React, { useEffect, useState } from 'react';
import { api } from 'app/api';
import { EmptyFile } from 'shared/UI/Fillers';
import { BlueButton, RedButton } from 'shared/UI/Buttons';
import { host } from 'app/globals';
import { BtnsWrap, ChangePhotoInput, CourseSmallImg, ImgPreview, InputButtonLabel } from './ui';

const CourseImgChanger = ({ id, EditAloow, DefaultCoursData, setDefaultCoursData, updatePhotoAction, RemovePhotoAction }) => {
    const [DisplayImg, SetDisplayImg] = useState()
    useEffect(() => {
        DefaultCoursData?.previewImg &&
            fetch(`${host}/api/GetFile/${DefaultCoursData.previewImg}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
                .then(response => response.status == 200 && response.blob(response)
                )
                .then(blob => {
                    if (blob) {
                        SetDisplayImg(URL.createObjectURL(blob))
                    } else {
                        SetDisplayImg(EmptyFile)
                    }
                })


    }, [DefaultCoursData.fileData])
    const uploadPhoto = async (event) => {
        var file = event.target.files;
        var f = file[0];
        var prevUrl = URL.createObjectURL(f)
        SetDisplayImg(prevUrl)
        const formData = new FormData();
        formData.append('file', f);
        const resUpload = await api.post('/uploadfile', formData)
        updatePhotoAction({ ...DefaultCoursData, PreviewImg: await resUpload.data.id, previewImg: await resUpload.data.id })
    };

    const RemovePhoto = (e) => {
        RemovePhotoAction()
        updatePhotoAction({ ...DefaultCoursData, PreviewImg: null, previewImg: null })
        SetDisplayImg(EmptyFile)
    }

    return (
        <ChangePhotoInput>
            <ImgPreview>
                <CourseSmallImg src={DisplayImg ?? EmptyFile} alt="" />
            </ImgPreview>
            {EditAloow && <BtnsWrap>
                <input
                    id={'addPhoto' + id} type={'file'}
                    onChange={(e) => uploadPhoto(e)}
                    accept='image/jpeg,image/png'
                />
                <div style={{ height: '38px', display: 'flex', gap: ' 10px', width: '100%' }}>
                    <BlueButton style={{ maxWidth: '50%' }}>
                        <InputButtonLabel htmlFor={'addPhoto' + id}>
                            <span>Загрузить</span>
                        </InputButtonLabel>
                    </BlueButton>
                    <RedButton
                        style={{ maxWidth: '50%' }}
                        onClick={e => RemovePhoto(e)}
                    >
                        Удалить
                        {/* <HiTrash /> */}
                    </RedButton>
                </div>
            </BtnsWrap>
            }
        </ChangePhotoInput>
    );
};

export default CourseImgChanger;