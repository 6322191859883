import { makeAutoObservable } from "mobx"

class mobxState {
    ElementsList = []
    ElementsGrid = 'tiles'
    FiltersToFetch = []
    DefaultFilters = []
    SearchValue = '%%'
    TotalCountOfElements = 0
    ElementsOnPage = 100
    CountOfPage = 0     
    CurrentPage = 0
    OrderParams = { parametr: 'label', direction: 'asc' }
    CheckBoxListFilters = []

    constructor() {
        makeAutoObservable(this)
    }
    
    filterElementsList(parametr, direction, listToFilter) {
        direction === 'up'
            ?
            listToFilter.ElementsList.sort((a, b) => a[parametr] > b[parametr] ? 1 : -1)
            :
            listToFilter.ElementsList.sort((a, b) => a[parametr] > b[parametr] ? -1 : 1)
    }
    setTotalCountOfElements(result) {
        this.TotalCountOfElements = result;
        this.CountOfPage = (Math.ceil(result / this.ElementsOnPage))
    }

    setElementsOnPage(result) {
        this.ElementsOnPage = result;
        this.CountOfPage = (Math.ceil(this.TotalCountOfElements / result))
    }

    setOrderParams(parametr, direction) {
        this.OrderParams = { parametr: parametr, direction: direction }
    }

    ResetDefaultFilters() {
        this.FiltersToFetch = this.DefaultFilters
    }

    SetData(object) {
        object.ElementsList && (this.ElementsList = object.ElementsList)
        object.ElementsGrid && (this.ElementsGrid = object.ElementsGrid)
        object.FiltersToFetch && (this.FiltersToFetch = object.FiltersToFetch)
        object.DefaultFilters && (this.DefaultFilters = object.DefaultFilters) && (this.FiltersToFetch = object.DefaultFilters)
        object.SearchValue && (this.SearchValue = object.SearchValue)
        object.CurrentPage && (this.CurrentPage = object.CurrentPage)
        object.TotalCountOfElements && (this.TotalCountOfElements = object.TotalCountOfElements)
        object.ElementsOnPage && (this.ElementsOnPage = object.ElementsOnPage)
        object.CountOfPage && (this.CountOfPage = object.CountOfPage)
        object.CheckBoxListFilters && (this.CheckBoxListFilters = object.CheckBoxListFilters)
    }
}

export default new mobxState