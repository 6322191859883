import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
import { usePassEncrypt } from 'shared/hooks';
import { api } from 'app/api';
import { AcceptButton } from 'shared/UI/Buttons';
import { MainPopup } from 'shared/UI/Popups';
import { ListVertical, MainWrap } from 'shared/UI/Layouts';
import { DefaultWrapper } from 'shared/UI/Wrappers';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import useDeviceInfomation from 'shared/hooks/AuthHooks';
import { EditProfileForm } from '../PersonalInormation/styled/ProfileStyles'; //повторяеn файлы в wigets


const PasswordRecovery = () => {
    const [SendOnMail, setSendOnMail] = useState(false)
    const [redirect, setRedirect] = useState(null)
    const [linkError, setLinkError] = useState(false)
    // const [success, setSuccess] = useState(location.state)
    const { link } = useParams()
    const device = useDeviceInfomation()
    const location = useLocation();
    const { register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors }
    } = useForm({ mode: 'OnInput' });

    const passEncrypt = usePassEncrypt()

    const SetNewPassWord = (data) => {
        setSendOnMail(true);
        data.newpassword !== data.newPasswordConffirm
            ? setError('newPasswordConffirm', { validate: '' })
            : (() => {
                // ---------------------------------------
                api.post(`/user/confirmation/${location.pathname.split('/').pop()}`, {
                    password: passEncrypt(data.newpassword),
                    device_type_id: device.type,
                    platform: '123',
                    geo: device.geo == {} ? "" : JSON.stringify(device.geo),
                    system: device.system
                })
                    .then(result => { })
                    // setRedirect(`/confirmation/SuccessRecovery`))
                    .catch(error => {
                        console.log(error)
                        if (error.response.status === 401) {
                            setError('password', { type: 'custom', message: 'Неверный пароль' });
                            setLinkError({ message: 'Неверный пароль' })
                        }
                        if (error.response.status === 403) {
                            setError('password', { type: 'custom', message: 'Недействительная ссылка' });
                            setLinkError({ message: 'Недействительная ссылка' })
                        }
                    })
            })()
    }
    return (
        // <DefaultWrapper width={'100%'}></DefaultWrapper>
        redirect !== null ?
            <Navigate to={'/login'} state={{ change: 'success', message: 'Ваш пароль успешно сброшен, пожалуйста авторизуйтесь' }} />
            :
            <MainWrap width={'100%'}>
                <DefaultWrapper>
                    <EditProfileForm onSubmit={handleSubmit(SetNewPassWord)} >
                        <ListVertical className={'account_Data'}>
                            <h3>Введите новый пароль</h3>
                            <InputValidateUseForm
                                title={'Новый пароль'}
                                type={'password'}
                                error={errors?.newpassword}
                                validateSet={register("newpassword", {
                                    required: "Пожалуйста введите новый пароль.",
                                    // validate: value => value == watch('newPasswordConffirm') || 'пароли не ссвпадают' 
                                })}
                            />
                            <InputValidateUseForm
                                title={'Подтверждение пароля'}
                                type={'password'}
                                error={errors?.newPasswordConffirm}
                                validateSet={register("newPasswordConffirm", {
                                    required: "Пожалуйста введите новый пароль ещё раз",
                                    validate: value => value == watch('newpassword') || 'пароли не совпадают'
                                })}
                            />
                            <AcceptButton type={'submit'} title={'Сменить'} />
                        </ListVertical>
                    </EditProfileForm>
                    <MainPopup
                        isOpened={SendOnMail}
                        onClose={e => setSendOnMail(false)}
                        title={'!!!'}
                        showHeader={true}>

                        {linkError ?
                            <>
                                {linkError.message}
                                <AcceptButton title={'Ок'} onClick={() => { setSendOnMail(false); setRedirect(`/login`) }}>
                                </AcceptButton></>
                            : <>
                                Пароль успешно сменён
                                <AcceptButton title={'Выполнить вход'} onClick={() => { setSendOnMail(false); setRedirect(`/login`) }}></AcceptButton>
                            </>
                        }


                    </MainPopup>
                </DefaultWrapper >
            </MainWrap >
    );
};
export default PasswordRecovery;