import { api } from 'app/api';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Outlet, useParams } from 'react-router-dom';
import SurveyInfoCard from 'entities/StudingArea/SurveyInfoCard/SurveyInfoCard';
import SurveyTrysInfoPopup from 'entities/StudingArea/SurveyTestPopup/SurveyTrysInfoPopup';
import SurveyContinuePopup from 'entities/StudingArea/SurveyContinuePopup/SurveyContinuePopup';

const TestPrev = () => {
    const { id_course, id_module, id_lesson, id_test } = useParams()
    const [TestParam, setTestParam] = useState({})
    const [TrysDate, setTrysDate] = useState(null)
    const [ShovPrev, setShovPrev] = useState(false)
    const [tryExist, setTryExist] = useState(false)

    useEffect(() => {
        if (id_test !== undefined) {
            api.get(`Survey/Info/${id_test}`)
                .then(result => setTestParam(result.data.survey))//получение параметров теста
            api.get(`Survey/SurveyTry/${id_test}`)//получение попыток теста
                .then(result => {
                    if (result.status == 200) {
                        localStorage.removeItem('SurveyCurrentTry')
                        const TTrysLength = result.data.length
                        if (TTrysLength !== 0) {
                            const lastTryStatus = result?.data[result.data.length - 1]?.ended
                            const lastId = result?.data[result?.data?.length - 1]?.id
                            !lastTryStatus && localStorage.setItem('SurveyCurrentTry', lastId) // не завершено   
                        }
                        setTrysDate(result.data)
                    }
                })
        }
    }, [id_test])

    const CreateNewTry = () => {
        api.post(`Survey/CreateSurveyTry/${id_test}`)
            .then((result) => {
                if (result.status == 200) {
                    localStorage.setItem('SurveyCurrentTry', result.data.id) // установка  id попытки в хранидище
                    window.location.assign(`/mylearn/course/${id_course}/module/${id_module}/lesson/${id_lesson}/survey/${id_test}/pass`); // переход нв попытку теста
                }
            })
    }

    const TryCheck = () => {
        const lstTry = localStorage.getItem('SurveyCurrentTry')
        if (lstTry == null || lstTry == undefined) {
            CreateNewTry()
        } else {
            const getTryTime = async () => {
                const survey = await api.get(`Survey/Info/${id_test}`)
                var TryTime = null
                try {
                    TryTime = await api.post(`Survey/TimeSurveyTry/${lstTry}`, { id: lstTry })
                } catch (error) {
                    CreateNewTry()
                }
                const CurrentTryTime = Math.floor((Date.parse(TryTime.data)) / 1000)
                const TestDuration = Math.floor(survey.data.survey.duration)
                const CurrentTime = Math.floor(Date.parse(new Date()) / 1000)
                {
                    if (CurrentTryTime + TestDuration < CurrentTime) {
                        CreateNewTry()
                    } else {
                        setTryExist(true)
                    }
                }
            }
            getTryTime()
        }
    }
    return (
        <>
            <SurveyInfoCard
                TestParam={TestParam}
                TrysDate={TrysDate}
                setShovPrev={setShovPrev}
                TryCheck={TryCheck}
            />
            <SurveyTrysInfoPopup
                isOpened={ShovPrev}
                onClose={setShovPrev}
                TrysDate={TrysDate}
                TestParam={TestParam}
            />

            <SurveyContinuePopup
                id_test={id_test}
                isOpened={tryExist}
                onClose={setTryExist}
                CreateNewTry={CreateNewTry}
            />   
        </>
    );
};

export default TestPrev;