import React, { useEffect, useState, useRef } from 'react';
import cl from './OptionAnswer.module.css'
import SimpleImg from 'shared/UI/Images/SimpleImg/SimpleImg';
import { host } from 'app/globals';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ContentHorizontal, ListVertical, ListVerticalStart } from 'shared/UI/Layouts';
import { api } from 'app/api';
import { RedButton } from 'shared/UI/Buttons';
import { AiOutlineUpload } from "react-icons/ai";
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
// import { AnswerOptionInputStyled, RedAnswerBtn, RedQuestionBtn } from '../../../styled/QuestionTextStyled';
import { AnswerOptionInputStyled, RedAnswerBtn } from 'shared/UI/Buttons/OtherButtons/QuestionTextStyled';
import { MyInput } from 'shared/UI/Inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImgUploadQuestion } from './styled/ImgUpload.styled';
import { HiOutlineTrash } from 'react-icons/hi';
const OptionAnswer = ({ element, imgAnswers, type, isEdit = false, editAction, index, EditRightAnswer, DeleteAnswer, questionIndex, setEditErrors }) => {
    const [Image, setImg] = useState(null)
    const clearValue = useRef("")

    const { register,
        reset,
        watch,
        formState: { errors }
    } = useForm({ mode: 'onTouched' });

    useEffect(() => {
        if (element.imgId != null) {
            fetch(`${host}/api/GetFile/${element.imgId}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
                .then(response => response.status === 200 && response.blob(response))
                .then(blob => {
                    if (blob) {
                        const fileURL = URL.createObjectURL(blob)
                        setImg(fileURL)
                    }
                })
        }
        else {
            setImg(null)
        }
        reset({ textAnswer: element.textAnswer })
    }, [element])

    useEffect(() => {
        errors.textAnswer ? setEditErrors(true) : setEditErrors(false)
        // editAction({ ...element, textAnswer: watch('textAnswer') }, index)
    }, [errors.textAnswer])

    const uploadPhoto = async (event) => {
        var file = event.target.files;
        console.log(file)
        const formData = new FormData();
        formData.append('file', file[0]);
        const resUpload = await api.post('/uploadfile', formData)
        editAction({ ...element, imgId: await resUpload.data.id }, index)
    }

    const saveAnswer = (answerData) => {
        editAction({ ...element, textAnswer: answerData.textAnswer }, index)
    }

    const removeAnswerImage = (PhotoId) => {
        setImg(null)
        api.delete(`deletefile/${PhotoId}`)
            .then(() => {
                editAction({ ...element, imgId: null }, index)
                clearValue.current.value = null
                console.log('b-', clearValue.current.files)
            },
                (error) => { }
            )
    }
    return (<>
        {
            isEdit
                ?
                <ListVertical>
                    <ContentHorizontal className={cl.questionAnswer}>
                        {type == 'checkbox' ?
                            <>
                                <input style={{ paddingLeft: '10px', transform: 'scale(1.5)', cursor: 'pointer' }}
                                    disabled={!isEdit}
                                    type={'checkbox'}
                                    checked={element?.right}
                                    onChange={e => editAction({ ...element, right: e.target.checked }, index)}
                                />
                            </>
                            : null}
                        {type == 'radio' ?
                            <>
                                <input
                                    style={{ paddingLeft: '10px', transform: 'scale(1.5)', cursor: 'pointer' }}
                                    disabled={!isEdit}
                                    type={'radio'}
                                    name={`q_${questionIndex}`}
                                    checked={element?.right}
                                    onChange={e => editAction({ ...element, right: e.target.checked }, index)}
                                />
                            </>
                            : null
                        }
                        <InputValidateUseForm
                            type={'text'}
                            error={errors?.textAnswer}
                            validateSet={register("textAnswer", {
                                required: "Пожалуйста, введите текст ответа",
                                onChange: (e) => { editAction({ ...element, textAnswer: watch('textAnswer') }, index) },
                            })}
                        />
                        <RedAnswerBtn type={'button'} onClick={() => DeleteAnswer(element.id, index)}> Удалить</RedAnswerBtn>
                    </ContentHorizontal >
                    { //Блок загрузки картинки на ответ
                        imgAnswers &&
                        <>
                            <ContentHorizontal className={cl.questionAnswerImg}>
                                {Image ?
                                    <SimpleImg
                                        src={Image}
                                        maxW={'200px'}
                                    />
                                    :
                                    null}
                                <ListVerticalStart>
                                    <label htmlFor={`addImg_${index}`}//// className={className}

                                    >
                                        <p className={cl.uploadImg} >
                                            <AiOutlineUpload />
                                            {Image == null ? 'Добавить изображение' : 'Заменить изображение'}
                                        </p>
                                        <MyInput
                                            refInput={clearValue}
                                            id={`addImg_${index}`}
                                            type="file"
                                            onChange={uploadPhoto}
                                            accept='image/jpeg,image/png'
                                        />
                                    </label>
                                    {Image !== null
                                        ?
                                        <p className={cl.removeImg} onClick={() => { removeAnswerImage(element.imgId) }}> <HiOutlineTrash /> Удалить изображение</p>
                                        : null
                                    }
                                </ListVerticalStart>
                                {/* <input
                                className={cl.InputImg}
                                type={'file'}
                                accept='image/jpeg,image/png'
                                onChange={(e) => uploadPhoto(e)}

                            /> */}
                            </ContentHorizontal >
                        </>
                    }
                </ListVertical>
                :
                <AnswerOptionInputStyled className={cl.questionAnswer}>
                    <>
                        <input
                            disabled={!isEdit}
                            type={type}
                            checked={element.right}
                            style={{ transform: 'scale(1.5)' }}
                        />
                        {imgAnswers == true ?
                            Image == null ? null
                                :
                                <SimpleImg
                                    src={Image}
                                    maxW={'200px'}
                                />
                            : null}
                    </>
                    {element?.textAnswer}
                </AnswerOptionInputStyled>
        }
    </>
    );
};

export default OptionAnswer;