import { MySelect } from 'shared/UI/Selects';
import cl from './SortList.module.css';
import React, { useEffect, useState } from 'react';
import mobxState from 'pages/TutorsMarket(Unused)/store/mobxState';
import { observer } from 'mobx-react-lite';
import useGetFitredData from '../../API/useGetElemsByFilters';
import { DefaultWrapper } from 'shared/UI/Wrappers';

const CoursesSortTeacher = observer(({ parametrsList, urlFilterByParams }) => {
    const [Selected, setSelected] = useState({ parametr: 'label', direction: 'asc' })
    const GetFitredData = useGetFitredData(urlFilterByParams)

    useEffect(() => {
        const getListAwait = async () => {
            var result = await GetFitredData({
                page: mobxState.CurrentPage,
                order: Selected.parametr,
                direction: Selected.direction,
            })

            mobxState.SetData({ ElementsList: await result.data })
            mobxState.setTotalCountOfElements(result.headers['x-total-count'])
        }
        if (Object.keys(mobxState.FiltersToFetch).length === 9 && Selected.parametr !== null && Selected.direction !== null) {
            getListAwait()
        }
    }, [Selected, mobxState.FiltersToFetch]);

    return (
        <DefaultWrapper>
            <MySelect
            className={cl.select}
                name="education_rank_id"
                current={'1'}
                onChange={(e) => setSelected({
                    ...Selected,
                    parametr: parametrsList[Number(e.target.value) - 1].parametr,
                    direction: parametrsList[Number(e.target.value) - 1].direction
                })}
                selectlist={parametrsList}
                selectedvalue='name'
            />
        </DefaultWrapper>
    );
});
export default CoursesSortTeacher;