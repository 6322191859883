import styled from "styled-components";

import ContentHorizontal from "shared/UI/Layouts/ContentHorizontal";



export const ProfileTextBold = styled.p`   
    font-size  : 14px; 
    font-weight: 500;
    color: #163C85;
`
export const ChangerWrap = styled(ContentHorizontal)`   
  background-color: #E8F1FF;
  border-radius: 16px;
  justify-content: space-between;
  width: 100%;
  padding: 18px 20px;
  &>a{width: fit-content}
  `