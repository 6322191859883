import React, { useEffect, useState } from 'react';
import cl from './CardCours.module.css'
import { Link } from 'react-router-dom';
import { ModalSmall } from 'shared/UI/Modals';
import WrapperNoShadow from 'shared/UI/Wrappers/WrapperNoShadow';
import { api } from 'app/api';
import { EmptyFile } from 'shared/UI/Fillers';
import { ReviewsCounter } from 'shared/UI/Other';
import { BlueButton } from 'shared/UI/Buttons';
import { MainPopup } from 'shared/UI/Popups';
import BuyConfirmCard from '../BuyConfirmCard/BuyConfirmCard';
import BuyRequest from 'features/BuyRequest/BuyRequest';

const CardCours = ({ item, cardStyle }) => {
    const [Photo, setPhoto] = useState(null)
    const [ShortMenu, setShortMenu] = useState(false)
    const [AlreadyBuy, setAlreadyBuy] = useState(false)
    const [ShowBuyPopup, setShowBuyPopup] = useState(false)
    const [Favorite, setFavorite] = useState(false)

    useEffect(() => {
        item?.favorite &&
            setFavorite(item?.favorite)
        if (item) {
            const img = document.createElement('img');
            if (item.mimeType == null && item.fileData == null) {
                img.src = EmptyFile
            } else {
                img.src = 'data:' + item.mimeType + ';base64,' + item.fileData;
            }
            setPhoto(img.src)

        }

    }, [item])

    const CheckBuy = (id) => {
        setShortMenu(false)
        api.get('/Course/cupil/' + id)
            .then(result => {
                !result.data
                    ? setShowBuyPopup(true)
                    : setAlreadyBuy(true)
            })
    }



    return (
        <div className={cl.courseCard}>
            <div className={cl.main}>
                <div className={cl.Image} >
                    <img src={Photo} alt='preview' />
                </div>
                <div className={cl.Content} >
                    <h2 className={cl.CourseLabel}>
                        {item.label}
                    </h2>
                    <ReviewsCounter />
                    <div className={cl.EducationArea}>
                        <h4>Предмет: {item.courseSubject.subject}</h4>
                        <p className={cl.Duration} >Продолжительность  {item.duration} часов</p>
                    </div>
                    <span className={cl.fullDescription}>
                        {item.description}
                    </span>
                </div>
                <div className={cl.Buttons} >
                    <div className={cl.BuyAndFavorite}>
                        <div className={cl.price}>
                            <p>{item?.price > 0 ? item?.price + "Р" : 'бесплатно'}</p>
                        </div>
                    </div>
                    <WrapperNoShadow>
                        {item.isPurchased !== false
                            ?
                            <button disabled={true} className={cl.byBtn}>Куплено</button>
                            :
                            <button onClick={e => CheckBuy(item.id)} className={cl.byBtn}>{item?.price > 0 ? "Оставить заявку" : 'Оставить заявку'}</button>
                        }
                    </WrapperNoShadow>
                    <BlueButton className={cl.detailBtn}>
                        <Link to={'/courses/coursedetail/' + item?.id}>
                            Подробнее
                        </Link>
                    </BlueButton>
                </div>
            </div>

            {/* {//TODO вернуть всплывашку когда пояится платёжка} */}
            <MainPopup
                isOpened={ShowBuyPopup}
                onClose={e => setShowBuyPopup(false)}
                title='Заявка'
                className={cl.buyPopup}
            >
                <BuyRequest
                    c_id={item.id}
                    endAction={() => setShowBuyPopup(false)}
                />
                {/* <BuyConfirmCard
                    item={item}
                    buyAction={e => setAlreadyBuy(false)}
                    Photo={Photo}
                /> */}
            </MainPopup>
        </div>
    );
};

export default CardCours;