import styled from "styled-components";
import ContentHorizontal from "..";

export const ContentHorisontalCenter = styled(ContentHorizontal)`
    justify-content: center;
`
export const ContentHorisontalSB = styled(ContentHorizontal)`
    justify-content: space-between;
`
export const ContentHorisontalStart = styled(ContentHorizontal)`
    justify-content: flex-start;
`