import React from 'react';
import cl from './loader.module.css'
import cn from 'classnames'

const Loader = ({ className, ...props }) => {
    return (
        <div className={cn(cl.loader, className)} id={'loader'} {...props}>
        </div>
    );
};

export default Loader;