import React from 'react';
import cl from './CustomTable.module.css'
import { TextRecovery } from 'shared/hooks';
import { json } from 'react-router-dom';

const TableOutput = ({ data }) => {
    const replaceContent = (content) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = content
        return tempDiv
    }


    const columnNames = data.content[0];
    return (
        <table>
            {
                <thead>
                    <tr style={{ display: 'flex', justifyContent: 'center' }}>
                        {columnNames?.map((columnName, index) =>
                            data.withHeadings ?
                                <th style={{ width: '100%', wordBreak: 'break-word' }}
                                    className={cl.tableHead}
                                    key={index} >{TextRecovery(columnName)}
                                </th>
                                :
                                <td style={{ width: '100%', wordBreak: 'break-word' }}
                                    className={cl.tableRow}
                                    key={index} >{TextRecovery(columnName)}
                                </td>
                        )}
                    </tr>
                </thead>
                // :
                // null
            }
            <tbody>

                {
                    data?.content.map((row, index) => (
                        index !== 0 ?
                            <tr style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center' }}>
                                {
                                    Array.isArray(row) && row.length > 1 &&
                                    row.map((columnValue, i) =>
                                        <td 
                                    dangerouslySetInnerHTML={{ __html: columnValue }} //опасный параметр
                                            className={cl.tableRow}
                                            style={{ width: '100%', wordBreak: 'break-word' }}>
                                            {/* {TextRecovery(columnValue)} */}
                                        </td>)
                                }
                            </tr>
                            :
                            null
                    ))
                }
            </tbody>
        </table>



    );
};

export default TableOutput;

