import React, { useState, useCallback, useEffect } from 'react';
import useResizeObserver from 'shared/hooks/useResizeObserver';
// import CardControl from './Components/CardControl';
import { MdOutlineAddCard } from "react-icons/md";
import { MainPopup } from 'shared/UI/Popups';

import { api } from 'app/api';
import Slider from 'features/PersonalArea/PersonalPayments/Slider';
import CardPay from 'entities/PersonalArea/PersonalPayments/CardPay';
import NewCardForm from 'entities/PersonalArea/PersonalPayments/NewCardForm/NewCardForm';
import { NewCardWrap } from './styled/NewCardWrap.styled';

const PaymentsCards = () => {
    const [payments, setPayments] = useState(null)
    const [width, setWidth] = useState()
    const [sizeCard, setSizeCard] = useState()
    const [selectAllCard, setSelectAllCard] = useState(false)
    const [showNewCard, setShowNewCard] = useState(false)
    const [inputNewCard, setInputNewCard] = useState({ number: 0, bank: '', isPrimary: false, system: 'мир' })
    const [primaryCard, setPrimaryCard] = useState({})
    const [notPrimaryMethods, setNotPrimaryMethods] = useState([])
    useEffect(() => {
        api.get('GetCard').then((res) => { setPayments({ ...payments, paymentMethod: res.data }) })
    }, [])

    useEffect(() => {
        setPrimaryCard(payments?.paymentMethod?.find(f => f.isPrimary))
        setNotPrimaryMethods(payments?.paymentMethod?.filter(f => !f.isPrimary))
    }, [payments?.paymentMethod, primaryCard])

    //Получуние размеров и запись в стейт
    const widthSlider = useCallback((target) => {
        setWidth(target?.offsetWidth)
    }, [sizeCard?.offsetWidth, payments])

    const getSizeCard = useCallback((target) => {
        setSizeCard(target)
    }, [notPrimaryMethods])

    //Сбор элементов ref для вычисления размеров
    const ref = useResizeObserver(widthSlider)
    const cardNode = useResizeObserver(getSizeCard)

    const getDynamicWidth = () => {
        if (width) {
            const proportion = 5
            return width / proportion
        }
    }

    const deleteCard = (target) => {
        api.delete('/removePay/' + target.id)
            .then(() => {
                setNotPrimaryMethods(prev => { return prev.filter(f => f.id === target.id) })
                setPayments(prev => {
                    return (
                        {
                            ...prev, paymentMethod: prev?.paymentMethod?.filter(f => f !== target)
                        }
                    )
                })
            })
            .catch((err) => console.log(err))
    }

    const switchPrimaryCard = (target) => {
        api.post('/isPrimary/' + target.id)
        setPayments(prev => {
            return (
                {
                    ...prev, paymentMethod: prev.paymentMethod?.map(e => {
                        if (e.id !== target.id && e.isPrimary) {
                            e.isPrimary = false
                        } else if (e.id === target.id && !e.isPrimary) {
                            e.isPrimary = true
                        }
                        return e
                    })
                })
        })
    }

    const addCard = (data) => {
        const newCard = {
            bank: data.bank,
            cardMask: data.number,
            isPrimary: inputNewCard.isPrimary,
            system: inputNewCard.system
        }
        api.post('/AddCard', { ...newCard }).then((result) => {
            const newData = {
                ...newCard,
                id: result.data
            }
            setPayments(prev => {
                return ({
                    ...prev, paymentMethod: [newData,
                        ...prev?.paymentMethod
                    ]
                })
            })
            setShowNewCard(false);
        })
            .catch((error) => console.log(error))
    }

    const deletePrimary = () => {
        setPrimaryCard(prev => prev.isPrimary = false)
    }
    return (
        <>
            <Slider
                sort={(a, b) => Number(b.isPrimary) - Number(a.isPrimary)}
                sizeCard={sizeCard}
                widthSlider={width}
                innerRef={ref}
                items={notPrimaryMethods}
                editional={
                    <div style={{ height: sizeCard?.offsetHeight > 0 && sizeCard?.offsetHeight + 'px', display: 'flex', gap: '5px', alignItems: 'center', margin: '0 5px' }}>
                        <NewCardWrap
                            as={'button'}
                            height={sizeCard?.offsetHeight}
                            width={sizeCard?.offsetWidth}
                            onClick={() => setShowNewCard(true)}
                        >
                            <MdOutlineAddCard />
                        </NewCardWrap>
                        {
                            primaryCard?.isPrimary ?
                                <CardPay
                                    isPrimary={primaryCard?.isPrimary}
                                    bank={primaryCard?.bank}
                                    numberCard={primaryCard?.cardMask}
                                    system={primaryCard?.system}
                                    deleteCard={deletePrimary}
                                />
                                : null
                        }
                        <div style={{ height: '46px', width: '3px', backgroundColor: '#8C8C8C', borderRadius: '10px' }}></div>
                    </div>
                }
                map={(e) =>
                    <CardPay
                        isPrimary={e.isPrimary}
                        setPrimary={() => switchPrimaryCard(e)}
                        deleteCard={() => deleteCard(e)}
                        select={selectAllCard}
                        whRef={cardNode}
                        width={getDynamicWidth()}
                        bank={e.bank?.toLowerCase()}
                        numberCard={e.cardMask}
                        system={e.system}
                        key={e.id}
                    />
                }
            />
            <MainPopup
                isOpened={showNewCard}
                onClose={() => setShowNewCard(false)}
                title={'Добавление карты'}
            >
                <NewCardForm
                    addCard={addCard}
                    inputs={inputNewCard}
                    setInputs={setInputNewCard}
                />
            </MainPopup>
        </>
    );
};

export default PaymentsCards;