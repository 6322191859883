import ContentHorizontal from "shared/UI/Layouts/ContentHorizontal";
import { DefaultWrapper } from "shared/UI/Wrappers";
import styled from "styled-components";

export const HystoryElem = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
gap: 10px;
width: 100%;

&> {

}
/* &>label {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    align-items: center;
} */
`