import React from 'react';
import { AcceptButton } from 'shared/UI/Buttons';
import { ListVertical } from 'shared/UI/Layouts';

const RegSuccessMessages = ({ auth, goAuth, goHome }) => {
    return (
        <>
            {
                !auth
                    ?
                    <ListVertical>
                        <p>Успешно! Вам на почту выслано письмо для подтверждения</p>
                        <AcceptButton
                            title={'Вход'}
                            onClick={e => goAuth()}
                        />
                    </ListVertical>
                    :
                    <ListVertical>
                        <p>Данные успешно подтверждены</p>
                        <AcceptButton
                            title={'На главную'}
                            onClick={e => goHome()}
                        />
                    </ListVertical>
            }
        </>
    );
};

export default RegSuccessMessages;