import React, { useEffect, useState } from 'react';
import '../style.css'
import { api } from 'app/api';
import pageState from 'shared/store/pageState';
import { Layout1200, MainWrap } from 'shared/UI/Layouts';
import { CourseCreateModal, CreateCourseHeader, TeacherCoursesList } from 'widgets/TeacherArea/TeacherСourses';
import { CoursesMainWrap, SectionContent } from './ui';


const TeacherСourses = ({ pageTitle }) => {
    const [Courses, setCourses] = useState([])
    const [NewCoursPopupVisibility, setNewCoursPopupVisibility] = useState(false)
    const [Subjects, setSubjects] = useState([])

    useEffect(() => {
        pageState.setPageTitle(pageTitle)
        api.get(`/Course/User/${Number(localStorage.getItem('userId'))}`)
            .then((result) => { setCourses(result.data) },
                (error) => {

                }
            )
        api.get("/Course/Subjects",)
            .then((result) => { setSubjects(result.data) }
            )

    }, [])

    const ChangePublick = (index, id) => {
        const data = {
            cours_id: id,
            fields: (Courses[index].isPublic === true) ? { isPublic: false } : { isPublic: true },
        }
        api.put(`/course/${id}`, { ...data.fields })
            .then(() => {
                var newCourses = [...Courses];
                newCourses[index].isPublic = data.fields.isPublic
                setCourses(newCourses)
            })
    }

    const filterCoursesList = (parametr, direction) => {
        direction === 'up'
            ? setCourses([...Courses].sort((a, b) => a[parametr] > b[parametr] ? 1 : -1))
            : setCourses([...Courses].sort((a, b) => a[parametr] > b[parametr] ? -1 : 1))
    }

    return (
        <Layout1200 style={{ padding: '90px 20px 0px' }} >
            <CoursesMainWrap>
                <CreateCourseHeader onClick={() => setNewCoursPopupVisibility(true)} />
                <TeacherCoursesList
                    Courses={Courses}
                    setCourses={setCourses}
                    ChangePublic={ChangePublick}
                    Subjects={Subjects}
                />
            </CoursesMainWrap>

            <CourseCreateModal
                Visibility={NewCoursPopupVisibility}
                VisibilityToggle={setNewCoursPopupVisibility}
                Courses={Courses}
                Subjects={Subjects}
                setCourses={setCourses}
            />

        </Layout1200>
    );
};

export default TeacherСourses;