import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'app/api';
import { MainWrap } from 'shared/UI/Layouts';
import PreviewSidebar from 'widgets/LessonPreview/PreviewSidebar/PreviewSidebar';
import DemoLessonContent from 'widgets/LessonPreview/LessonContent/LessonContent';
import { Lesson } from 'shared/UI/LearnPrev/LearmPrev.styled';

const LessonEditPreview = () => {
    const { id_course, id_lesson } = useParams()
    const [lessonData, setLessonData] = useState(null)
    const [courseName, setCourseName] = useState()
    const [ModuleId, setModuleId] = useState(null)

    useEffect(() => {
        const getLessonContent = async () => {
            var lessonsList = (await api.get('/Module/Course/' + id_course))
            await api.get("/Lesson/" + id_lesson)
                .then(res => {
                    var result = { ...res.data, content: JSON.parse(res.data.content) };
                    setModuleId(res.data.moduleId);
                    setLessonData(result);
                    setCourseName(lessonsList.data.course.label);
                }
                )
        }
        getLessonContent()
        // setLoader(false)
    }, [id_lesson])

    return (
        // <MainWrap  >
        <Lesson style={{ maxWidth: '100%' }}>
            <PreviewSidebar
                id_course={id_course}
                id_lesson={id_lesson}
                lessonData={lessonData}
                type='EditPrev'
                courseName={courseName}
            />
            <DemoLessonContent lessonData={lessonData} />
        </Lesson>
        // </MainWrap>
    )
}
export default LessonEditPreview