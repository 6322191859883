import React from 'react';
import { MotionA, MotionI, moveIcon, scaleAnim } from './ui';

const DownloadCard = ({ ...props }) => {
    return (
        <MotionA
            whileHover='scale'
            variants={scaleAnim}
            target={'_blank'}
            {...props}
        >
            <p>{props.title}</p>
            <MotionI
                variants={moveIcon}
            >
                {props.icon}
            </MotionI>
            <span>Скачать</span>
        </MotionA>
    );
};

export default DownloadCard;