import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cl from './CardCourseStudent.module.css'
import cn from 'classnames';
import { IoChevronForwardOutline } from 'react-icons/io5';
import { SimpleWhiteButton } from 'shared/UI/Buttons';
import { EmptyFile } from 'shared/UI/Fillers';
import { ContentHorizontal, ListVertical } from 'shared/UI/Layouts';

import { ReviewsCounter } from 'shared/UI/Other';
import { host } from 'app/globals';
import { TextLinesIOverflow } from 'shared/UI/Containers/TextLinesOveflow/TextLinesOveflowStyle.styed';
import { GreenTag } from './styled/greenTag.styled';

const CardCourseStudent = ({ cours }) => {
    const [ShowFull, setShowFull] = useState(false)
    const [isPassed, setIsPassed] = useState(false)
    const [PreviewImg, setPreviewImg] = useState(null)

    useEffect(() => {
        cours &&

            fetch(`${host}/api/GetFile/${cours?.previewImg}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
                .then(response => response.status == 200 && response.blob(response)
                )
                .then(blob => {
                    if (blob) {
                        setPreviewImg(URL.createObjectURL(blob))
                    } else {
                        setPreviewImg(EmptyFile)
                    }
                })
    }, [cours])
    return (
        <div className={cl.StudentCourseCard}>
            <div className={cl.CoursCardHead} onClick={() => setShowFull(!ShowFull)}>
                <div className={cl.StudentCourseCardTitle}>
                    <p>
                        {!ShowFull
                            ? <IoChevronForwardOutline className={cl.arrowIcon} />
                            : <IoChevronForwardOutline className={cn(cl.arrowIconcn, cl.down)} />
                        }
                        Курс: {cours?.label}</p>
                    {/* <div className={cl.headerButtons}> */}
                    <ContentHorizontal>
                        {cours.isCourseCompleted &&
                            <GreenTag>
                                Курс пройден
                            </GreenTag>
                        }
                        {/* </div> */}
                        {!ShowFull ?
                            <div className={cl.headerButtons}>
                                {<Link className={cn(cl.OutBtn, cl.head)} to={'/mylearn/course/' + cours?.id}>
                                    Перейти к курсу
                                </Link>}
                            </div>
                            : null}
                    </ContentHorizontal>
                </div>
            </div>
            {ShowFull && <ContentHorizontal className={cl.content}>
                <ListVertical className={cl.ImgSection}>
                    <div className={cl.ImgWrap}>
                        <img src={cours.previewImg ? `${host}/api/getFile/${cours.previewImg}` : EmptyFile} />
                    </div>
                </ListVertical>
                <ListVertical className={cl.ContentSection}>
                    <ReviewsCounter />
                    <p><span className={cl.label}>Предмет</span>  {cours?.courseSubject.subject}</p>
                    <p><span className={cl.label}>Длительность</span> {cours?.duration} ч</p>
                    <TextLinesIOverflow $overflow={false} lines={'5'} >{cours?.description}</TextLinesIOverflow>
                </ListVertical>
                <ListVertical className={cl.BtnsSection}>
                    <div>
                        <p className={cl.priceLabel}>Стоимость</p>
                        <p className={cl.price}> {cours?.price} P</p>
                    </div>
                    <Link className={cl.OutBtn} to={'/mylearn/course/' + cours?.id}>
                        Перейти к курсу
                    </Link>
                </ListVertical>
            </ContentHorizontal>
            }
        </div >
    );
}
export default CardCourseStudent