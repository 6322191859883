import { CustomLink } from "shared/UI/Links";
import styled from "styled-components";

export const LessonManageBtn = styled(CustomLink)`
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(235, 235, 235);
    border-radius: 20px;
    cursor: pointer;
    align-items: center;
    border: none;
    margin-bottom: 15px;    
`
