import AddLessonForm from "features/TeacherArea.jsx/AddLessonForm/AddLessonForm";
import { AcceptButton } from "shared/UI/Buttons";
import { CustomLink } from "shared/UI/Links";
import styled from "styled-components";

export const ModuleCard = styled.div`
    /* composes: defaultStyle from 'style.module.css'; */
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    position: relative;
    border-radius: 20px;
    background: var(--default-style-back);
    box-shadow: var(--default-style-shadow);
    border: 0;
`
export const ModuleCardHeader = styled.div`
    display: flex; 
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    gap: 20px;
    height: 40px;
    cursor: pointer;

    & span {
    font-weight: 600;
    white-space: nowrap;
    }
`
export const ModuleCardHeaderFull = styled(ModuleCardHeader)`
    display: flex; 
  width: 100%;
  padding: 20px;
  height: auto;
    
`
export const ModuleLabelWrap = styled.div` 
    display: flex; 
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
`

export const CustomLinkFit = styled(CustomLink)` 
  width: fit-content;
`

export const LessonString = styled.p` 
  cursor: pointer;
  &:hover {
    color: var(--base-blue-color);
  }
`
export const AddLessonFormStyled = styled(AddLessonForm)` 
min-width: 100%;
`

export const RejectButton = styled(AcceptButton) `
  background-color: var(--color-red-default);
  &:hover { 
    background-color: var(--color-red-hover);
  }
  &:focus, &:active { 
    background-color: var(--color-red-click);
  }
`