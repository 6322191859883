import EducationLayout from "shared/UI/Layouts/EducationLayout/EducationLayout";
import styled from "styled-components";

export const Layout1440 = styled(EducationLayout)`
    display: flex;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px 20px;
    gap: 20px;
`

export const Layout1200 = styled(Layout1440)`
flex-direction: column;
max-width: 1240px;
`

export const LayoutFullWidth = styled(EducationLayout)`
    max-width: none;
`

