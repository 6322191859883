import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { api } from 'app/api';
import { RedirectButton, ConfirmMain, ConfirmDefault } from './ui';
import { LoaderBlueFullView } from 'shared/UI/Loaders';
import userStore from 'shared/store/userStore';


const SetNewEmail = observer(({ emailChangeSecond }) => {
    const [error, setError] = useState()
    const [secondConfirmationSuccess, setSecondConfirmationSuccess] = useState(false)
    const location = useLocation()

    useEffect(() => {
        SetNewMail()
    }, [])

    const SetNewMail = () => {
        api.post(`/user/confirmation/${location.pathname.split('/').pop()}`, {
        })
            .then(result => {
                if (result.status === 200 && emailChangeSecond) {
                    userStore.checkAuth();
                    setSecondConfirmationSuccess(true)
                }
            })
            .catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    setError({ message: 'Неверный пароль' });
                }
                if (error.response.status === 403) {
                    setError({ message: 'Недействительная ссылка' });
                }
            })
    }

    return (
        <>
            {
                emailChangeSecond ?
                    secondConfirmationSuccess
                        ? <LoaderBlueFullView />
                        : <Navigate to={'/profile/mail'} state={{ change: 'success', message: 'Почта успешно изменена' }} />
                    :
                    <ConfirmMain>
                        <ConfirmDefault>
                            На новую почту отправлено письмо для её подтверждения
                            <RedirectButton style={{ height: 'auto' }} onClick={() => window.close()}>
                                Закрыть вкладку
                            </RedirectButton>
                        </ConfirmDefault>
                    </ConfirmMain>
            }
        </>
    )
});

export default SetNewEmail;