import React from 'react';
import cl from './CardCoursWrapper.module.css'
import { DefaultWrapper } from 'shared/UI/Wrappers';

import cn from 'classnames'
import CardCours from 'entities/SearchCourses/CardCoursePublic';

const CardCoursWrapper = ({ element, cardStyle }) => {
    return (
        <DefaultWrapper className={cn(cl.wrapper, cl[cardStyle])}>
            <CardCours item={element} key={element.id} cardStyle={cardStyle} />
        </DefaultWrapper>
    );
};

export default CardCoursWrapper;