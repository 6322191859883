import React, { useEffect, useState } from 'react';
import { ModerationHeaderWrap } from './styled';

const ModerationHeader = ({ statusmod, isPublic }) => {

    const [realStatus, setRealStatus] = useState(3)
    console.log(statusmod, isPublic)
    useEffect(() => {
        !isPublic && statusmod === 2
            ? setRealStatus(2)
            : isPublic ? //setRealStatus(1) : setRealStatus(3)
                statusmod === 0 ? setRealStatus(0) : // опубликован и принят
                    statusmod === 1 ? setRealStatus(1) : //опубликовани и отправлен на модерацию -> жёлтый
                        statusmod === 2 ? setRealStatus(2) : //опубликован и отменён -> красный
                            setRealStatus(3)  //опубликован и ещё не в модерации (такого не может быть) -> белый
                :
                setRealStatus(3)  //промодерирован, но сенят с модерации -> белый  
    }, [statusmod, isPublic])

    return (
        <ModerationHeaderWrap $Status={realStatus}>
            <p>
                {realStatus === 0 ? 'Принято. Курс Опубликован!' :
                    realStatus === 1 ? 'Отправленно на модерацию.' :
                        realStatus === 2 ? 'Отклонено. Требуется внести изменения!' :
                            realStatus === 3 ? 'Ожидает публикации' : null}
            </p>
        </ModerationHeaderWrap>
    );
};

export default ModerationHeader;