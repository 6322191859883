import React, { useEffect, useState } from 'react';
import { api } from 'app/api';
import pageState from 'shared/store/pageState';
import { ListVertical } from 'shared/UI/Layouts';
import StudentCourses from 'widgets/StudentArea/StudentCoursesList/StudentCoursesList';
import { CardCourseStudent } from 'entities/StudentArea/CardCourseStudent';
import { invite_group, removeCookies } from 'app/globals';
import { Loader } from 'shared/UI/Loaders';
import { MainPopup } from 'shared/UI/Popups';
import { DefaultWrapper } from 'shared/UI/Wrappers';

const MyCourses = ({ pageTitle }) => {
    const [StudentCoursesList, setStudentCoursesList] = useState([])
    const [GroupsCourses, setGroupsCourses] = useState([])
    const [VisibleSubMenu, setVisibleSubMenu] = useState(null)
    const [isLoad, setIsLoad] = useState(false)
    const [visibleNotification, setVisibleNotification] = useState(false)

    useEffect(() => {
        const SetData = async () => {
            if (invite_group() !== undefined) {
                setVisibleNotification(true)
                await api.get(`user/join-group/${invite_group()}`)
                    .catch(() => {
                        setIsLoad(true)
                    })
            }
            api.get("/Course/User/cupil")
                .then((result) => {
                    setStudentCoursesList(result.data.bayCourses)
                    setGroupsCourses(result.data.groups)
                    setIsLoad(true)
                })
        }
        pageState.setPageTitle(pageTitle.data)
        SetData()
    }, [])

    return (
        <ListVertical viewMode={'list'}>
            <DefaultWrapper style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '14px', borderRadius: '24px'}}>
                <h3 style={{ display: 'flex', justifyContent: 'center', fontSize: '24px' }}>Мои курсы</h3>
                {
                    isLoad ?
                        <StudentCourses
                            StudentCoursesList={StudentCoursesList}
                            VisibleSubMenu={VisibleSubMenu}
                            setVisibleSubMenu={e => setVisibleSubMenu(e)}
                        />
                        : <Loader style={{ width: '70px', height: '70px' }} />
                }
            </DefaultWrapper>
            {
                isLoad &&
                <DefaultWrapper style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '14px', borderRadius: '24px'}}>

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <h3 style={{ display: 'flex', justifyContent: 'center', fontSize: '24px' }}>Групповые курсы</h3>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '22px' }}>
                            {
                                GroupsCourses?.map(group => (
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
                                        <p style={{ fontSize: '18px', fontWeight: 600 }}>{group?.name}</p>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
                                            {
                                                group?.groupCourses?.map(course => (
                                                    <CardCourseStudent
                                                        key={course.course.id}
                                                        cours={course.course}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                )
                                )
                            }
                        </div>
                    </div>
                </DefaultWrapper>
            }
            {
                visibleNotification &&
                <MainPopup
                    visible={visibleNotification}
                    setVisible={e => setVisibleNotification(e)}
                />
            }
        </ListVertical>

    )
};

export default MyCourses;