import React from 'react';
import cn from 'classnames';
import { MyInput, MyTextarea } from 'shared/UI/Inputs';
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
import InputArea from 'shared/UI/Inputs/InputArea';
import { AddLessonSectionForm, CreateLessonBtn, DurationLesson } from './ui';


const AddLessonForm = (props) => {
    const { register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({ mode: 'onTouched' });
    const watchDuration = watch("duration", false)

    const AddLesson = (data) => {
        props.setLessons(data)
    }

    return (
        <AddLessonSectionForm onSubmit={handleSubmit(AddLesson)} className={cn(props.mainStyle)}>
            <InputValidateUseForm
                title={'Название урока'}
                type={'text'}
                error={errors?.name}
                validateSet={register("name", {
                    required: true,
                    minLength: {
                        value: 2,
                        message: 'Минимум 5 символа'
                    },
                    pattern: {
                        value: /[А-Я][а-яё]/,
                        message: 'Введите Название с большой буквы',
                    }
                })}
            />
            <InputArea
                title={'Описание урока'}
                type={'text'}
                id={'description'}
            >
                <MyTextarea
                    minRows={3}
                    validateSet={{ ...register("description", {}) }}
                />
            </InputArea>
            <DurationLesson>
                <InputValidateUseForm
                    title={'Длительность'}
                    type={'number'}
                    error={errors?.duration}
                >
                    <MyInput
                        type={'number'}
                        validateSet={register("duration", {
                            required: "Пожалуйста укажите длительность урока",
                        })} />
                    <p>мин. или</p>
                    <p>
                        {
                            watchDuration
                                ?
                                watchDuration < 60
                                    ?
                                    watchDuration + ' мин.'
                                    :
                                    Math.floor((Number(watchDuration) / 60)) + ' ч. ' + (Math.floor(Number(watchDuration) % 60)) + ' мин.'
                                : '0 ч. 0 мин.'
                        }
                    </p>
                </InputValidateUseForm>
            </DurationLesson>
            <CreateLessonBtn
                type='submit'
            >
                Создать урок
            </CreateLessonBtn>
        </AddLessonSectionForm>
    )
};
export default AddLessonForm;