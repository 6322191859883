import React from 'react';
import MyInput from '../../MyInput/MyInput';
import cl from './InputValidateFirstCharUpp.module.css'
import cn from 'classnames';
import { InputError, MyInputStyled, ValidateInputStyled, ValidationInputWrap } from './styled/BlueValidateInput.styled';
import { uppFistChar } from 'shared/hooks/uppFistChar';
import { InputValidateUseForm } from '../InputValidateUseForm/index';

// const InputValidateFirstCharUpp = ({ validateSet, type, error, title, disabled, className, children, after, isEdit, ...props }) => {
const InputValidateFirstCharUpp = (props) => {
    return (
        <InputValidateUseForm
            {...props}
            validateSet={{ ...props.validateSet, onChange: (e) => { e.target.value = uppFistChar(e.target.value) } }}
        />
    );
};

export default InputValidateFirstCharUpp;