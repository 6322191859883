import styled from "styled-components";

export const Section = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
`

export const AccountSection = styled.div`
    z-index: 10;
       width: 100%;
    height: min-content;
    background: var(--default-style-back);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 20px;
    box-shadow: var(--default-style-shadow);
    border: var(--default-style-border);
    padding: 36px;
    gap: 40px;
`
export const NameSectionAccount = styled.div`
     p{
        font-size: 32px;
    }
`
export const AccountInformation = styled.div`
    width: 100%;
    height: min-content;
    display: flex;
    flex-direction: row;
    gap: 30px;
`