
import React, { useEffect, useState } from 'react';
import { RenderText } from './RenderText';
import { RenderSelect } from './RenderSelect';
import ImgDefault from 'shared/UI/Images/ImgDefault/ImgDefault';
import { host } from 'app/globals';
import { EmptyFile } from 'shared/UI/Fillers';
import _ from 'lodash'
import { ContentHorizontal, ListVertical } from 'shared/UI/Layouts';
import { api } from 'app/api';
// import { DefaultLine, DefaultWrapper2, DefaultWrapperNoPadding } from 'pages/StudentArea/StudentCoursesList/LessonShow/LearnPrev/LearmPrev.styled';
import { DefaultLine } from 'shared/UI/LearnPrev/LearmPrev.styled';
import { AnswerMark } from './styled/AnswerMark.styled';
import { observer } from 'mobx-react-lite';
import MySurveysStore from 'shared/store/MySurveysStore';

const QuestionRender = ({ Question, ListLength, isLastIndex, CurrentIndex, setAnswersToCheck, setCurrent, isAnswered, updateQuestion, ...props }) => {
  const [TestAnswers, setTestAnswers] = useState([])
  const [localQuestion, setlocalQuestion] = useState(null)

  useEffect(() => {
    setlocalQuestion(Question)
  }, [Question])

  const updateAnswersList = (answer) => {
    setAnswersToCheck(answer, Question.id, Question.type)
    // isLastIndex && MySurveysStore.SendIsLastAnswer(true)

  }

  const SendAnswerToDB = async (text, id) => {
    const result = await api.put(`AnswerOption/AddUserAnswer/${localStorage.getItem('SurveyCurrentTry')}`, { userAnswerText: text, questionId: id })
    return id
  }


  // Если ответ отмечен как isNotSelect, то отправить запрос на удаление
  // Если ответ отмечен как siSelect, то отправить запрос на добавление
  const SetAnswer = (answer, updateActionStatus) => {
    if (updateActionStatus) {
      if (Number(Question.type) === 3 || Number(Question.type) === 2) {
        var CopyList = JSON.parse(JSON.stringify(Question))
        answer?.answer?.map(a => {
          a.answered
            ?
            api.put(`AnswerOption/AddUserAnswer/${localStorage.getItem('SurveyCurrentTry')}`, { userAnswerText: String(a.id), questionId: Question.id })
            :
            api.post(`AnswerOption/DeleteUserAnswer/${localStorage.getItem('SurveyCurrentTry')}`, { userAnswerText: String(a.id), questionId: Question.id })

          const elIndex = CopyList.answerOptions.findIndex(e => e.id === a.id)
          CopyList.answerOptions[elIndex] = { ...CopyList.answerOptions[elIndex], answered: a.answered }
          updateAnswersList(answer)
          updateQuestion(CopyList)
          // это выажный и нужный запрос в цыкле (не удалять)
        }
        )
      }
    } else {
      //строка текста
      if (Number(Question.type) === 1) {

        api.put(`AnswerOption/AddUserAnswer/${localStorage.getItem('SurveyCurrentTry')}`, { userAnswerText: String(answer.answer), questionId: Question.id })
          //Нужно обновить локальные Question.answerOptions.answerd
          .then((result) => updateAnswersList(result.data))

      }

      //чекбоксы
      if (Number(Question.type) == 2) {
        var CopyList = JSON.parse(JSON.stringify(Question))
        answer?.answer?.map(a => {
          // это выажный и нужный запрос в цыкле (не удалять)
          api.put(`AnswerOption/AddUserAnswer/${localStorage.getItem('SurveyCurrentTry')}`, { userAnswerText: String(a.id), questionId: Question.id })
          const elIndex = CopyList.answerOptions.findIndex(e => e.id === a.id)
          CopyList.answerOptions[elIndex] = { ...CopyList.answerOptions[elIndex], answered: true }
        }
        )
        updateAnswersList(answer)
        updateQuestion(CopyList)
      }
      //радиокнопки
      if (Number(Question.type) === 3) {
        var CopyList = JSON.parse(JSON.stringify(Question))
        api.put(`AnswerOption/AddUserAnswer/${localStorage.getItem('SurveyCurrentTry')}`, {
          userAnswerText: answer.answer.length !== 0
            ? String(answer.answer[0].id)
            : null,
          questionId: Question.id
        })
          //Нужно обновить локальные Question.answerOptions.answerd
          .then(() => {
            const elIndex = CopyList.answerOptions.findIndex(e => e.id === answer.answer[0]?.id)
            CopyList.answerOptions[elIndex] = { ...CopyList.answerOptions[elIndex], answered: true }
            updateAnswersList(answer);
            updateQuestion(CopyList)
          })
          .catch(error =>
            console.log(error)
          )
      }
    }

    //проверка на последний вопрос
  }

  //данные для заполнения в случаее перехода к этому вопросу
  const FindAnswer = (questonId) => TestAnswers.find(Q => Q.id === questonId)
  return (
    <ListVertical>
      <AnswerMark isAnswered={isAnswered}>
        {isAnswered
          ?
          'отвечено'
          :
          'неотвчено'}
      </AnswerMark>
      <h3>
        {Question?.textQuest}
      </h3>
      {Question?.imgQuest != null
        ?
        <>
          <DefaultLine />
          <ImgDefault style={{ maxWidth: '400px' }} //width={'550px'} height={'370px'}
            src={Question.imgQuest
              ? `${host}/api/GetFile/${Question.imgQuest}`
              : EmptyFile
            } />
        </>
        : null
      }
      <DefaultLine />
      {/* Список ответов */}

      {Question
        ?
        (() => {
          switch (Question.type) {
            case "1":
              return (<RenderText
                isLast={false}
                Question={Question}
                setAnswer={(answer, curId) => { SetAnswer(answer); setCurrent(curId) }} postEdit={true} />);
            case "2":
              return (<RenderSelect
                isLast={false}
                Question={Question}
                setAnswer={(answer, curId, updateActionStatus, ElIndex) => { SetAnswer(answer, updateActionStatus); setCurrent(curId) }} />);
            case "3":
              return (<RenderSelect
                isLast={false}
                Question={Question}
                setAnswer={(answer, curId, updateActionStatus, ElIndex) => { SetAnswer(answer, updateActionStatus); setCurrent(curId) }} />);
            default: return null;
          }
        })()
        :
        null}

    </ListVertical >

  );
};

export default observer(QuestionRender);