import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { api } from 'app/api';
import { LoaderBlueFullView } from 'shared/UI/Loaders';
import { MainWrap } from 'shared/UI/Layouts';
import { Lesson } from 'shared/UI/LearnPrev/LearmPrev.styled';
import PreviewSidebar from 'widgets/LessonPreview/PreviewSidebar/PreviewSidebar';
import DemoLessonContent from 'widgets/LessonPreview/LessonContent/LessonContent';

const LessonDemo = () => {
    const { id_course, id_module, id_lesson } = useParams()
    const [lessonData, setLessonData] = useState(null)
    const [Loader, setLoader] = useState(false)
    const [courseInfo, setCourseInfo] = useState({})

    useEffect(() => {
        setLoader(true)
        const getLessonContent = async () => {
            var lessonsList = (await api.get('/Module/Course/' + id_course))
            var response = await api.get("/Lesson/" + id_lesson)
            var result = { ...response.data, content: JSON.parse(response.data.content) }
            setLessonData(await result)
            setCourseInfo(lessonsList.data.course)
        }
        getLessonContent()
        setLoader(false)
    }, [id_lesson])

    return (
        <MainWrap >
            <Lesson>
                {
                    !Loader
                        ?
                        <>
                            <PreviewSidebar
                                id_course={id_course}
                                id_lesson={id_lesson}
                                id_module={id_module}
                                lessonData={courseInfo}
                                type={'demo'}
                                courseName={courseInfo.label}
                            />
                            <DemoLessonContent lessonData={lessonData} />
                        </>
                        :
                        <LoaderBlueFullView />
                }
            </Lesson>
        </MainWrap>
    )
}
export default LessonDemo