import React, { useState } from 'react';
import { AcceptButton } from 'shared/UI/Buttons';
import cl from './AddLessonTest.module.css'
import cn from 'classnames';
import { MyInput, MyTextarea } from 'shared/UI/Inputs';
import { useForm } from 'react-hook-form';
import { InputValidateRadio, InputValidateUseForm } from 'shared/UI/Inputs/HookForm';
import InputArea from 'shared/UI/Inputs/InputArea';
import { CancelButton, RedButton } from 'shared/UI/Buttons';
import { api } from 'app/api';
import { convertTimeSpan } from 'shared/hooks';
import { ListVertical } from 'shared/UI/Layouts';
import { FormContent } from './ui';

const AddLessonTest = ({ LessonId, mainStyle, createTest, onClose, ...props }) => {

    const { register,
        handleSubmit,
        reset,
        setError,
        watch,
        setValue,
        getValues,
        formState: { errors }
    } = useForm(
        {
            mode: 'onTouched',
            defaultValues: { trycount: 1, pointSystem: 'true', questJump: 'true', random: 'true', isTryCount: 'false' }
        }
    );
    const watchDuration = watch("duration", false)
    const watchAllFields = watch();
    const [errorResult, setErrorResult] = useState({ status: false, message: '' })



    const createTestComplete = (data) => {
        console.log(data)
        const newData = Object.entries(data).reduce(
            (fields, [label, value]) => {
                if (value === 'true') {
                    return { ...fields, [label]: true }
                } else if (value === 'false') {
                    return { ...fields, [label]: false }
                } else if (Number(value)) {
                    return { ...fields, [label]: Number(value) }
                }
                return { ...fields, label: value }
            }, {}
        )
        api.post('Survey', { ...newData, LessonId: LessonId, pointSystem: Boolean(data.pointSystem), duration: convertTimeSpan(data.duration), showAnyCount: 1 })
            .then(result => createTest(result.data))
            .catch(({ response }) => {
                setErrorResult({ status: true, message: response.data })
            }
            )
    }

    return (
        <>
            {
                !errorResult.status ?
                    <form
                        onSubmit={handleSubmit(createTestComplete)}
                        className={cn(cl.addLessonSection, mainStyle)}
                    >
                        <FormContent>
                            <InputValidateUseForm
                                title={'Название теста'}
                                type={'text'}
                                className={cl.addModuleInput}
                                error={errors?.Label}
                                validateSet={register("Label", {
                                    required: true,
                                    minLength: {
                                        value: 2,
                                        message: 'Минимум 5 символа'
                                    },
                                    pattern: {
                                        value: /[А-Я][а-яё]/,
                                        message: 'Введите Название с большой буквы',
                                    }
                                })}
                            />
                            <div className={cl.durationlesson}>
                                <InputValidateUseForm
                                    title={'Длительность'}
                                    type={'number'}
                                    className={cl.addLessonTime}
                                    error={errors?.duration}
                                >
                                    <MyInput
                                        type={'number'}
                                        validateSet={register("duration", {
                                            required: "Пожалуйста укажите длительность теста",
                                        })} />
                                    <p>
                                        {
                                            watchDuration
                                                ?
                                                watchDuration < 60
                                                    ?
                                                    'мин.'
                                                    :
                                                    <p>
                                                        {`мин. или `}
                                                        {Math.floor((Number(watchDuration) / 60)) + ' ч. ' + (Math.floor(Number(watchDuration) % 60)) + ' мин.'}
                                                    </p>
                                                : 'мин.'
                                        }
                                    </p>
                                </InputValidateUseForm>
                                <ListVertical>
                                    <InputValidateRadio
                                        title='Попытки'
                                        InputClass={cl.Radio}
                                        RadioClass={cl.RadioField}
                                        checked={watch('isTryCount') ?? 'true'}
                                        RadioList={
                                            [
                                                { id: 'itc1', value: 'true', name: 'isTryCount', title: 'Ограниченно' },
                                                { id: 'itc2', value: 'false', name: 'isTryCount', title: 'Не ограничено' }
                                            ]
                                        }
                                        error={errors?.random}
                                        validateSet={{
                                            ...register("isTryCount", {
                                                required: "Не выбрано",
                                                onChange: (e) => { setValue('isTryCount', e.target.value) }
                                            })
                                        }}
                                    />
                                    {console.log(watch('isTryCount'))}
                                    {
                                        watch('isTryCount') === true || watch('isTryCount') === 'true'
                                            ? <InputValidateUseForm
                                                title={'Количество попыток'}
                                                placeholder={'1'}
                                                type={'number'}
                                                className={cl.addLessonTime}
                                                error={errors?.trycount}
                                                validateSet={{
                                                    ...register("trycount", {
                                                        required: true,
                                                        min: {
                                                            value: 1,
                                                        }
                                                    })
                                                }}
                                            />
                                            : null
                                    }

                                </ListVertical>


                            </div>
                            <div className={cl.durationlesson}>
                                <InputValidateRadio
                                    title='Порядок вопросов для ученика'
                                    InputClass={cl.Radio}
                                    RadioClass={cl.RadioField}
                                    checked={watch('random') ?? 'true'}
                                    RadioList={
                                        [
                                            { id: 'random1', value: 'true', name: 'random', title: 'Случайно' },
                                            { id: 'random2', value: 'false', name: 'random', title: 'Упорядоченно' }
                                        ]
                                    }
                                    error={errors?.random}
                                    validateSet={{
                                        ...register("random", {
                                            required: "Не выбрано",
                                        })
                                    }}
                                />
                                <InputValidateRadio
                                    title='Переход к пройденым вопросам'
                                    InputClass={cl.Radio}
                                    RadioClass={cl.RadioField}
                                    checked={watch('questJump') ?? 'true'}
                                    RadioList={
                                        [
                                            { id: 'questJump1', value: 'true', name: 'questJump', title: 'Да' },
                                            { id: 'questJump2', value: 'false', name: 'questJump', title: 'Нет' }
                                        ]
                                    }
                                    error={errors?.questJump}
                                    validateSet={{
                                        ...register("questJump", {
                                            required: "Выберите тип группы"
                                        })
                                    }}
                                />
                            </div>
                            <InputValidateRadio
                                title='Система оценивания'
                                InputClass={cl.Radio}
                                RadioClass={cl.RadioField}
                                checked={watch('pointSystem') ?? 'false'}
                                RadioList={
                                    [
                                        { id: 'point', value: 'true', name: 'pointSystem', title: 'Баллы' },
                                        { id: 'limit', value: 'false', name: 'pointSystem', title: 'Зачёт ' }
                                    ]
                                }
                                error={errors?.pointSystem}
                                validateSet={{
                                    ...register("pointSystem", {
                                        required: "Выберите тип группы"
                                    })
                                }}
                            />
                            <InputValidateUseForm
                                RadioClass={cl.RadioField}
                                after={
                                    <p>%</p>
                                }
                                title={
                                    <p>
                                        {(watch("pointSystem") === true || watch("pointSystem") == 'true')
                                            ? '% верных ответов на 3'
                                            : '% верных ответов на зачёт'}
                                    </p>
                                }
                                type={'number'}
                                className={cl.validateInput}
                                error={errors?.failure_3}
                                validateSet={register("failure_3", {
                                    required: 'Пожалуйста, укажите минимальный проходной бал',
                                    max: {
                                        value: 100,
                                        message: 'Значение не может быть больше 100%'
                                    },
                                    min: {
                                        value: 0,
                                        message: 'Значение не может быть меньше или равно 0'
                                    },
                                })}
                            />

                            {(watch("pointSystem") === true || watch("pointSystem") == 'true') ?
                                <>
                                    <InputValidateUseForm
                                        RadioClass={cl.RadioField}
                                        title={'% верных ответов на 4'}
                                        type={'number'}
                                        className={cl.validateInput}
                                        error={errors?.grade_4}
                                        validateSet={register("grade_4", {
                                            required: 'Пожалуйста, укажите количество попыток',
                                            max: {
                                                value: 100,
                                                message: 'Значение не может быть больше 100%'
                                            },
                                            min: {
                                                value: 1 + Number(watch("failure_3")),
                                                message: `не может быть меньше или равно ${watch("failure_3")}`
                                            },
                                        })}
                                        after={
                                            <p>%</p>
                                        }
                                    />
                                    <InputValidateUseForm
                                        title={'% верных ответов на 5'}
                                        type={'number'}
                                        RadioClass={cl.RadioField}
                                        className={cl.validateInput}
                                        error={errors?.grade_5}
                                        validateSet={register("grade_5", {
                                            required: 'Пожалуйста, укажите количество попыток',
                                            max: {
                                                value: 100,
                                                message: 'Значение не может быть больше 100%'
                                            },
                                            min: {
                                                value: 1 + Number(watch("grade_4")),
                                                message: `не может быть меньше или равно ${watch("grade_4")}`
                                            },
                                        })}
                                        after={<p>%</p>}
                                    />
                                </>
                                : null
                            }
                            <hr style={{ border: '1px solid #a1a1a1' }} />
                        </FormContent>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', gap: '20px' }}>
                            <RedButton
                                onClick={() => onClose()}
                                className={cl.addLessonBtn}
                                text="Отменить"
                            />
                            <AcceptButton
                                type='submit'
                                className={cl.addLessonBtn}
                                title="Создать тест"
                            >
                            </AcceptButton>
                        </div>
                    </form >
                    : <p>{errorResult.message}</p>
            }
        </>

    )
}
export { AddLessonTest };