import React, { useState } from 'react';
import cl from './LessonTests.module.css'
import { AcceptButton, ButtonNo, ButtonYes } from 'shared/UI/Buttons';
import { ModalSmall } from 'shared/UI/Modals';
import { RedButton } from 'shared/UI/Buttons';
import { BlueButton } from 'shared/UI/Buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { MainPopup } from 'shared/UI/Popups';
import { AddLessonTest } from 'widgets/AddLessonTest/AddLessonTest';
import { ContentHorizontal, ListVertical } from 'shared/UI/Layouts';
import { formateTimeSpanToMinute, useDurationTransform } from 'shared/hooks';

const LessonsTests = ({ lesson, createTest, RemoveTest }) => {
    const [State, setState] = useState(
        {
            LessonHasTest: false,
            TestDeleteConfirm: false,
            showAddPopup: false,
            CreateTestPopup: false
        }
    )

    const { mid } = useParams()

    const navigate = useNavigate()

    const TransformDuration = useDurationTransform()

    return (
        <div>
            <ContentHorizontal style={{ maxWidth: 'none' }}>
                {
                    lesson?.surveys != null && lesson?.surveys[0] !== null && lesson?.surveys.length > 0
                        ?
                        <>
                            <div style={{
                                background: 'rgb(255, 255, 255)',
                                padding: '10px 20px',
                                width: '100%',
                                borderRadius: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                                <p>{lesson?.surveys[0]?.label}</p>
                                <p>{'Длительность: '}
                                    {formateTimeSpanToMinute(lesson?.surveys[0]?.duration) + ' минут'}
                                    {/* {TransformDuration(lesson?.surveys[0]?.duration)} */}
                                </p>

                                {/* 
                                {lesson.isPublic
                                    ?
                                    <AcceptButton 
                                        title={'Снять'}
                                        onClick={(e) => setState({ ...State, TestDeleteConfirm: true })}
                                    />
                                    :
                                    <RedButton
                                        style={{ lineHeight: '100%', padding: '16px 23px', borderRadius: ' 14px', width: 'fit-content' }}
                                        data-id={lesson?.id}
                                        onClick={(e) => setState({ ...State, TestDeleteConfirm: true })}>
                                        Опубликовать
                                    </RedButton>
                                } */}

                                <RedButton
                                    style={{ lineHeight: '100%', padding: '16px 23px', borderRadius: ' 14px', width: 'fit-content' }}
                                    data-id={lesson?.id}
                                    // className={cl.ChangeBtn}
                                    onClick={(e) => setState({ ...State, TestDeleteConfirm: true })}>
                                    Удалить тест
                                </RedButton>
                                <AcceptButton onClick={e => {
                                    navigate(`lesson/${lesson.id}/survey/${lesson?.surveys[0].id}`)
                                    // navigate(`survey/${lesson?.surveys[0].id}`)
                                }}
                                    title={'изменить'} />
                            </div>
                        </>
                        // </div> 
                        // <div className={cl.testsArea}>
                        //     <Link to={`/test/edit/${lesson?.surveys[0]?.id}`}>
                        //         <MdPlaylistPlay /> {` Тест: ${lesson?.surveys[0]?.label}`}
                        //     </Link>
                        // </div>
                        : null
                }
                {
                    lesson?.surveys === null || lesson?.surveys[0] === null || lesson?.surveys?.length === 0
                        ?
                        <ContentHorizontal >
                            <BlueButton
                                style={{ padding: '6px 13px' }}
                                data-id={lesson?.id}
                                // className={cl.ChangeBtn}
                                onClick={e => {
                                    setState({ ...State, CreateTestPopup: true })
                                }}
                            >
                                Создать тест
                            </BlueButton>
                        </ContentHorizontal>
                        :
                        null
                }
            </ContentHorizontal>

            <MainPopup
                isOpened={State.CreateTestPopup}
                onClose={e => setState({ ...State, CreateTestPopup: false })}
                className={cl.addTestPopup}
                title={'Создание теста'}>
                <AddLessonTest
                    onClose={e => setState({ ...State, CreateTestPopup: false })}
                    LessonId={lesson?.id}
                    mainStyle={cl.AddLessonForm}

                    //----------------------------
                    createTest={(testData) => { createTest(testData); setState({ ...State, CreateTestPopup: false }) }}
                //----------------------------
                />
            </MainPopup>

            {
                lesson?.surveys?.length > 0 ?
                    <ModalSmall
                        visible={State.TestDeleteConfirm}
                        setVisible={() => setState({ ...State, TestDeleteConfirm: false })}
                        className={cl.LessonDeleteAlert}>
                        <p>Вы точно хотите удалить Тест {lesson?.surveys[0]?.label}</p>
                        <div className={cl.LessonDeleteAlertBtns}>
                            <ButtonYes onClick={() => {
                                RemoveTest(lesson?.surveys[0]?.id)
                                    .then(() => {
                                        setState({ ...State, TestDeleteConfirm: false, LessonHasTest: false });
                                    })
                            }} />
                            <ButtonNo onClick={() => setState({ ...State, TestDeleteConfirm: false })} />
                        </div>
                    </ModalSmall>
                    : null
            }
        </div>

    );
};

export default LessonsTests;