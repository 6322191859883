import styled from 'styled-components';

export const ImgUploadQuestion = styled.div`
width: 400px;
height: 354px;
border-radius: 14px;
border: 2px solid var(--blue-button-color);
color: var(--blue-button-color);
display: flex;
background-color: transparent;
transition: .3s linear;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 14px;
cursor: pointer;
overflow: hidden;

& svg{
    font-size: 50px;
}

& p { 
    font-size: 18px;
    font-weight: 500;
}
& input { 
   display: none;
}

&:hover { 
   background-color: #DCEAFF;
}
`

export const ImgUploadAnwer = styled.div`

`
export const ImgSubUploadQuestion = styled(ImgUploadQuestion)`
    border: 0;
    width: unset;
    height: unset;
    overflow: unset;
    border-radius: unset;
    flex-direction: row;
    font-weight: 500;
    font-size: 18px;
    max-width: 50%;
    width: 100%;
    justify-content: flex-end;
    color: var(--base-blue-color);


    &>label{    
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer
    }
    &>label:hover{    
      text-decoration: underline;
    }
    &>label:hover p{    
      text-decoration: underline;
    }

    &:hover { 
        background-color: unset;
    }
    & svg{
        font-size: 18px;
    }


`

export const ImgRemove = styled(ImgSubUploadQuestion)`
    cursor: pointer;
    color: #FF5F5F ;
    font-size: 16px;
    font-weight: 500;
    max-width: 50%;
    width: 100%;
    justify-content: flex-start;
    & svg{
        font-size: 18px;
    }
    &:hover{    
      text-decoration: underline;
    }
    &:hover p{    
      text-decoration: underline;
    }
`