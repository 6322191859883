import { MyInput } from 'shared/UI/Inputs';
 import { ContentHorizontal, ListVertical } from 'shared/UI/Layouts';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import MySurveysStore from 'shared/store/MySurveysStore';

const RenderText = observer(({ Question, setAnswer, postEdit, defaultAnswer, isLast }) => {
    const [LocalAnswerString, setLocalAnswerString] = useState('')
    const [updateType, setUpdateType] = useState(false)

    useEffect(() => {
        LocalAnswerString.length > 0
        ? MySurveysStore.setHasAnswer(true)
        : MySurveysStore.setHasAnswer(false)
    },
    [LocalAnswerString])


    useEffect(() => {
      //найдём последний элемент с последним `
      const lastAnswered = Question?.answerOptions.find( ao => ao.answered === true )      
      setLocalAnswerString(lastAnswered?.textAnswer || '')
    }, [Question])

    useEffect(() => {
        MySurveysStore.SendAnswerAction &&
            setAnswer({ id: Question.id, answer: LocalAnswerString }, Question.id, updateType)
            // console.log('отправка текста завершена')
        MySurveysStore.setAnswerAction(false)
    }, [MySurveysStore.SendAnswerAction])   

    return (       
            <ContentHorizontal style={{ alignItems: 'center' }}>
                <p>Ответ:</p>
                < MyInput
                    disabled={!postEdit && defaultAnswer ? true : false}
                    value={LocalAnswerString}
                    onChange={e => setLocalAnswerString(e.target.value)}
                />
            </ContentHorizontal>      
    );
});

export default RenderText;