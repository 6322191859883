import React, { useEffect, useState } from 'react';
import cl from './TestSettings.module.css'
import cn from 'classnames';
import { MyInput } from 'shared/UI/Inputs';
import { Controller, useForm } from 'react-hook-form';
import { InputValidateUseForm, InputValidateCheckBox, InputValidateRadio } from 'shared/UI/Inputs/HookForm';

import { DefaultWrapper } from 'shared/UI/Wrappers';
import { ContentHorizontal, ListVertical } from 'shared/UI/Layouts';
import { GreenButton } from 'shared/UI/Buttons';
import { formateTimeSpanToMinute } from 'shared/hooks';
import { PointsElement } from './styled/pointsElement.styled';

const TestSettings = ({ settings, saveSettings, countOfQuestions }) => {
    const { register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors, isTouched, isDirty, isValid }
    } = useForm(
        {
            mode: 'onSubmit',
            // defaultValues: settings
        }
    );

    useEffect(() => {
        var ResetingData = { ...settings }
        for (let key in ResetingData) {
            if (ResetingData[key] === true || ResetingData[key] === false)
                ResetingData[key] = JSON.stringify(ResetingData[key])
            if (key === 'duration') { ResetingData[key] = formateTimeSpanToMinute(settings.duration) }
            setValue(key, ResetingData[key])
        }
    }, [settings]);

    const saveTest = (TestData) => {
        var SendingData = { ...TestData }
        for (let key in SendingData) {
            if (SendingData[key] === "true" || SendingData[key] === "false")
                SendingData[key] = JSON.parse(SendingData[key])
        }
        saveSettings(SendingData)
    }

    return (
        <div className={cl.settingsWarpDiv}>
            <DefaultWrapper className={cl.settingsListWrap}>
                {JSON.stringify(watch()) != '{}'
                    ?
                    <form onSubmit={handleSubmit(saveTest)}>
                        <ListVertical className={cl.settingsList}>
                            <h3>Настройки теста</h3>
                            <InputValidateRadio
                                RadioClass={cl.RadioField}
                                title='Опубликовать тест'
                                className={cl.validateInput}
                                checked={JSON.parse(watch('isPublic'))}
                                RadioList={
                                    [
                                        { id: 'public', value: true, name: 'isPublic', title: 'да' },
                                        { id: 'notPublic', value: false, name: 'isPublic', title: 'нет' }
                                    ]
                                }
                                InputClass={cl.Radios}
                                error={errors?.showAny}
                                validateSet={{
                                    ...register("isPublic", { required: "Пожалуйста выберите" }),
                                    onChange: e => { setValue(e.target.name, e.target.value) }
                                }}
                            />
                            <InputValidateUseForm
                                title={'Название теста'}
                                type={'text'}
                                className={cl.validateInput}
                                error={errors?.label}
                                validateSet={register("label", {
                                    required: 'Пожалуйста, назовите тест ',
                                    minLength: {
                                        value: 1,
                                        message: 'Минимум 1 буквы в название'
                                    },
                                })}
                            />
                            <InputValidateUseForm
                                title={'Длительность (мин.)'}
                                type={'number'}
                                InputClass={cl.validateInput}
                                error={errors?.duration}
                                validateSet={register("duration", {
                                    required: 'Пожалуйста, укажите длительность теста',
                                    min: {
                                        value: 1,
                                        message: 'Укажите длительность в мин.'
                                    },
                                })}
                            />

                            <InputValidateRadio
                                RadioClass={cl.RadioField}
                                title='Ограничить количество попыток'
                                className={cl.validateInput}
                                checked={JSON.parse(watch('isTryCount'))}
                                RadioList={
                                    [
                                        { id: 'showTryCount', value: true, name: 'isTryCount', title: 'да' },
                                        { id: 'hideTryCount', value: false, name: 'isTryCount', title: 'нет' }
                                    ]
                                }
                                InputClass={cl.Radios}
                                error={errors?.showAny}
                                validateSet={{ ...register("isTryCount", { required: "Пожалуйста выберите" }) }}
                            />

                            {watch("isTryCount") === true || watch("isTryCount") == 'true' ?
                                < InputValidateUseForm
                                    title={'Количество попыток'}
                                    type={'number'}
                                    InputClass={cl.validateInput}
                                    error={errors?.tryCount}
                                    validateSet={register("tryCount", {
                                        required: 'Пожалуйста, укажите количество попыток',
                                        min: {
                                            value: 1,
                                            message: 'Попыток не может быть меньше 1'
                                        },
                                    })}
                                />
                                : null
                            }

                            <InputValidateRadio
                                RadioClass={cl.RadioField}
                                title='Ограничить вывод вопросов'
                                className={cl.validateInput}
                                checked={JSON.parse(watch('showAny'))}
                                RadioList={
                                    [
                                        { id: 'showAny', value: true, name: 'showAny', title: 'да' },
                                        { id: 'ShowAll', value: false, name: 'showAny', title: 'нет' }
                                    ]
                                }
                                InputClass={cl.Radios}
                                error={errors?.showAny}
                                validateSet={{ ...register("showAny", { required: "Пожалуйста выберите" }) }}
                            />

                            {JSON.parse(watch('showAny')) === true
                                ?
                                <InputValidateUseForm
                                    disabled={JSON.parse(watch("showAny")) === false}
                                    title={'Количество выводимых вопросов'}
                                    type={'number'}
                                    // checked={settings?.showAnyCount}
                                    className={cl.validateInput}
                                    error={errors?.showAnyCount}
                                    validateSet={register("showAnyCount", {
                                        required: 'Пожалуйста укажите количество ',
                                        min: {
                                            value: 1,
                                            message: 'В тесте дожен быть мимимум 1 вопрос '
                                        },
                                    })}
                                />
                                : null}

                            <InputValidateRadio
                                RadioClass={cl.RadioField}
                                title='Порядок вопросов для ученика'
                                defaultValue={settings?.random}
                                className={cl.validateInput}
                                checked={JSON.parse(watch('random'))}
                                RadioList={
                                    [
                                        { id: 'random1', value: true, name: 'random', title: 'случайно' },
                                        { id: 'random2', value: false, name: 'random', title: 'упорядочно' }
                                    ]
                                }
                                InputClass={cl.Radios}
                                error={errors?.random}
                                validateSet={{
                                    ...register("random", {
                                        required: "Выберите тип группы",
                                        onChange: e => {
                                            setValue(e.target.name, JSON.parse(e.target.value))

                                        }
                                    })
                                }}
                            />
                            <InputValidateRadio
                                RadioClass={cl.RadioField}
                                title={'Разрешить перепрыгивание по вопросам'}
                                defaultValue={settings?.questJump}
                                className={cl.validateInput}
                                checked={JSON.parse(watch('questJump'))}
                                RadioList={
                                    [
                                        { id: 'questJump1', value: true, name: 'questJump', title: 'да' },
                                        { id: 'questJump2', value: false, name: 'questJump', title: 'нет' }
                                    ]
                                }
                                InputClass={cl.Radios}
                                error={errors?.questJump}
                                validateSet={{
                                    ...register("questJump", {
                                        value: watch("questJump"),
                                        required: "Пожалуста выберите да или нет"
                                    })
                                }}
                            />
                            {JSON.parse((watch("questJump"))) === true ?
                                <>
                                    <InputValidateRadio
                                        RadioClass={cl.RadioField}
                                        title={'Разрешить переход к уже решённым'}
                                        className={cl.validateInput}
                                        defaultValue={settings?.solvedMove}
                                        checked={JSON.parse(watch('solvedMove'))}
                                        RadioList={
                                            [
                                                { id: 'solvedMove1', value: true, name: 'solvedMove', title: 'да' },
                                                { id: 'solvedMove2', value: false, name: 'solvedMove', title: 'нет' }
                                            ]
                                        }
                                        InputClass={cl.Radios}
                                        error={errors?.solvedMove}
                                        value={JSON.parse(watch("solvedMove"))}
                                        validateSet={{
                                            ...register("solvedMove",
                                                {
                                                    required: "Пожалуста, выберите да или нет",
                                                    onChange: e => { setValue(e.target.name, JSON.parse(e.target.value)) }
                                                },

                                            )
                                        }}
                                    />

                                    <InputValidateRadio
                                        RadioClass={cl.RadioField}
                                        defaultValue={settings?.solvedEdit}
                                        className={cl.validateInput}
                                        checked={JSON.parse(watch('solvedEdit'))}
                                        title={'Разрешить изменять решённые'}
                                        RadioList={
                                            [
                                                { id: 'solvedEdit1', value: true, name: 'solvedEdit', title: 'да' },
                                                { id: 'solvedEdit2', value: false, name: 'solvedEdit', title: 'нет' }
                                            ]
                                        }
                                        InputClass={cl.Radios}
                                        error={errors?.solvedEdit}
                                        validateSet={{
                                            ...register("solvedEdit", {
                                                required: "Пожалуста выберите да или нет",
                                                onChange: e => { setValue(e.target.name, JSON.parse(e.target.value)) }
                                            })
                                        }}
                                    />
                                </>
                                : null
                            }

                            {watch("pointSystem") != null &&
                                <InputValidateRadio
                                    RadioClass={cl.RadioField}

                                    title={`Система оценки`} // ${watch("pointSystem") == 'true' ? '(1 вопрос - 1 балл)' : ''}`}
                                    className={cl.validateInput}
                                    defaultValue={settings?.pointSystem}
                                    checked={JSON.parse(watch('pointSystem'))}
                                    RadioList={
                                        [
                                            { id: 'limit', value: false, name: 'pointSystem', title: 'зачёт' },
                                            { id: 'point', value: true, name: 'pointSystem', title: 'баллы ' }
                                        ]
                                    }
                                    InputClass={cl.Radios}
                                    error={errors?.pointSystem}
                                    validateSet={{
                                        ...register("pointSystem", {
                                            required: "Выберите систему",
                                            onChange: e => { setValue(e.target.name, JSON.parse(e.target.value)) }
                                        })
                                    }}
                                />}
                            {/* минимальный проходной балл */}
                            <InputValidateUseForm
                                RadioClass={cl.RadioField}
                                after={
                                    <ContentHorizontal>
                                        <p>%</p>
                                        <PointsElement> {(Math.ceil(Number(watch("failure_3")) / 100 * countOfQuestions))} </PointsElement>
                                    </ContentHorizontal>
                                }
                                title={<ContentHorizontal>
                                    <p>
                                        {(watch("pointSystem") === true || watch("pointSystem") == 'true')
                                            ? '% верных ответов на 3'
                                            : '% верных ответов на зачёт'}
                                    </p>
                                    <PointsElement>Ответов </PointsElement>
                                </ContentHorizontal>


                                }
                                type={'number'}
                                className={cl.validateInput}
                                error={errors?.failure_3}
                                validateSet={register("failure_3", {
                                    required: 'Пожалуйста, укажите минимальный проходной бал',
                                    max: {
                                        value: 100,
                                        message: 'Значение не может быть больше 100%'
                                    },
                                    min: {
                                        value: 0,
                                        message: 'Значение не может быть меньше или равно 0'
                                    },
                                })}
                            />
                            
                            {/* <InputValidateUseForm
                            RadioClass={cl.RadioField}
                            title={(watch("pointSystem") === true || watch("pointSystem") == 'true') ? '3 начинается с ' : 'баллов на зачёт'}
                            className={cl.validateInput}
                            type={'number'}
                            checked={settings?.pointSystem}
                            error={errors?.failure_3}
                            validateSet={register("failure_3", {
                                min: {
                                    value: 0,
                                    message: 'Значение не может быть меньше или равно 0'
                                },
                            })}
                        /> */}
                            {/* В зависимости от системы оценок выводятся доп поля */}
                            {(watch("pointSystem") === true || watch("pointSystem") == 'true') ?
                                <>
                                    <InputValidateUseForm
                                        RadioClass={cl.RadioField}
                                        title={'% верных ответов на 4'}
                                        type={'number'}
                                        className={cl.validateInput}
                                        error={errors?.grade_4}
                                        validateSet={register("grade_4", {
                                            required: 'Пожалуйста, укажите количество попыток',
                                            min: {
                                                value: 1 + Number(watch("failure_3")),
                                                message: `не может быть меньше или равно ${watch("failure_3")}`
                                            },
                                        })}
                                        after={
                                            <ContentHorizontal>
                                                <p>%</p>
                                                <PointsElement> {(Math.ceil(Number(watch("grade_4")) / 100 * countOfQuestions))} </PointsElement>
                                            </ContentHorizontal>
                                        }
                                    />
                                    <InputValidateUseForm
                                        title={'% верных ответов на 5'}
                                        type={'number'}
                                        RadioClass={cl.RadioField}
                                        className={cl.validateInput}
                                        error={errors?.grade_5}
                                        validateSet={register("grade_5", {
                                            required: 'Пожалуйста, укажите количество попыток',
                                            min: {
                                                value: 1 + Number(watch("grade_4")),
                                                message: `не может быть меньше или равно ${watch("grade_4")}`
                                            },
                                        })}
                                        after={
                                            <ContentHorizontal>
                                                <p>%</p>
                                                <PointsElement> {(Math.ceil(Number(watch("grade_5")) / 100 * countOfQuestions))}  </PointsElement>
                                            </ContentHorizontal>
                                        }
                                    />
                                </>
                                : null
                            }
                            <GreenButton style={{ height: '25px' }} type='submit'> Сохранить </GreenButton>
                        </ListVertical>

                    </form>
                    : null
                }
            </DefaultWrapper>
        </div>

    );
};

export default TestSettings;