import React from 'react';
import { TeacersCoursesListStyled } from './ui';
import { CardCourseTeacher } from 'entities/TeacherArea';

const TeacherCoursesList = ({ Courses, setCourses, Subjects, ChangePublick }) => {
    return (
        <TeacersCoursesListStyled>
            {
                Courses.map((cours, index) => (
                    <CardCourseTeacher
                        key={cours.id}
                        Courses={Courses}
                        setCourses={setCourses}
                        cours={cours}
                        ChangePublic={ChangePublick}
                        index={index}
                        Subjects={Subjects}
                    />
                ))}
        </TeacersCoursesListStyled>
    );
};

export default TeacherCoursesList;