import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import NavigateBtns from 'features/PersonalArea/CourseReaderNavigateBts/NavigateBtns';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';

import { CustomLink } from 'shared/UI/Links';
import { LessonsNavigateWrap, EscapeLesson, LessonsNavigate, LessonsSidebar, NavigateLesson, NavigateModule, MoveBtn, NavBtns, CourseDescription } from 'shared/UI/LearnPrev/LearmPrev.styled';
import { useNavigate, useParams } from 'react-router-dom';
// import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { api } from 'app/api';
// import { useNavigate } from 'react-router-dom';
import СourseСontentNavigation from 'features/СourseСontentNavigation/СourseСontentNavigation'
import { TextAndBackHead } from 'shared/UI/Other';
import EducationNavigationList from 'models/StundentEducationNavigationList/StundentEducationNavigationList';

const StudingSidebar = () => {
    const { id_course, id_lesson, id_test, id_module } = useParams()
    const [ModuleId, setModuleId] = useState(null)
    const [NavigateArrary, setNavigateArrary] = useState({})
    const [modulesAndLessons, setModulesAndLessons] = useState()
    const scrollNavigation = useRef(null);
    const [CourseInfo, setCourseInfo] = useState(false)
    const [CurrentIndex, setCurrentIndex] = useState(0)

    const navigate = useNavigate()
    const goLock = (loclation) => { navigate(loclation) }

    const SortSubArrays = (array, param) => {
        return ([...array.map(subarray =>
            subarray = { ...subarray, [param]: _.sortBy(subarray[param], 'order') })])
    }
    useEffect(() => {
        // входящая сортировка подмассивов
        const getLessonContent = async () => {
            var lessonsList = (await api.get('/Module/Course/' + id_course))
            setCourseInfo(lessonsList.data.course)
            const OrderedLessonsList = SortSubArrays([...lessonsList.data.results], 'lesson')

            setNavigateArrary(await getNeighbours(OrderedLessonsList, id_test ? id_test : id_lesson, id_test ? 'test' : 'lesson'))
            setModulesAndLessons(OrderedLessonsList)
            const scrollInRef = (ref, number) => {
                ref?.current?.scroll({
                    top: [0, 1, 2, 3].indexOf(number) != -1 ? 0 :
                        (number - 5) * 40,
                    behavior: 'smooth'
                });
            };
            // Скроллинг при переходах
            var counter = 0
            lessonsList.data.results?.map(module => {
                counter += 1
                _.sortBy(module.lesson, 'order').map(lesson => {
                    if (lesson.id != id_lesson) {
                        counter += 1
                    } else {
                        scrollInRef(scrollNavigation, counter)
                    }
                })
            })
            EducationNavigationList.UpadeNavigationsList(OrderedLessonsList)
            EducationNavigationList.UpadeNavigationsAction(await getNeighbours(OrderedLessonsList, id_test ? id_test : id_lesson, id_test ? 'test' : 'lesson'))
            EducationNavigationList.UpadeIds({ c_id: id_course, m_id: id_module, l_id: id_lesson, t_id: id_test })
        }


        getLessonContent()
    }, [id_lesson, id_test])

    const checkLast = () => {
        const ModuleIndex = modulesAndLessons?.findIndex(module => module.id == id_module)
        if (ModuleIndex === modulesAndLessons?.length - 1) {
            if (modulesAndLessons[ModuleIndex]?.lesson?.findIndex(lesson => lesson.id == id_lesson) === modulesAndLessons[ModuleIndex]?.lesson?.length - 1) {
                return true
            }
            else {
                return false
            }
        } else {
            return false
        }
    }
    const checkFirst = () => {
        const ModuleIndex = modulesAndLessons?.findIndex(module => module.id == id_module)
        if (ModuleIndex === 0) {
            if (id_test === undefined && modulesAndLessons[0].lesson?.findIndex(lesson => lesson.id == id_lesson) === 0) {
                return true
            } else {
                return false
            }
        }
        else {
            return false
        }
    }


    const getNeighbours = async (list, currentId, type) => {
        var idList = []
        list.map(module => {
            var moduleLessons = []
            module.lesson.map(lesson => {
                moduleLessons.push({ id: lesson.id, type: 'lesson' })
                lesson?.surveys?.map(test =>
                    moduleLessons.push({ id: test.id, type: 'test', lesson_id: Number(lesson.id) })
                )
            })
            idList = [...idList, ...moduleLessons]
        })
        const CurentElemnt = idList.find(el => el.id === Number(currentId) && el.type === type)
        const cuurentIndex = idList.indexOf(CurentElemnt)
        return {
            prev: idList[cuurentIndex - 1] || 'back',
            currentIndex: cuurentIndex,
            next: idList[cuurentIndex + 1] || 'end'
        }
    }

    return (
        <LessonsSidebar>
            <CourseDescription>
                <h2>{CourseInfo?.label}</h2>
            </CourseDescription>
            <NavigateBtns
                id_course={id_course}
                id_module={id_module}
                NavigateArrary={NavigateArrary}
                wrap={<NavBtns />}
                from={'learn'}
                buttons={{
                    back:
                        <CustomLink>
                            {checkFirst()
                                ? <MoveBtn><IoIosArrowRoundBack />На главную</MoveBtn>
                                : <MoveBtn><IoIosArrowRoundBack />Назад</MoveBtn>}
                        </CustomLink>,
                    next:
                        <CustomLink>
                            {checkLast()
                                ? <MoveBtn>  Выйти<IoIosArrowRoundForward /></MoveBtn>
                                : <MoveBtn>  Далее<IoIosArrowRoundForward /></MoveBtn>
                            }
                        </CustomLink>
                }}
            />
            <CourseDescription style={{ padding: '15px' }}>
                <СourseСontentNavigation
                    cid={id_course}
                    mid={id_module}
                    lid={id_lesson}
                    tid={id_test}
                    ScrollRef={scrollNavigation}
                    modulesAndLessons={modulesAndLessons}
                    onClickModule={(M_id, L_id, T_id) => {
                        navigate(`/mylearn/course/${id_course}/module/${M_id}/lesson/${L_id}`)
                    }
                    }
                    onClickLesson={(M_id, L_id, T_id) => {
                        navigate(`/mylearn/course/${id_course}/module/${M_id}/lesson/${L_id}/info`)
                    }}
                    onClickTest={(M_id, L_id, T_id) => {
                        navigate(`/mylearn/course/${id_course}/module/${M_id}/lesson/${L_id}/surveyLobby/${T_id}`)
                    }}
                    ActiveModule={false}
                    ActiveLesson={false}
                    ActiveTest={false}
                />
            </CourseDescription>


        </LessonsSidebar>
    );
};

export default StudingSidebar;