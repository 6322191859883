import ListVertical from ".."
import styled from "styled-components"

export const ListVerticalStart = styled(ListVertical)` 
justify-content: flex-start;
    align-items: flex-start;
` 
export const ListVerticalCenter = styled(ListVertical)` 
    justify-content: center;
    align-items: flex-start;
` 