import styled from "styled-components";

export const ColorBtnSample = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    width: 100%;
    height: 100%;
    background-color: #000000;
    align-items: center;
    color: #ffffff;
    transition: all 0.3s linear;
    padding: 0;
    overflow: hidden;   


    &>a{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        color: #12c25b;
        background-color: rgb(147, 0, 184);
    }
    &>a:hover {
        color: inherit;
        background-color: inherit;;
    }

    &:disabled {
        background-color: rgb(232 232 232);
        cursor: auto;
        color: #a0a0a0;
    }

    &:disabled:hover {
        background-color: rgb(232 232 232);
        cursor: auto;
        color: #a0a0a0;
    }
    &:disabled:hover a:hover {   
        color: #a0a0a0;
    }
`





