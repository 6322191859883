import { GreenButton } from "shared/UI/Buttons";
import {ContentHorizontal, ListVertical} from "shared/UI/Layouts";
import styled from "styled-components";

export const LessonElementWrap = styled(ListVertical)`
gap: 10px;
`
export const LessonElement = styled.div`    
    /* padding: 10px 20px; */
    display: flex;
    padding: 10px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
    border-radius: 20px;
    cursor: pointer;
    background-color: #E8F1FF;
    transition: background 0.2s linear;
    &:hover {
        background-color: var(--base-hover-blue-color)
    }
    
`
export const LessonCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 20px;
    
  
`


export const LessonCardHeaderContent = styled(ContentHorizontal)`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 832px;
    flex-wrap: wrap;
  &>h4 { 
        font-size: 18px;
        font-weight: 500    ;
        width: 100%;
        max-width: 400px
    }
`


export const LessonDuration = styled.div`
    font-size: 18px;
    font-weight: 500px;
    width: 100%;
    max-width: 380px;
    &>span {  
            font-size: 18px; 
        }
`


export const DarkGreenButton = styled(GreenButton)`
    background-color: var(--base-green-color);
    color : #fff;
    border-radius: 14px;
    justify-content: center;
    line-height: 100%;
    padding: 16px 24px;
    font-weight : 500;
    font-size: 16px;
    width: fit-content;
    height: fit-content;
    gap: 10px;

    & > a { 
        color : #fff;
        padding: 0;
    }
    `
export const BlueButton2 = styled(DarkGreenButton)`
    background-color: #E8F1FF;
    color : var(--base-blue-color);
    
    &:hover{ 
        background-color: #A7C5FD;   
        color : var(--base-blue-color);
    }
    `

export const TestLinkWrap = styled.div`
    padding-left: 30px;
    width: 100%;
`
export const TestLink = styled.div`    
    /* & > div { */
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding:10px 20px;
        align-items: center;
        background-color: #f6f6f6;
        border-radius: 14px;
        font-size: 14px;
        border-radius: 14px;
        & >  a > button
    {
    padding: 11px 24px;
    border-radius: 10px;
    font-size: 14px;
    }  
`

export const TestLinkText = styled.div`
        display: flex;
        justify-content: flex-start;
        width: 100%;
        gap: 20px;   
    & >  p {
        max-width: 400px;
        width: 100%;
    }
    
`



