import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Loader } from 'shared/UI/Loaders';
import cl from './PublicCoursStyle.module.css'
import { SimpleWhiteButton } from 'shared/UI/Buttons';
import { api } from 'app/api';
import { useParams } from 'react-router';
import { ReviewsCounter } from 'shared/UI/Other';
import _ from 'lodash'
import { host } from 'app/globals';
import { EmptyFile } from 'shared/UI/Fillers';
import { ListVertical } from 'shared/UI/Layouts';
import CourseBuyConfirmation from 'features/CourseBuyConfirmation/CourseBuyConfirmation';
import { useNavigate } from 'react-router-dom';
import { CustomLink } from 'shared/UI/Links';
import { CustomLBlueBtn } from './styled/CustomBtnBlue.styled';

const CoursDescription = ({ CourseData, directories_data, buyCourse }) => {
    const navigate = useNavigate();
    const [ByCoursVisibility, setByCoursVisibility] = useState(false)
    const [BuyStatus, setBuyStatus] = useState(false)
    const { id } = useParams()

    useEffect(() => {
        api.get("/Course/cupil/" + id)
            .then(result => {
                setBuyStatus(result.data)
            })
    }, [CourseData])

    return (
        <div className={cn(cl.PublicTextParams, 'default-style')}>
            {(CourseData == null && directories_data) ? <Loader /> :
                <div className={cn(cl.publicCourseInformation)}>
                    <ListVertical className={cl.MainContent}>
                        <div className={cl.MainBlockText}>
                            <div className={cl.publicСourseImg}>
                                <img src={CourseData?.previewImg ? host + '/api/getFile/' + CourseData?.previewImg : EmptyFile} />
                            </div>
                            <div className={cl.PublicСourseParams}>
                                <div className={cl.PublicCourseInput}>
                                    <h2>{CourseData?.label}</h2>
                                </div>
                                <ReviewsCounter />
                                <p>
                                    <span className={cl.coursParametr}>Предмет: </span>
                                    <span className={cl.paramValue}>
                                        {directories_data?.learning_subjects.map(subject => (subject?.id == CourseData?.courseSubjectID ? subject.subject : null))}
                                    </span>
                                </p>

                                <p>
                                    <span className={cl.coursParametr}>Длительность курса: </span>
                                    <span className={cl.paramValue}>
                                        {CourseData?.duration} ч
                                    </span>
                                </p>
                            </div>
                            <CourseBuyConfirmation
                                isOpened={ByCoursVisibility}
                                onClose={setByCoursVisibility}
                                CourseData={CourseData}
                                setBuyStatus={setBuyStatus}
                                directories_data={directories_data}
                            />
                        </div>
                        <div className={cl.description}>
                            <p className={cl.DescriptionTitle}>Описание</p>
                            <p className={cl.DescriptionText}>{CourseData?.description}</p>
                        </div>
                    </ListVertical>
                    <div className={cl.rightButtonsSection}>
                        <div className={cl.PriceAndFavor}>
                            {buyCourse == null
                                ? null
                                : <span className={cl.price}> {CourseData?.price} P </span>
                            }
                        </div>

                        {buyCourse == null ? null :
                            BuyStatus ?
                                <>
                                    <SimpleWhiteButton className={cn(cl.buyBtn, cl.disabled)} disabled={true} title={'куплено'} />
                                    <CustomLBlueBtn onClick={e => navigate(`/mylearn/course/${id}/`)} title={'К обучению'} >К обучению</CustomLBlueBtn>
                                </>
                                :
                                <SimpleWhiteButton className={cl.buyBtn} onClick={e => setByCoursVisibility(true)} title={CourseData?.price > 0 ? 'Оставить заявку' : 'Оставить заявку'} />
                                // <SimpleWhiteButton className={cl.buyBtn} onClick={e => setByCoursVisibility(true)} title={CourseData?.price > 0 ? 'Купить' : 'Получить'} />
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default CoursDescription;