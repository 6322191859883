import { BackButton } from 'shared/UI/Buttons';
import { ContentHorizontal, ListVertical } from 'shared/UI/Layouts';
import React from 'react';
import { ContentHeader } from 'pages/PersonalArea/PersonalInormation/styled/ProfileStyles';
import { Back } from './TextAndBackHead.styled';
import { IoIosArrowRoundBack } from 'react-icons/io';

const TABHead = ({ text, LeftOn = true, AnotherLeft = null }) => {
    return (
        <ContentHeader>
            <ContentHorizontal style={{ gap: '0' }} >
                {LeftOn ?
                    AnotherLeft !== null ?
                        AnotherLeft :
                        <Back additionalText={<IoIosArrowRoundBack />} />
                    :
                    null}
            </ContentHorizontal>
            <h3>{text}</h3>
        </ContentHeader >
    );
};

export default TABHead;