import { api } from "app/api";
import { host } from "app/globals";
import axios from "axios";
import mobxState from "pages/TutorsMarket(Unused)/store/mobxState";

export default function useGetFitredData(urlPart) {  
    const defaultData = {
        page: '0',
        filters: mobxState.FiltersToFetch,
        count: mobxState.ElementsOnPage ?? '10',
        order: mobxState.OrderParams.parametr ?? 'label',
        direction: mobxState.OrderParams.direction ?? 'asc',
        SearchValue: mobxState.SearchValue ?? '%%'
    }
    async function getData(filterParametrs){
        const response = await api.post(urlPart, {...defaultData, ...filterParametrs })
        return response
    }
    return getData   
}   