import { AcceptButton } from "shared/UI/Buttons";
import styled from "styled-components";

export const InnerButton = styled.div`
    display: flex;
    align-items: center;
    gap: 10px; 
    padding: 4px 10px; 
    height: 32px;
    `

    export const CreateLessonSmallBtn = styled(AcceptButton)`
        height: 10px;
        font-size: 14px;
        border-radius: 8px;
        padding: 16px 17px;    
    `