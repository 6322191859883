import { MainPopup } from "shared/UI/Popups";
import styled from "styled-components";

export const CourseCreateModalStyled = styled(MainPopup)`
    display: flex;
    width: 600px;
    max-width: 440px;
    max-height: 500px !important;
    height: 100%;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;

    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #F8F8F8;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.01);
`