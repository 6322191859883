import { CustomLink } from 'shared/UI/Links';
import React from 'react';
import { useParams } from 'react-router-dom';
import { DefaultWrapper2 } from 'shared/UI/LearnPrev/LearmPrev.styled';
import { Layout1200 } from 'shared/UI/Layouts';
import { DarkGreenButton } from 'features/TeacherArea.jsx/MyLearnCourseLesson/styled/MyLearnCourseLessonStyled';
import { QuestionTextStyled } from 'shared/UI/Buttons/OtherButtons/QuestionTextStyled';
import SurveylobbyTestParametr from 'entities/StudingArea/SurveyInfoCard/SurveylobbyTestParametr/SurveylobbyTestParametr';

const TestResultShort = ({ TestResults }) => {
    const { id_course, id_module, id_lesson, id_test } = useParams()
    return (
        <Layout1200>
            <DefaultWrapper2 padding='40px' gap={'20px'}>
                {console.log(TestResults)}
                <QuestionTextStyled style={{ justifyContent: 'center', maxWidth: '800px' }}>
                    <SurveylobbyTestParametr
                        label={'Оценка :'}
                        value={

                            
                            TestResults?.pointSystem 
                            ? TestResults?.grade : 
                            TestResults?.grade === 1 ? 'зачёт' :  TestResults?.grade === 6 ? 'незачёт' : 'незачёт'



                            // ? TestResults?.countOfRight >= TestResults?.grade_5 ? 5 :
                            //     TestResults?.countOfRight >= TestResults?.grade_4 ? 4 :
                            //         TestResults?.countOfRight >= TestResults?.failure_3 ? 3 : 2
                            // : TestResults?.countOfRight >= TestResults?.failure_3 ? 'зачёт' : 'незачёт'
                        } />
                </QuestionTextStyled>
                <CustomLink to={`/mylearn/course/${id_course}/module/${id_module}/lesson/${id_lesson}/surveyLobby/${id_test}`}>
                    <DarkGreenButton style={{ maxWidth: 'fit-content' }}>
                        На главную теста
                    </DarkGreenButton>
                </CustomLink>
            </DefaultWrapper2>
        </Layout1200>
    );
};
export default TestResultShort;