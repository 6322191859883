import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import cl from './PublicCoursStyle.module.css'
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import PublickCoursesMenagment from 'shared/store/PublicCoursesMenagment';
import { MainWrap } from 'shared/UI/Layouts';
import { api } from 'app/api';
import { DefaultWrapper } from 'shared/UI/Wrappers';
import { ContentHorizontal } from 'shared/UI/Layouts';
import { CustomLink } from 'shared/UI/Links';
import CreatorInformation from 'entities/MarketCourseDetail/CourseCreator/CreatorInformation';
import CoursDescription from 'entities/MarketCourseDetail/CourseDescription/CoursDescription';
import CourseContent from 'widgets/CourseDetail/CourseContent/CourseContent';

const CourseDetail = observer(() => {
    const [AllCourseData, setAllCourseData] = useState({})
    const [dataCourse, setData] = useState({})
    const [Modules, setModules] = useState([])
    const { id } = useParams()
    const [Directions, setDirections] = useState()
    const [CurrentTab, setCurrentTab] = useState()
    const [CreatorPhoto, setCreatorPhoto] = useState()
    const setTab = (tabName) => {
        PublickCoursesMenagment.addOpendCourses(id, tabName)
        setCurrentTab(tabName)
    }

    useEffect(() => {
        api.get('/Course/Subjects')
            .then((result) => {
                setDirections({ learning_subjects: result.data })
            }
            )
        api.get(`/Module/Course/${id}`)
            .then((result) => {
                setCreatorPhoto({
                    mimeType: result.data.mimeType,
                    fileData: result.data.fileData
                })
                setAllCourseData({
                    course: { ...result.data.course },
                    results: _.sortBy(result.data.results, 'order')
                })
            }
            )

        const getCourseData = async () => {
            if (PublickCoursesMenagment.OpendCourses[id] == null) {
                setTab('Descriptopn')
            } else {
                setCurrentTab(PublickCoursesMenagment.OpendCourses[id])
            }
        }
        getCourseData()
    }, [id])

    const buyCourse = (group_id) => {
        api.post('/Course/buy', { GroupId: group_id, UserId: Number(localStorage.getItem('userId')) }
        )
    }
    return (
        <MainWrap namePage={dataCourse.label}>
            <ContentHorizontal className={cl.mainContent}>
                <DefaultWrapper className={cl.orange}>
                    <CustomLink className={cl.Back} to='/courses/allcourses'>Назад</CustomLink>
                    {/* <BackButton ></BackButton> */}
                </DefaultWrapper>
                <div className={cl.CoursDescriptionWrap}>
                    <CoursDescription
                        CourseData={AllCourseData.course}
                        directories_data={Directions}
                        buyCourse={buyCourse}
                        creatorInfo={{ mimeType: '', fileData: '' }}
                    />
                    <CourseContent AllCourseData={AllCourseData} />

                    <CreatorInformation
                        Creator_id={AllCourseData?.course?.creator}
                        CurrentCoursId={AllCourseData?.course?.id}
                        CreatorInfo={{
                            ...AllCourseData?.course?.creator,
                            mimeType: CreatorPhoto?.mimeType,
                            fileData: CreatorPhoto?.fileData
                        }}
                    />

                </div>
            </ContentHorizontal>

        </MainWrap>
    )
}

)


export default CourseDetail
