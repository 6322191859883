import ContentHorizontal from "shared/UI/Layouts/ContentHorizontal";
import styled from "styled-components";

export const SurveyLobbyTestparam = styled.div`
display: flex;
gap: 20px;
`

export const SurveyLobbyLabel = styled.p`
line-height: 100%;
color : var(--base-blue-color);
font-size: 22px;
font-weight: 600;

`

export const SurveyLobbyValue = styled(SurveyLobbyLabel)`
color : var(--base-green-color);
`