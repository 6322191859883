
import { ErrorField } from 'shared/UI/Errors';
import { DefaultWrapper } from 'shared/UI/Wrappers';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { ListVertical } from 'shared/UI/Layouts';
import { ContentHorizontalCenter } from 'pages/StudentArea/StudentCoursesList/courseShow/SurveyLobby/SurveyLobbyStyled';
import { QuestionTextStyled } from 'shared/UI/Buttons/OtherButtons/QuestionTextStyled';
import { SurveyLobbyParamsWrap } from './ui';
import SurveylobbyTestParametr from './SurveylobbyTestParametr/SurveylobbyTestParametr';
import { SurveyLobbyLabel, SurveyLobbyValue } from './SurveylobbyTestParametr/ui';
import { BlueButton2, DarkGreenButton } from 'features/TeacherArea.jsx/MyLearnCourseLesson/styled/MyLearnCourseLessonStyled';
import { formateTimeSpanToMinute } from 'shared/hooks';


const SurveyInfoCard = ({ TestParam, TrysDate, setShovPrev, TryCheck }) => {
    const [formedDuration, setFormedDuration] = useState(0);
    const [localTrysDate, setLocalTrysDate] = useState(null);
    const [bestGrade, setBestGrade] = useState(null);


    useEffect(() => {
        TestParam !== undefined &&
            setFormedDuration(formateTimeSpanToMinute(TestParam.duration))
    }, [TestParam.duration])


    const FindMaxGrade = (innerArray, paramName) => {
        innerArray.reduce((max, current) => {
            if (current[paramName] >= 2 && current[paramName] > max[paramName]) {
                return current;
            }
            return max;
        }, TrysDate[0])
    }

    useEffect(() => {
        if (TrysDate !== undefined && TrysDate !== null && TestParam?.pointSystem !== undefined) {
            setLocalTrysDate(TrysDate)
            if (TestParam.pointSystem) {
                const maxElement = TrysDate.reduce((max, current) => {
                    if (current.grade !== 6 && current.grade >= 2 && current.grade > max.grade) {
                        return current;
                    }
                    return max;
                }, TrysDate[0]);
                switch (maxElement?.grade) {
                    case 2:
                        return (setBestGrade(' 2'));
                    case 3:
                        return (setBestGrade(' 3'));
                    case 4:
                        return (setBestGrade(' 4'));
                    case 5:
                        return (setBestGrade(' 5'));
                    default: return (setBestGrade(null));
                }
            } else {

                const maxElement = TrysDate.reduce((max, current) => {
                    if (current.grade !== 6 && current.grade < 2 && current.grade > max.grade) {
                        return current;
                    }
                    return max;
                }, TrysDate[0]);

                console.log('-----TrysDate------', maxElement)
                switch (maxElement?.grade) {
                    case 0:
                        return (setBestGrade(' незачёт'));
                    case 1:
                        return (setBestGrade(' зачёт'));
                    default: return (setBestGrade(null))
                }
            }
        }

    }, [TrysDate, TestParam.pointSystem])

    return (
        <DefaultWrapper style={{ width: '100%', padding: '32px', borderRadius: '20px' }}>
            <ListVertical>
                <QuestionTextStyled>
                    <h4>Тест: {TestParam.label}</h4>
                    <ListVertical style={{ gap: '8px', width: 'fit-content', alignItems: 'flex-start' }}>
                        <SurveyLobbyLabel style={{ fontSize: '18px' }}>Длительность:</SurveyLobbyLabel>
                        <SurveyLobbyValue>
                            {TestParam.duration ?
                                (Math.floor((formedDuration / 60)) !== 0 ? Math.floor((formedDuration / 60)) : '')
                                + ' ' + (formedDuration >= 60 ? 'ч ' : '') + ' ' +
                                (formedDuration - (formedDuration - formedDuration % 60)).toFixed(0) + ' минут '
                                : 'нет'}
                        </SurveyLobbyValue>
                    </ListVertical>
                </QuestionTextStyled>
                <QuestionTextStyled $ShowUnderLine={true} style={{ paddingBottom: '30px' }}>
                    <ListVertical>
                        <SurveyLobbyParamsWrap>
                            <SurveylobbyTestParametr label={'Тип оценивания:'} value={TestParam.pointSystem ? 'оценка' : 'зачёт'} />
                            <SurveylobbyTestParametr label={'Количество попыток: '} value={TestParam.tryCount} />
                            <SurveylobbyTestParametr label={'Сколько раз пройдено:'} value={TrysDate?.length ? TrysDate?.length : 0} />
                            <SurveylobbyTestParametr label={'Переход по вопросам: '} value={TestParam.questJump ? 'Разрешён' : 'запрещён'} />
                        </SurveyLobbyParamsWrap>
                        <SurveylobbyTestParametr label={'Лучший результат: '} value={bestGrade ?? 'ожидает тестирования'} />
                    </ListVertical>
                </QuestionTextStyled>
                {
                    (TrysDate?.length == TestParam.tryCount && TrysDate?.length !== undefined)
                        ?
                        <>
                            <ErrorField error={'Невозможно начать тест, закончились попытки'} />
                            <BlueButton2 onClick={e => setShovPrev(true)} > Предыдущие попытки</BlueButton2>
                        </>
                        :
                        TrysDate?.length === TestParam.tryCount - 1
                            ?
                            <ContentHorizontalCenter>
                                <BlueButton2 onClick={e => setShovPrev(true)} > Предыдущие попытки</BlueButton2>
                                <DarkGreenButton onClick={e => TryCheck()} >
                                    Попытка {TrysDate?.length + 1} - Последняя
                                </DarkGreenButton>
                            </ContentHorizontalCenter>
                            :
                            TrysDate?.length == 0
                                ?
                                <DarkGreenButton style={{ padding: '16px 30px' }} onClick={e => TryCheck()} >
                                    Начать тестирование
                                </DarkGreenButton>
                                :
                                <ContentHorizontalCenter>
                                    <BlueButton2 onClick={e => setShovPrev(true)} > Предыдущие попытки</BlueButton2>
                                    <DarkGreenButton onClick={e => TryCheck()} >
                                        Начать попытку
                                    </DarkGreenButton>
                                </ContentHorizontalCenter>
                }

            </ListVertical>
        </DefaultWrapper>
    );
};

export default SurveyInfoCard;