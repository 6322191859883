import React, { useState } from 'react';
import _ from 'lodash';
import { AboutModule, ModuleCardDetailed, ModuleDetailedHeader, ModuleName, RotateShevron } from './styled/CourseContentDetailedStyled';
import { ProfileBackButton } from 'shared/UI/Buttons/ProfileBackButton/ProfileBackButton';
import { CustomLinkFit } from 'shared/UI/Links';
import MyLearnCourseLesson from 'features/TeacherArea.jsx/MyLearnCourseLesson/MyLearnCourseLesson';
import { ActionBtn, BlueButton, RedButton } from 'shared/UI/Buttons';
import { api } from 'app/api';
import { MainPopup } from 'shared/UI/Popups';
import { ModalSmall, MyModal } from 'shared/UI/Modals';
import { BlueQuestionButton, RedQuestionBtn } from 'shared/UI/Buttons/OtherButtons/QuestionTextStyled';
import { Loader } from 'shared/UI/Loaders';
import { TransparentButton } from './styled/TransparentButton.styled';
import { useNavigate } from 'react-router-dom';
import { RedModuleBtn,  BlueModuleBtn} from './styled/localModuleBtn.styled';


const CourseContentDetailed = ({ module, index, cid, mode, setState }) => {
    const navigate = useNavigate();
    const [ShowAll, setShowAll] = useState(false)
    const [popup, setPopup] = useState({ status: false, message: '' })
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false)
    const [deleteProgress, setDeleteProgress] = useState(false)

    const TogleFullView = (e) => {
        e.stopPropagation()
        setShowAll(!ShowAll)
    }
    const handleDeleteModule = (id) => {
        if (!id) {
            console.error('No id provided to handleDeleteModule function');
            return;
        }

        setDeleteProgress(true);
        api.delete(`module/${id}`)
            .then(() => {
                setState((prevState) => {
                    if (!prevState) {
                        console.error('No prevState provided to handleDeleteModule function');
                        return;
                    }
                    const newArray = _.reject(prevState, (el) => el.id === id);
                    return newArray;
                });
            })
            .catch((e) => {
                setDeleteProgress(false);
                if (e.response.status === 409 && e.response.data === '69-01') {
                    setPopup(prevState => { return { ...prevState, status: true, message: 'Модуль содержит уроки. Удаление модуля возможно только после удаления уроков.', title: 'Удаление невозможно!' } })
                }
            });
    }

    return (
        <ModuleCardDetailed key={index} id={'module_' + index} >
            <ModuleDetailedHeader onClick={(e) => TogleFullView(e)}>
                <AboutModule >
                    <ModuleName >
                        <RotateShevron $rotate={ShowAll ? 90 : 0} /> Модуль {index + 1}: {module.name}
                    </ModuleName>
                    <span>Количество уроков: {module.lesson.length}</span>
                </AboutModule>
                {mode == 'edit'
                    ?
                    <div style={{ width: 'min-content', display: 'flex', gap: '10px' }}>                   
                        <BlueModuleBtn  onClick={() => navigate(`/teachercabinete/course/${cid}/edit/module/${module.id}`)}>
                            Изменить
                        </BlueModuleBtn>
                        <RedModuleBtn >
                            Удалить
                        </RedModuleBtn>
                    </div>
                    :
                    null
                }

                <MainPopup
                    onClose={() => setConfirmDeleteVisible(false)}
                    isOpened={confirmDeleteVisible}
                    title={'Удалить модуль?'}
                >
                    <div style={{ display: 'flex', gap: '20px' }}>
                        {
                            deleteProgress ?
                                <Loader />
                                :
                                <>
                                    <RedQuestionBtn onClick={() => handleDeleteModule(module.id)}>Удалить</RedQuestionBtn>
                                    <TransparentButton style={{}} onClick={() => setConfirmDeleteVisible(false)}>Нет</TransparentButton>
                                </>
                        }
                    </div>
                    {/* <p>{popup.message}</p> */}
                </MainPopup>
                <MainPopup
                    onClose={() => setPopup(prevState => { return { ...prevState, status: false, message: '', title: '' } })}
                    isOpened={popup.status}
                    title={popup.title}
                >
                    <p>{popup.message}</p>
                    <BlueQuestionButton onClick={() => setPopup(prevState => { return { ...prevState, status: false, message: '', title: '' } })}>Ок</BlueQuestionButton>
                </MainPopup>

            </ModuleDetailedHeader>
            {
                ShowAll ?
                    _.sortBy(module.lesson, 'id').map((less, LessonIndex) => (
                        <MyLearnCourseLesson
                            cid={cid}
                            mid={module.id}
                            less={{ ...less, mid: module.id }}
                            LessonIndex={LessonIndex}
                            key={LessonIndex}
                            mode={mode == 'edit' ? mode : mode}
                            moduleIndex={index}
                        />
                    ))
                    : null
            }
        </ModuleCardDetailed >
    );
};

export default CourseContentDetailed;