
import { ProfileBackButton } from 'shared/UI/Buttons/ProfileBackButton/ProfileBackButton';
import React from 'react';
import { ListVertical } from 'shared/UI/Layouts';
import { MainPopup } from 'shared/UI/Popups';

const SurveyTrysInfoPopup = ({ isOpened, onClose, TrysDate, TestParam }) => {
    return (
        <MainPopup
            isOpened={isOpened}
            onClose={e => onClose(false)}
            title={'Предыдущие попытки'}
            showHeader={true}
        >
            <div>
                {TrysDate == null ?
                    null
                    :
                    <>
                        <p style={{ margin: '0 0 10px 0' }}>Попыток сделано: {TrysDate?.length}/  {TestParam.tryCount}</p>
                        <ListVertical style={{
                            gap: '10px',
                            maxHeight: 'max(50vh, 200px)',
                            overflowY: 'scroll'
                        }}>
                            {TrysDate?.length == 0
                                ? <p>Попыток пока что не было</p>
                                : null
                            }
                            {TrysDate?.map((tryDate, index) =>
                                <ProfileBackButton style={{ width: '100%' }} key={tryDate.id}>
                                    <span>{index + 1}.</span>
                                    <span>Оценка:
                                        {(() => {
                                            switch (tryDate.grade) {
                                                case 0:
                                                    return (' незачёт');
                                                case 1:
                                                    return (' зачёт');
                                                case 2:
                                                    return (' 2');
                                                case 3:
                                                    return (' 3');
                                                case 4:
                                                    return (' 4');
                                                case 5:
                                                    return (' 5');
                                                case 6:
                                                    return ('незачёт');
                                                default: return null;
                                            }
                                        })()}
                                    </span>
                                    {
                                        <span>
                                            Верных: {tryDate?.tryPoints && tryDate?.maxPoints && tryDate?.tryPoints} / {tryDate?.maxPoints}
                                        </span>
                                    }
                                </ProfileBackButton>
                            )}
                        </ListVertical>
                    </>
                }
            </div>
        </MainPopup>
    );
};

export default SurveyTrysInfoPopup;