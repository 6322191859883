import styled from "styled-components";
import { ColorBtnSample } from "./ColorBtnSampleStyled";


export  const BlueButton = styled(ColorBtnSample)`
     background-color:#b5cfff;
    color: #246FFB;
    &:hover { 
        background-color:#9CBEFD;
    color: #246FFB;
    }
    &:active, &:focus {
    background-color: #246FFB;
    color: #fff;
}
`