import { makeAutoObservable } from "mobx"

class EducationNavigationList {
    modulesAndLessons = []
    eductionIds = {
        c_id: null,
        m_id: null,
        l_id: null,
        t_id: null,
    }

    navigationList = null
    navigationsActions = null

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    async UpadeIds(result) {
        this.eductionIds = { ...this.eductionIds, ...result }
    }

    async UpadeNavigationsList(newList) {
        this.navigationList = newList
    }
    async setModulesAndLessons(list) {
        this.modulesAndLessons = list
    }
    async UpadeNavigationsAction(newActions) {
        this.navigationsActions = newActions
    }
}

export default new EducationNavigationList()